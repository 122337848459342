import amplifyService from "../../../api/amplifyService";
import { setUserData } from "./user.actions";
import * as Actions from "../../../store/actions";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
export const CODE_REQUEST_SUCCESS = "CODE_REQUEST_SUCCESS";
export const CODE_REQUEST_ERROR = "CODE_REQUEST_ERROR";

export function submitLoginWithAWS({ username, password }) {
  return (dispatch) => {
    dispatch(Actions.showMessage({ message: "Logging in" }));

    amplifyService
      .signIn(username, password)
      .then((user) => {
        amplifyService
          .getUserData({ userID: user.attributes.sub })
          .then((userData) => {
            dispatch(setUserData(userData));
            dispatch(Actions.showMessage({ message: "Logged in" }));
          });

        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        const usernameErrorCodes = ["UserNotFoundException"];
        const passwordErrorCodes = ["NotAuthorizedException"];

        const response = {
          username: usernameErrorCodes.includes(error.code)
            ? error.message
            : null,
          password: passwordErrorCodes.includes(error.code)
            ? error.message
            : null,
        };

        if (error.code === "auth/invalid-api-key") {
          dispatch(Actions.showMessage({ message: error.message }));
        }

        return dispatch({
          type: LOGIN_ERROR,
          payload: response,
        });
      });
  };
}

export function submitLoginWithIDP() {
  return (dispatch) => {
    dispatch(Actions.showMessage({ message: "Logging in" }));

    amplifyService
      .signInIDP()
      .then((result) => {
        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        const usernameErrorCodes = ["UserNotFoundException"];
        const passwordErrorCodes = ["NotAuthorizedException"];

        const response = {
          username: usernameErrorCodes.includes(error.code)
            ? error.message
            : null,
          password: passwordErrorCodes.includes(error.code)
            ? error.message
            : null,
        };

        if (error.code === "auth/invalid-api-key") {
          dispatch(Actions.showMessage({ message: error.message }));
        }

        return dispatch({
          type: LOGIN_ERROR,
          payload: response,
        });
      });
  };
}

export function submitChallengeLogin(email, baseURL, name) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(Actions.showMessage({ message: "Logging in" }));

      amplifyService
        .submitChallengeLogin(email, baseURL, name)
        .then((result) => {
          resolve(result);
          return dispatch({
            type: LOGIN_SUCCESS,
          });
        })
        .catch((error) => {
          const usernameErrorCodes = ["UserNotFoundException"];
          const passwordErrorCodes = ["NotAuthorizedException"];

          const response = {
            username: usernameErrorCodes.includes(error.code)
              ? error.message
              : null,
            password: passwordErrorCodes.includes(error.code)
              ? error.message
              : null,
          };

          if (error.code === "auth/invalid-api-key") {
            dispatch(Actions.showMessage({ message: error.message }));
          }

          reject(response);

          return dispatch({
            type: LOGIN_ERROR,
            payload: response,
          });
        });
    });
  };
}

export function signInChallenge(email) {
  console.log("🚀 ~ signInChallenge ~ email", email);

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(Actions.showMessage({ message: "Logging in" }));

      amplifyService
        .signInChallenge(email)
        .then((result) => {
          resolve(result);
          return dispatch({
            type: LOGIN_SUCCESS,
          });
        })
        .catch((error) => {
          console.log("🚀 ~ returnnewPromise ~ error", error);
          const usernameErrorCodes = ["UserNotFoundException"];
          const passwordErrorCodes = ["NotAuthorizedException"];

          const response = {
            username: usernameErrorCodes.includes(error.code)
              ? error.message
              : null,
            password: passwordErrorCodes.includes(error.code)
              ? error.message
              : null,
          };

          if (error.code === "auth/invalid-api-key") {
            dispatch(Actions.showMessage({ message: error.message }));
          }

          reject(response);

          return dispatch({
            type: LOGIN_ERROR,
            payload: response,
          });
        });
    });
  };
}

export function validateSignInChallenge(user, code) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(Actions.showMessage({ message: "Logging in" }));

      amplifyService
        .validateSignInChallenge(user, code)
        .then((result) => {
          resolve(result);
          return dispatch({
            type: LOGIN_SUCCESS,
          });
        })
        .catch((error) => {
          const usernameErrorCodes = ["UserNotFoundException"];
          const passwordErrorCodes = ["NotAuthorizedException"];

          const response = {
            username: usernameErrorCodes.includes(error.code)
              ? error.message
              : null,
            password: passwordErrorCodes.includes(error.code)
              ? error.message
              : null,
          };

          if (error.code === "auth/invalid-api-key") {
            dispatch(Actions.showMessage({ message: error.message }));
          }

          reject(response);

          return dispatch({
            type: LOGIN_ERROR,
            payload: response,
          });
        });
    });
  };
}

export function requestPasswordResetCode({ username }) {
  return (dispatch) => {
    amplifyService
      .forgotPasswordCodeRequest(username)
      .then((status) => {
        if (status.codeSent === true) {
          dispatch(Actions.showMessage({ message: "Verification Code Sent" }));
          return dispatch({
            type: CODE_REQUEST_SUCCESS,
          });
        }
      })
      .catch((error) => {
        dispatch(Actions.showMessage({ message: error.message }));

        return dispatch({
          type: CODE_REQUEST_ERROR,
          payload: error.message,
        });
      });
  };
}

export function requestPasswordSubmit({ username, code, password }) {
  return (dispatch) => {
    amplifyService
      .forgotPasswordSubmit(username, code, password)
      .then((status) => {
        if (status.passwordReset === true) {
          dispatch(
            Actions.showMessage({
              message:
                "Password reset was successful. Please navigate back to the login page.",
            })
          );
          return dispatch({
            type: RESET_SUCCESS,
          });
        } else {
          throw status.error;
        }
      })
      .catch((error) => {
        dispatch(Actions.showMessage({ message: error.message }));

        return dispatch({
          type: RESET_ERROR,
          payload: error.message,
        });
      });
  };
}
