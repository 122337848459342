import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavItem from "./NavItem";
import { Icon } from "../../../../ui-components";
import _ from "lodash";

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};

const defaultProps = {};

function needsToBeOpened(props) {
  return props.location && isUrlInChildren(props.item, props.location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].url === url ||
      url.includes(parent.children[i].url)
    ) {
      return true;
    }
  }

  return false;
}

class NavCollapse extends Component {
  constructor(props) {
    super(props);

    this.state = { open: needsToBeOpened(this.props) };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevState.open &&
      (!_.isEqual(this.props.location, prevProps.location) ||
        !_.isEqual(this.props.item, prevProps.item)) &&
      needsToBeOpened(this.props)
    ) {
      this.setState({ open: true });
    }
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { item, nestedLevel, userRole, active } = this.props;
    if (
      item.auth &&
      (!item.auth.includes(userRole) ||
        (userRole !== "guest" &&
          item.auth.length === 1 &&
          item.auth.includes("guest")))
    ) {
      return null;
    }
    let paddingValue = 8 + nestedLevel * 4;
    const listItemPadding =
      nestedLevel > 0
        ? "pl-" + (paddingValue > 80 ? 80 : paddingValue)
        : "pl-6";
    return (
      <ul className={classNames("flex flex-col", this.state.open && "")}>
        <li
          className={classNames(
            "flex items-center h-10 w-navGroup rounded-r-navGroup pr-3 cursor-pointer transition hover:bg-white hover:bg-opacity-25 ease-in-out duration-150 text-white",
            listItemPadding,
            "list-item",
            active
          )}
          onClick={this.handleClick}
        >
          {item.icon && (
            <Icon
              className="text-white self-center text-lg mr-4 flex-no-shrink"
              icon={item.icon}
            />
          )}
          <p className="text-sd">{item.title}</p>

          <Icon
            className="text-white self-center text-xl ml-2 flex-no-shrink"
            icon={this.state.open ? "expand_less" : "expand_more"}
          />
        </li>
        {item.children && (
          <div className={classNames(this.state.open ? "" : "hidden")}>
            {item.children.map((item) => (
              <React.Fragment key={item.id}>
                {item.type === "collapse" && (
                  <NavCollapse
                    item={item}
                    nestedLevel={nestedLevel + 1}
                    active={active}
                  />
                )}

                {item.type === "item" && (
                  <NavItem
                    item={item}
                    nestedLevel={nestedLevel + 1}
                    active={active}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </ul>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}

NavCollapse.propTypes = propTypes;
NavCollapse.defaultProps = defaultProps;

const NavVerticalCollapse = withRouter(connect(mapStateToProps)(NavCollapse));

export default NavVerticalCollapse;
