import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { ButtonBodyContainer, SubHeading, Button, AppLoading, Icon } from "./";
import Transition from "../base-components/layout/components/Transition";
import { responsiveFontSizes } from "@material-ui/core";

const catalogs = [
  "bc-mf-imp-std-2021-an",
  "cam-mf-imp-std-2021-an",
  "e-mf-imp-std-2021-an",
  "gb-mf-imp-std-2021-an",
  "hc-mf-imp-std-2021-an",
  "i-mf-imp-std-2021-an",
  "lc-mf-imp-std-2021-an",
  "m-mf-imp-std-2021-an",
  "p-mf-imp-std-2021-an",
  "swal-mf-imp-std-2021-an",
];

const AddCostSegAssetModal = (props) => {
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [hits, setHits] = useState([]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      props.hide();
    }
  }, []);

  // const enterFunction = useCallback((event) => {
  //   if (event.keyCode === 13) {
  //     searchRSMeansText();
  //   }
  // }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    // document.addEventListener("keydown", enterFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
      // document.removeEventListener("keydown", enterFunction, false);
    };
  }, []);

  const searchRSMeansText = () => {
    setIsSearching(true);

    let loaded = {};
    catalogs.forEach((cat) => {
      loaded[cat] = false;
    });

    setHits([]);

    catalogs.forEach((book) => {
      var body = {
        type: "unit",
        book: book,
        location: "us-us-national",
        authToken: props.authToken,
        searchTerm: searchText,
      };
      props
        .searchRSMeansAsset(body)
        .then((response) => {
          response.assets.forEach((item) => {
            setHits((hits) =>
              [...hits, item].sort((a, b) =>
                a.shortDescription.localeCompare(b.shortDescription)
              )
            );
          });
          // console.log(hits);
          loaded[book] = true;
          const doneLoading = Object.values(loaded).every((a) => a === true);
          // console.log("assets", response.assets.length, "hits", hits.length);
          if (doneLoading) {
            // console.log("done", hits.length);
            setIsSearching(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSearching(false);
        });
    });
  };

  return (
    <div className="fixed z-40 bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={props.show}
          onExited={props.hide}
        >
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={props.hide}
            ></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          show={props.show}
          onExited={props.hide}
        >
          <div
            // className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:max-w-lg sm:w-full sm:p-6"
            className="bg-sprxToolbar px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all w-full h-screen overflow-y-scroll sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50">
              <button
                onClick={() => {
                  props.hide();
                }}
              >
                <Icon
                  icon={"clear"}
                  className={"text-center text-black w-full"}
                />
              </button>
            </div>
            <SubHeading className={""}>
              Use the search interface below to find RSMeans estimated costs.
            </SubHeading>
            <div className="w-full mt-8">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  className="block w-full h-12 elevation-1 pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 text-black placeholder-gray-400 focus:outline-none focus:bg-white focus:text-gray-900 sm:text-sm transition duration-150 ease-in-out"
                  placeholder="Search"
                  type="search"
                  value={searchText}
                  onChange={(newValue) => {
                    setSearchText(newValue.target.value);
                  }}
                />
              </div>
              <ButtonBodyContainer>
                <Button
                  primary={isSearching ? false : true}
                  secondary={isSearching ? true : false}
                  disabled={isSearching}
                  onClick={(event) => {
                    searchRSMeansText();
                  }}
                >
                  Search
                  <Icon
                    className={"text-white ml-2 align-middle"}
                    icon={"search"}
                  />
                </Button>
              </ButtonBodyContainer>
            </div>
            {!isSearching && hits.length > 0 && (
              <Hits hits={hits} props={props} />
            )}
            {/* {!isSearching && hits.length === 0 && (
              <SubHeading className={""}>
                We couldn't find any assets matching your search term. Please
                refine your serach terms.
              </SubHeading>
            )} */}
            {isSearching && (
              <div className={"flex justify-center"}>
                <div className={"flex justify-center w-1/4 h-1/4"}>
                  <AppLoading />
                </div>
              </div>
            )}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default AddCostSegAssetModal;

const Hits = (hitProps) => {
  const { hits, props } = hitProps;
  return (
    <div className="bg-white mt-8 shadow overflow-hidden sm:rounded-md">
      <ul className="">
        {hits.map((hit, index) => {
          return (
            <li
              className={classNames(
                index === 0 ? "" : "border-t border-gray-200"
              )}
              key={Math.random()}
            >
              <button
                onClick={() => {
                  props.select(hit);
                }}
                className="w-full block hover:bg-gray-50 focus:outline-none focus:none transition duration-150 ease-in-out"
              >
                <div className="px-4 py-4 flex items-center sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="">
                      <div className="flex text-sm leading-5 font-medium text-blue-700 truncate">
                        {hit.description}
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm leading-5 text-gray-500">
                          <span>{hit.shortDescription}</span>
                        </div>
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm leading-5 text-gray-500">
                          <span>
                            {"Unit Cost: $" +
                              Number(hit.unitCost)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm leading-5 text-gray-500">
                          <span>
                            {"Measurement Unit: " + hit.measurementUnit}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
