import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import classNames from "classnames";
import { InstantSearch, connectAutoComplete } from "react-instantsearch-dom";
import { Button, BasicTextInput, BasicSelectInput, Icon, AppLoading } from "./";
import Transition from "../base-components/layout/components/Transition";

const locationSearchClient = algoliasearch(
  "VGVIHCD6N0",
  "87333357aab590b43faa2a74be42ad7b"
);

const AddCostSegClientModal = (props) => {
  const [clientName, setClientName] = useState("");
  const [engagementName, setEngagementName] = useState("");
  const [engagementTemplate, setEngagementTemplate] = useState("");
  const [engagementLocation, setEngagementLocation] = useState("");
  const [engagementLocationID, setEngagementLocationID] = useState("");
  const [engagementFees, setEngagementFees] = useState("");
  const [
    engagementDepreciationSystem,
    setEngagementDepreciationSystem,
  ] = useState("");

  const [clientID, setClientID] = useState("");

  const clientArray = props.clients;
  var modClientArry = [];

  clientArray.forEach((client) => {
    var obj = { name: client.clientName, key: client.clientID };
    modClientArry.push(obj);
  });

  const [templateID, setTemplateID] = useState("");

  const templateArray = props.templates;
  var modTemplateArry = [];

  templateArray.forEach((template) => {
    var obj = { name: template.name, key: template.templateID };
    modTemplateArry.push(obj);
  });

  const depreciationSystems = [
    { name: "GDS", key: 1 },
    { name: "ADS", key: 2 },
  ];

  return (
    <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={props.show}
          onExited={props.hide}
        >
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={props.hide}
            ></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          show={props.show}
          onExited={props.hide}
        >
          <div
            // className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <InstantSearch
              searchClient={locationSearchClient}
              indexName={`${process.env.REACT_APP_STAGE}_cs_locations`}
              // searchState={{
              //   query: searchText,
              // }}
              // refresh={this.props.refresh}
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    {(() => {
                      switch (props.type) {
                        case "select":
                          return "Select whether the engagement is for a new client or an existing client";
                        case "loading":
                          return "Creating Engagement...";
                        case "new":
                          return "New engagement for a new client";
                        case "existing":
                          return "New engagement for an existing client";
                        default:
                          return null;
                      }
                    })()}
                  </h3>
                  <div className="mt-2">
                    {(() => {
                      switch (props.type) {
                        case "select":
                          return null;
                        case "loading":
                          return <AppLoading />;
                        case "new":
                          return (
                            <div>
                              <BasicTextInput
                                label="Client Name"
                                showLabel={true}
                                id={"clientName"}
                                type={"text"}
                                placeholder={"Client Name"}
                                value={clientName}
                                className={"mb-2"}
                                onChange={(event) =>
                                  setClientName(event.target.value)
                                }
                              />
                              <BasicTextInput
                                label="Engagement Name"
                                showLabel={true}
                                id={"engagementName"}
                                type={"text"}
                                placeholder={"Engagement Name"}
                                value={engagementName}
                                className={"mb-2"}
                                onChange={(event) =>
                                  setEngagementName(event.target.value)
                                }
                              />
                              {engagementLocation === "" ? (
                                <CustomAutocomplete
                                  onClick={(location) => {
                                    console.log(location);
                                    setEngagementLocation(
                                      `${location.city}, ${location.stateCode}, ${location.countryCode}`
                                    );

                                    setEngagementLocationID({
                                      locationID: location.id,
                                      city: location.city,
                                      state: location.stateCode,
                                      country: location.countryCode,
                                    });
                                  }}
                                />
                              ) : (
                                <div className="flex flex-row items-center mb-2">
                                  <div className="w-11/12">
                                    <BasicTextInput
                                      label="Engagement Location"
                                      showLabel={false}
                                      id={"engagementLocation"}
                                      type={"text"}
                                      placeholder={"Engagement Location"}
                                      value={engagementLocation}
                                      readOnly={true}
                                    />
                                  </div>
                                  <div className="w-1/12 cursor-pointer flex flex-col items-center text-black text-sm z-50">
                                    <button
                                      onClick={() => {
                                        setEngagementLocation("");
                                      }}
                                    >
                                      <Icon
                                        icon={"clear"}
                                        className={
                                          "text-center text-black w-full"
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                              )}
                              <BasicSelectInput
                                label="Engagement Template"
                                id={"engagementTemplate"}
                                placeholder={"Engagement Template"}
                                value={engagementTemplate}
                                className={"mb-2"}
                                options={modTemplateArry}
                                onChange={(event) => {
                                  setEngagementTemplate(event.target.value);

                                  setTemplateID(
                                    modTemplateArry.find(
                                      (x) => x.name === event.target.value
                                    ).key
                                  );
                                }}
                              />
                              <BasicSelectInput
                                label="Engagement Depreciation System"
                                id={"depreciationSystem"}
                                placeholder={"Engagement Depreciation System"}
                                value={engagementDepreciationSystem}
                                className={"mb-2"}
                                options={depreciationSystems}
                                onChange={(event) => {
                                  setEngagementDepreciationSystem(
                                    event.target.value
                                  );
                                }}
                              />
                              <BasicTextInput
                                label="Engagement Fees"
                                showLabel={true}
                                id={"engagementFees"}
                                type={"number"}
                                placeholder={"Engagement Fees"}
                                value={engagementFees}
                                className={"mb-2"}
                                onChange={(event) =>
                                  setEngagementFees(event.target.value)
                                }
                              />
                            </div>
                          );
                        case "existing":
                          return (
                            <div>
                              <BasicSelectInput
                                label="Client Name"
                                id={"clientName"}
                                placeholder={"Client Name"}
                                value={clientName}
                                className={"mb-2"}
                                options={modClientArry}
                                onChange={(event) => {
                                  setClientName(event.target.value);

                                  setClientID(
                                    modClientArry.find(
                                      (x) => x.name === event.target.value
                                    ).key
                                  );
                                }}
                              />
                              <BasicTextInput
                                label="Engagement Name"
                                showLabel={true}
                                id={"engagementName"}
                                type={"text"}
                                placeholder={"Engagement Name"}
                                value={engagementName}
                                className={"mb-2"}
                                onChange={(event) =>
                                  setEngagementName(event.target.value)
                                }
                              />
                              {engagementLocation === "" ? (
                                <CustomAutocomplete
                                  onClick={(location) => {
                                    console.log(location);
                                    setEngagementLocation(
                                      `${location.city}, ${location.stateCode}, ${location.countryCode}`
                                    );

                                    setEngagementLocationID({
                                      locationID: location.id,
                                      city: location.city,
                                      state: location.stateCode,
                                      country: location.countryCode,
                                    });
                                  }}
                                />
                              ) : (
                                <div className="flex flex-row items-center mb-2">
                                  <div className="w-11/12">
                                    <BasicTextInput
                                      label="Engagement Location"
                                      showLabel={false}
                                      id={"engagementLocation"}
                                      type={"text"}
                                      placeholder={"Engagement Location"}
                                      value={engagementLocation}
                                      readOnly={true}
                                    />
                                  </div>
                                  <div className="w-1/12 cursor-pointer flex flex-col items-center text-black text-sm z-50">
                                    <button
                                      onClick={() => {
                                        setEngagementLocation("");
                                      }}
                                    >
                                      <Icon
                                        icon={"clear"}
                                        className={
                                          "text-center text-black w-full"
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                              )}
                              <BasicSelectInput
                                label="Engagement Template"
                                id={"engagementTemplate"}
                                placeholder={"Engagement Template"}
                                value={engagementTemplate}
                                className={"mb-2"}
                                options={modTemplateArry}
                                onChange={(event) => {
                                  setEngagementTemplate(event.target.value);

                                  setTemplateID(
                                    modTemplateArry.find(
                                      (x) => x.name === event.target.value
                                    ).key
                                  );
                                }}
                              />
                              <BasicSelectInput
                                label="Engagement Depreciation System"
                                id={"depreciationSystem"}
                                placeholder={"Engagement Depreciation System"}
                                value={engagementDepreciationSystem}
                                className={"mb-2"}
                                options={depreciationSystems}
                                onChange={(event) => {
                                  setEngagementDepreciationSystem(
                                    event.target.value
                                  );
                                }}
                              />
                              <BasicTextInput
                                label="Engagement Fees"
                                showLabel={true}
                                id={"engagementFees"}
                                type={"number"}
                                placeholder={"Engagement Fees"}
                                value={engagementFees}
                                className={"mb-2"}
                                onChange={(event) =>
                                  setEngagementFees(event.target.value)
                                }
                              />
                            </div>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              </div>
            </InstantSearch>
            {(() => {
              switch (props.type) {
                case "select":
                  return (
                    <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <Button
                          secondary
                          onClick={() => {
                            props.toggle("new");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          New Client
                        </Button>
                      </span>
                      <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                        <Button
                          primary
                          onClick={() => {
                            props.toggle("existing");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          Existing Client
                        </Button>
                      </span>
                    </div>
                  );
                case "loading":
                  return null;
                case "new":
                  return (
                    <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <Button
                          secondary
                          onClick={() => {
                            props.toggle("select");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          Back
                        </Button>
                      </span>
                      <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                        <Button
                          primary
                          disabled={
                            clientName === "" ||
                            engagementName === "" ||
                            engagementFees === ""
                          }
                          buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                          onClick={() => {
                            const model = {
                              clientName: clientName,
                              engagementName: engagementName,
                              engagementFees: engagementFees,
                              engagementLocationID: engagementLocationID,
                              templateID: templateID,
                              depreciationSystem: engagementDepreciationSystem,
                            };
                            props.submit(model);
                          }}
                        >
                          Submit
                        </Button>
                      </span>
                    </div>
                  );
                case "existing":
                  return (
                    <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <Button
                          secondary
                          onClick={() => {
                            props.toggle("select");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          Back
                        </Button>
                      </span>
                      <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                        <Button
                          primary
                          disabled={
                            clientName === "" ||
                            engagementName === "" ||
                            engagementFees === ""
                          }
                          buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                          onClick={() => {
                            const model = {
                              clientName: clientName,
                              engagementName: engagementName,
                              engagementFees: engagementFees,
                              engagementLocationID: engagementLocationID,
                              templateID: templateID,
                              clientID: clientID,
                              depreciationSystem: engagementDepreciationSystem,
                            };
                            props.submit(model);
                          }}
                        >
                          Submit
                        </Button>
                      </span>
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default AddCostSegClientModal;

const Autocomplete = ({ hits, currentRefinement, refine, onClick }) => {
  return (
    <div className="mb-2">
      <ul className="">
        <li className="">
          <div className="relative">
            <input
              className={classNames(
                "form-input block w-full sm:text-sm sm:leading-5"
              )}
              type="search"
              placeholder={"Location"}
              value={currentRefinement}
              onChange={(event) => refine(event.currentTarget.value)}
            />
          </div>
        </li>
        <div className="bg-gray-100">
          {hits.map((hit, index) => (
            <li
              className={classNames(
                index === 0 ? "" : "border-t border-gray-200"
              )}
              key={hit.objectID}
            >
              <button
                onClick={() => {
                  onClick(hit);
                }}
                className="w-full block hover:bg-gray-50 focus:outline-none focus:none transition duration-150 ease-in-out"
              >
                <div className="px-1 py-1 flex items-center">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="">
                      <div className="flex text-sm leading-5 font-medium text-blue-700 truncate">
                        {hit.city}
                      </div>
                      <div className="flex">
                        <div className="flex items-center text-sm leading-5 text-gray-500">
                          <span>{hit.stateCode}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

const CustomAutocomplete = connectAutoComplete(Autocomplete);
