import axios from "axios";
import amplifyService from "../../api/amplifyService";
import * as Actions from "../../store/actions";

export const ENGAGEMENT_DATA_SUCCCESS = "ENGAGEMENT_DATA_SUCCCESS";
export const ENGAGEMENT_DATA_FAILURE = "ENGAGEMENT_DATA_FAILURE";
export const ENGAGEMENT_DATA_RESET = "ENGAGEMENT_DATA_RESET";
export const SET_CREDIT_OVERVIEW_DATA_SUCCCESS =
  "CREDIT_OVERVIEW_DATA_SUCCCESS";
export const SET_CREDIT_OVERVIEW_DATA_FAILURE = "CREDIT_OVERVIEW_DATA_FAILURE";
export const SET_CALCULATION_DATA_SUCCESS = "SET_CALCULATION_DATA_SUCCESS";
export const SET_CALCULATION_DATA_FAILURE = "SET_CALCULATION_DATA_FAILURE";
export const SELECT_SURVEY_TYPE = "SELECT_SURVEY_TYPE";
export const SELECT_CREDIT_METHOD = "SELECT_CREDIT_METHOD";
export const SELECT_PROJECT_ALLOCATION_METHOD =
  "SELECT_PROJECT_ALLOCATION_METHOD";
export const SET_ALLOCATION_METHOD_DATA_SUCCESS =
  "SET_ALLOCATION_METHOD_DATA_SUCCESS";
export const SET_ALLOCATION_METHOD_DATA_FAILURE =
  "SET_ALLOCATION_METHOD_DATA_FAILURE";
export const EXTRACT_6765_DATA_SUCCESS = "EXTRACT_6765_DATA_SUCCESS";
export const EXTRACT_6765_DATA_FAILURE = "EXTRACT_6765_DATA_FAILURE";
export const UPLOAD_6765_DATA_SUCCESS = "UPLOAD_6765_DATA_SUCCESS";
export const UPLOAD_6765_DATA_FAILURE = "UPLOAD_6765_DATA_FAILURE";
export const UPLOAD_LOADING = "UPLOAD_LOADING";
export const EXTRACT_LOADING = "EXTRACT_LOADING";
export const TEMPLATE_LOADING = "TEMPLATE_LOADING";
export const DOCUMENT_LIST_LOADING = "DOCUMENT_LIST_LOADING";
export const SELECT_CHANGE_6765 = "SELECT_CHANGE_6765";
export const TEMPLATE_DOWNLOAD_SUCCESS = "TEMPLATE_DOWNLOAD_SUCCESS";
export const TEMPLATE_DOWNLOAD_FAILURE = "TEMPLATE_DOWNLOAD_FAILURE";
export const UPLOAD_QRE_TEMPLATE_SUCCESS = "UPLOAD_QRE_TEMPLATE_SUCCESS";
export const UPLOAD_QRE_TEMPLATE_FAILURE = "UPLOAD_QRE_TEMPLATE_FAILURE";
export const PARSE_QRE_TEMPLATE_SUCCESS = "PARSE_QRE_TEMPLATE_SUCCESS";
export const PARSE_QRE_TEMPLATE_FAILURE = "PARSE_QRE_TEMPLATE_FAILURE";
export const DOCUMENT_LIST_DOWNLOAD_SUCCESS = "DOCUMENT_LIST_DOWNLOAD_SUCCESS";
export const DOCUMENT_LIST_DOWNLOAD_FAILURE = "DOCUMENT_LIST_DOWNLOAD_FAILURE";
export const UPLOAD_SUPPORTING_DOC_SUCCESS = "UPLOAD_SUPPORTING_DOC_SUCCESS";
export const UPLOAD_SUPPORTING_DOC_FAILURE = "UPLOAD_SUPPORTING_DOC_FAILURE";
export const UPDATE_QRE_DATA_SUCCESS = "UPDATE_QRE_DATA_SUCCESS";
export const UPDATE_QRE_DATA_FAILURE = "UPDATE_QRE_DATA_FAILURE";
export const ADD_FUNDED_PROJECT = "ADD_FUNDED_PROJECT";
export const ADD_IUS_PROJECT = "ADD_IUS_PROJECT";
export const ADD_Q_PROJECT = "ADD_Q_PROJECT";
export const ADD_NQ_PROJECT = "ADD_NQ_PROJECT";
export const REMOVE_FUNDED_PROJECT = "REMOVE_FUNDED_PROJECT";
export const REMOVE_IUS_PROJECT = "REMOVE_IUS_PROJECT";
export const REMOVE_Q_PROJECT = "REMOVE_Q_PROJECT";
export const REMOVE_NQ_PROJECT = "REMOVE_NQ_PROJECT";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const EDIT_FUNDED_PROJECT = "EDIT_FUNDED_PROJECT";
export const EDIT_IUS_PROJECT = "EDIT_IUS_PROJECT";
export const EDIT_Q_PROJECT = "EDIT_Q_PROJECT";
export const EDIT_NQ_PROJECT = "EDIT_NQ_PROJECT";
export const RUN_ML_MODEL_SUCCESS = "RUN_ML_MODEL_SUCCESS";
export const RUN_ML_MODEL_FAILURE = "RUN_ML_MODEL_FAILURE";
export const SET_FLAGS_SUCCESS = "SET_FLAGS_SUCCESS";
export const SET_FLAGS_FAILURE = "SET_FLAGS_FAILURE";
export const RECOMPUTE_CREDIT_SUCCESS = "RECOMPUTE_CREDIT_SUCCESS";
export const RECOMPUTE_CREDIT_FAILURE = "RECOMPUTE_CREDIT_FAILURE";
export const GENERATE_DELIVERABLES_SUCCESS = "GENERATE_DELIVERABLES_SUCCESS";
export const GENERATE_DELIVERABLES_FAILURE = "GENERATE_DELIVERABLES_FAILURE";
export const DELIVERABLE_DOWNLOAD_SUCCESS = "DELIVERABLE_DOWNLOAD_SUCCESS";
export const DELIVERABLE_DOWNLOAD_FAILURE = "DELIVERABLE_DOWNLOAD_FAILURE";
export const GENERATE_REVIEW_REPORTS_SUCCESS =
  "GENERATE_REVIEW_REPORTS_SUCCESS";
export const GENERATE_REVIEW_REPORTS_FAILURE =
  "GENERATE_REVIEW_REPORTS_FAILURE";
export const ROLL_OVER_ENGAGEMENT_SUCCESS = "ROLL_OVER_ENGAGEMENT_SUCCESS";
export const ROLL_OVER_ENGAGEMENT_FAILURE = "ROLL_OVER_ENGAGEMENT_FAILURE";
export const CHECK_EMPLOYEE_LOCATION_SUCCESS =
  "CHECK_EMPLOYEE_LOCATION_SUCCESS";
export const CHECK_EMPLOYEE_LOCATION_FAILURE =
  "CHECK_EMPLOYEE_LOCATION_FAILURE";
export const SET_ENGAGEMENT_NOTES_SUCCESS = "SET_ENGAGEMENT_NOTES_SUCCESS";
export const SET_ENGAGEMENT_NOTES_FAILURE = "SET_ENGAGEMENT_NOTES_FAILURE";
export const GET_ENGAGEMENT_SUCCESS = "GET_ENGAGEMENT_SUCCESS";
export const GET_ENGAGEMENT_FAILURE = "GET_ENGAGEMENT_FAILURE";
export const UPDATE_ENGAGEMENT_SUCCESS = "UPDATE_ENGAGEMENT_SUCCESS";
export const UPDATE_ENGAGEMENT_FAILURE = "UPDATE_ENGAGEMENT_FAILURE";
export const UPLOAD_ENGAGEMENT_IMAGE_SUCCESS =
  "UPLOAD_ENGAGEMENT_IMAGE_SUCCESS";
export const UPLOAD_ENGAGEMENT_IMAGE_FAILURE =
  "UPLOAD_ENGAGEMENT_IMAGE_FAILURE";
export const DOCUMENT_UPDATE_SUCCESS = "DOCUMENT_UPDATE_SUCCESS";
export const DOCUMENT_UPDATE_FAILURE = "DOCUMENT_UPDATE_FAILURE";
export const EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS =
  "EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS";
export const EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE =
  "EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE";
export const UPDATE_SUPPORTING_DOC_SUCCESS = "UPDATE_SUPPORTING_DOC_SUCCESS";
export const UPDATE_SUPPORTING_DOC_FAILURE = "UPDATE_SUPPORTING_DOC_FAILURE";
export const CALCULATE_ERC_AVGERAGE_EMPLOYEES_SUCCESS =
  "CALCULATE_ERC_AVGERAGE_EMPLOYEES_SUCCESS";
export const CALCULATE_ERC_AVGERAGE_EMPLOYEES_FAILURE =
  "CALCULATE_ERC_AVGERAGE_EMPLOYEES_FAILURE";
export const COMPUTE_GR_DECLINE_SUCCESS = "COMPUTE_GR_DECLINE_SUCCESS";
export const COMPUTE_GR_DECLINE_FAILURE = "COMPUTE_GR_DECLINE_FAILURE";
export const CHECK_ERC_EMPLOYEE_LOCATION_SUCCESS =
  "CHECK_ERC_EMPLOYEE_LOCATION_SUCCESS";
export const CHECK_ERC_EMPLOYEE_LOCATION_FAILURE =
  "CHECK_ERC_EMPLOYEE_LOCATION_FAILURE";
export const CHECK_ERC_EMPLOYEE_HCP_SUCCESS = "CHECK_ERC_EMPLOYEE_HCP_SUCCESS";
export const CHECK_ERC_EMPLOYEE_HCP_FAILURE = "CHECK_ERC_EMPLOYEE_HCP_FAILURE";
export const COMPUTE_ERC_CREDIT_SUCCESS = "COMPUTE_ERC_CREDIT_SUCCESS";
export const COMPUTE_ERC_CREDIT_FAILURE = "COMPUTE_ERC_CREDIT_FAILURE";
export const COST_SEG_TOKEN_SUCCCESS = "COST_SEG_TOKEN_SUCCCESS";
export const COST_SEG_TOKEN_FAILURE = "COST_SEG_TOKEN_FAILURE";
export const GENERATED_PARSED_UPLOAD_SUCCESS =
  "GENERATED_PARSED_UPLOAD_SUCCESS";
export const GENERATED_PARSED_UPLOAD_FAILURE =
  "GENERATED_PARSED_UPLOAD_FAILURE";

//R&D Credit
export function getEngagementData(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getEngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          resolve(engagementData);

          return dispatch({
            type: ENGAGEMENT_DATA_SUCCCESS,
            payload: engagementData,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: ENGAGEMENT_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function resetEngagementData() {
  return (dispatch) => {
    return dispatch({
      type: ENGAGEMENT_DATA_RESET,
    });
  };
}

export function selectSurveyType(type) {
  return (dispatch, getState) => {
    return dispatch({
      type: SELECT_SURVEY_TYPE,
      payload: type,
    });
  };
}

export function selectCreditMethod(type) {
  return (dispatch, getState) => {
    return dispatch({
      type: SELECT_CREDIT_METHOD,
      payload: type,
    });
  };
}

export function selectProjectAllocationMethod(type) {
  return (dispatch, getState) => {
    return dispatch({
      type: SELECT_PROJECT_ALLOCATION_METHOD,
      payload: type,
    });
  };
}

export function selectChange6765(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setExtractLoading());

      var body = {
        form6765: {},
      };

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          dispatch(getEngagementData(engagementID));
          dispatch(setExtractLoading());
          resolve(data);
        });
    });
  };
}

export function selectChangeQREData(engagementID, type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setUploadLoading());

      if (type === "wageList") {
        const currentStateData =
          getState().engagement.engagement.engagementData.stateCredits;

        currentStateData.forEach((state) => {
          state.selected = false;
        });
        // resolve(true);
        var wageListBody = {
          [type]: {
            bucket: "N/A",
            data: {
              qre: 0,
              data: [],
            },
            name: "N/A",
            qreType: "N/A",
            url: "N/A",
          },
          stateCredits: currentStateData,
        };

        amplifyService
          .updateEngagementData({
            engagementID: engagementID,
            body: wageListBody,
          })
          .then((data) => {
            dispatch(getEngagementData(engagementID));
            resolve(data);
          });
      } else {
        var body = {
          [type]: {
            bucket: "N/A",
            data: {
              qre: 0,
              data: [],
            },
            name: "N/A",
            qreType: "N/A",
            url: "N/A",
          },
        };

        amplifyService
          .updateEngagementData({ engagementID: engagementID, body: body })
          .then((data) => {
            dispatch(getEngagementData(engagementID));
            resolve(data);
          });
      }
    });
  };
}

export function setCreditOverviewData(engagementID, type, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        [type]: data,
      };

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((creditOverviewData) => {
          resolve(creditOverviewData);
          return dispatch({
            type: SET_CREDIT_OVERVIEW_DATA_SUCCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: SET_CREDIT_OVERVIEW_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setCalculationData(
  engagementID,
  calcData,
  controlledGroupData,
  ascData
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const currentCalcData =
        getState().engagement.engagement.engagementData.calculation;

      var body = {
        averageAnnualGR: calcData.hasOwnProperty("averageAnnualGR")
          ? calcData.averageAnnualGR
          : currentCalcData.averageAnnualGR,
        fixedBasePercentage: calcData.hasOwnProperty("fixedBasePercentage")
          ? calcData.fixedBasePercentage
          : currentCalcData.fixedBasePercentage,
        method: calcData.hasOwnProperty("method")
          ? calcData.method
          : currentCalcData.method,
        cgAllocation: controlledGroupData,
        documentAllocation: calcData.hasOwnProperty("documentAllocation")
          ? calcData.documentAllocation
          : currentCalcData.documentAllocation,
        py1QRE: ascData.py1QRE,
        py2QRE: ascData.py2QRE,
        py3QRE: ascData.py3QRE,
        entityName: calcData.hasOwnProperty("entityName")
          ? calcData.entityName
          : currentCalcData.entityName,
        entityEIN: calcData.hasOwnProperty("entityEIN")
          ? calcData.entityEIN
          : currentCalcData.entityEIN,
        reducedCredit: calcData.hasOwnProperty("reducedCredit")
          ? calcData.reducedCredit
          : currentCalcData.reducedCredit,
        form8932: calcData.hasOwnProperty("form8932")
          ? calcData.form8932
          : currentCalcData.form8932,
        creditK1: calcData.hasOwnProperty("creditK1")
          ? calcData.creditK1
          : currentCalcData.creditK1,
        creditBeneficiariesEstateTrust: calcData.hasOwnProperty(
          "creditBeneficiariesEstateTrust"
        )
          ? calcData.creditBeneficiariesEstateTrust
          : currentCalcData.creditBeneficiariesEstateTrust,
        entityType: calcData.hasOwnProperty("entityType")
          ? calcData.entityType
          : currentCalcData.entityType,
      };

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((calcObject) => {
          resolve(calcObject);
          return dispatch({
            type: UPDATE_QRE_DATA_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_QRE_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setAllocationMethodData(engagementID, method) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        projectAllocation: method,
      };

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((allocationObject) => {
          amplifyService
            .allocateQRE({ engagementID: engagementID })
            .then((result) => {
              resolve(allocationObject);
              return dispatch({
                type: SET_ALLOCATION_METHOD_DATA_SUCCESS,
              });
            });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_ALLOCATION_METHOD_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setUploadLoading() {
  return (dispatch, getState) => {
    const loading = getState().engagement.engagement.uploadLoading;
    if (loading === true) {
      return dispatch({
        type: UPLOAD_LOADING,
        payload: false,
      });
    } else if (loading === false) {
      return dispatch({
        type: UPLOAD_LOADING,
        payload: true,
      });
    }
  };
}

export function setExtractLoading() {
  return (dispatch, getState) => {
    const loading = getState().engagement.engagement.form6765Loading;
    console.log(loading);
    if (loading === true) {
      return dispatch({
        type: EXTRACT_LOADING,
        payload: false,
      });
    } else if (loading === false) {
      return dispatch({
        type: EXTRACT_LOADING,
        payload: true,
      });
    }
  };
}

export function setTemplateLoading() {
  return (dispatch, getState) => {
    const loading = getState().engagement.engagement.templateDownloading;
    // console.log(loading);
    if (loading === true) {
      return dispatch({
        type: TEMPLATE_LOADING,
        payload: false,
      });
    } else if (loading === false) {
      return dispatch({
        type: TEMPLATE_LOADING,
        payload: true,
      });
    }
  };
}

export function setDocumentListLoading() {
  return (dispatch, getState) => {
    const loading = getState().engagement.engagement.docListDownloading;
    console.log(loading);
    if (loading === true) {
      return dispatch({
        type: DOCUMENT_LIST_LOADING,
        payload: false,
      });
    } else if (loading === false) {
      return dispatch({
        type: DOCUMENT_LIST_LOADING,
        payload: true,
      });
    }
  };
}

export function extract6765Data(data) {
  return (dispatch, getState) => {
    // dispatch(setExtractLoading());
    // bucket, fileName, taxYear, engagementID

    return new Promise((resolve, reject) => {
      var body = {
        processing: true,
      };

      amplifyService
        .updateEngagementData({ engagementID: data.engagementID, body: body })
        .then((engagementData) => {
          amplifyService
            .extractForm6765({
              bucketName: data.bucketName,
              fileName: data.fileName,
              taxYear: data.taxYear,
              url: data.url,
              engagementID: data.engagementID,
              toEmail: data.toEmail,
              engagement: data.engagement,
              userName: data.userName,
            })
            .then((data) => {
              console.log(data);

              if (data.status === true) {
                console.log(data.response.body);

                amplifyService
                  .pollForm6765(data.response.body)
                  .then((poll) => {
                    console.log(poll);
                    resolve(true);
                    return dispatch({
                      type: EXTRACT_6765_DATA_SUCCESS,
                      payload: poll,
                    });
                  })
                  .catch((error) => {
                    console.log(error);

                    dispatch(Actions.showMessage({ message: error.message }));
                    reject(error.message);
                    return dispatch({
                      type: EXTRACT_6765_DATA_FAILURE,
                      payload: error.message,
                    });
                  });
              }
            })
            .catch((error) => {
              // console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function upload6765(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL, taxYear: data.taxYear };

              if (result.statusText === "OK") {
                // dispatch(extract6765Data(bucket, key, taxYear));
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_6765_DATA_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function creditDataFormDownload(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setTemplateLoading());

      amplifyService
        .requestSignedURL({
          bucket: "sprx.standard.forms.2019",
          key: data.key,
          action: data.action,
        })
        .then((signedURL) => {
          console.log(signedURL);
          window.location.href = signedURL.data.signedRequest;
          dispatch(setTemplateLoading());
          resolve(signedURL);
          return dispatch({
            type: TEMPLATE_DOWNLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: TEMPLATE_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function parsedFileDownload(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setTemplateLoading());

      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
        })
        .then((signedURL) => {
          console.log(signedURL);
          window.location.href = signedURL.data.signedRequest;
          dispatch(setTemplateLoading());
          resolve(signedURL);
          return dispatch({
            type: TEMPLATE_DOWNLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: TEMPLATE_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadQRETemplate(data) {
  // bucket, key, file, type, engagementID
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL, taxYear: data.taxYear };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_QRE_TEMPLATE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_QRE_TEMPLATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function parseQRETemplate(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        [data.qreType]: {
          bucket: data.bucket,
          data: data.data,
          name: data.name,
          qreType: data.qreType,
          url: data.url,
        },
      };

      amplifyService
        .updateEngagementData({ engagementID: data.engagementID, body: body })
        .then((qreObject) => {
          var finalObj = { type: qreObject, qreType: data.qreType };
          resolve(finalObj);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_SUCCESS,
            payload: finalObj,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function connectRDCreditPayroll(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        code: data.code,
      };

      amplifyService
        .connectRDCreditPayroll({ engagementID: data.engagementID, body: body })
        .then((payrollStatus) => {
          if (payrollStatus.status === true) {
            resolve(payrollStatus.status);
          }

          return dispatch({
            type: PARSE_QRE_TEMPLATE_SUCCESS,
            payload: payrollStatus,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function documentListDownload(bucket, delimiter, prefix) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // dispatch(setDocumentListLoading());

      amplifyService
        .listEngagementDocuments({
          bucket: bucket,
          delimiter: delimiter,
          prefix: prefix,
        })
        .then((documents) => {
          console.log(documents);
          documents.data.documentList.Contents.forEach((doc) => {
            if (doc.Key.indexOf("deliverable") !== -1) {
              var index = documents.data.documentList.Contents.indexOf(doc);
              documents.data.documentList.Contents.splice(index, 1);
            }
          });

          // dispatch(setDocumentListLoading());
          resolve(documents);
          return dispatch({
            type: DOCUMENT_LIST_DOWNLOAD_SUCCESS,
            payload: documents,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: DOCUMENT_LIST_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function updateDocumentationDatabase(engagementID, documents) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getEngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          var filteredDocs = documents;

          engagementData.documentation.documents.forEach((doc) => {
            filteredDocs = filteredDocs.filter((item) => item.key !== doc.key);
          });

          var body = {
            documentation: {
              documents: [
                ...engagementData.documentation.documents,
                ...filteredDocs,
              ],
            },
          };

          amplifyService
            .updateEngagementData({ engagementID: engagementID, body: body })
            .then((status) => {
              resolve(status);
              return dispatch({
                type: DOCUMENT_UPDATE_SUCCESS,
                payload: status,
              });
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: DOCUMENT_UPDATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function uploadSupportingDoc(data) {
  // bucket, key, file, engagementID
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL, taxYear: data.taxYear };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_SUPPORTING_DOC_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_SUPPORTING_DOC_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function extractSupporingDocData(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: true,
      };

      amplifyService
        .updateEngagementData({ engagementID: data.engagementID, body: body })
        .then((engagementData) => {
          amplifyService
            .extractOCRSupportingDoc({
              bucketName: data.bucketName,
              fileName: data.fileName,
              url: data.url,
              engagementID: data.engagementID,
            })
            .then((extractData) => {
              console.log(extractData);

              if (extractData.status === true) {
                var pollBody = {
                  ...extractData.response.body,
                  engagementID: data.engagementID,
                };

                amplifyService
                  .pollOCRSupportingDoc(pollBody)
                  .then((poll) => {
                    console.log(poll);
                    resolve(true);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                      payload: poll,
                    });
                  })
                  .catch((error) => {
                    console.log(error);

                    dispatch(Actions.showMessage({ message: error.message }));
                    reject(error.message);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE,
                      payload: error.message,
                    });
                  });
              }
            })
            .catch((error) => {
              // console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function transcribeSupporingDocData(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: true,
      };

      amplifyService
        .updateEngagementData({ engagementID: data.engagementID, body: body })
        .then((engagementData) => {
          amplifyService
            .transcribeSupportingDoc({
              bucketName: data.bucketName,
              fileName: data.fileName,
              url: data.url,
              engagementID: data.engagementID,
            })
            .then((extractData) => {
              console.log(extractData);

              if (extractData.status === true) {
                var pollBody = {
                  ...extractData.response.body,
                  engagementID: data.engagementID,
                };

                amplifyService
                  .pollTranscribeSupportingDoc(pollBody)
                  .then((poll) => {
                    console.log(poll);
                    resolve(true);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                      payload: poll,
                    });
                  })
                  .catch((error) => {
                    console.log(error);

                    dispatch(Actions.showMessage({ message: error.message }));
                    reject(error.message);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE,
                      payload: error.message,
                    });
                  });
              }
            })
            .catch((error) => {
              // console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function ocrMSOSupportingDocument(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: false,
      };

      amplifyService
        .updateEngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((engagementData) => {
          amplifyService
            .ocrMSOSupportingDocument({
              bucketName: data.bucketName,
              fileName: data.fileName,
              url: data.url,
              engagementID: data.engagementID,
              extractType: data.extractType,
            })
            .then((poll) => {
              console.log(poll);
              resolve(true);
              return dispatch({
                type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                payload: poll,
              });
            })
            .catch((error) => {
              console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE,
                payload: error.message,
              });
            });
        })
        .catch((error) => {
          // console.log(error);

          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: EXTRACT_6765_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function updateSupportingDocumentation(engagementID, documents) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        documentation: {
          documents: documents,
        },
      };

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((obj) => {
          resolve(obj);
          return dispatch({
            type: UPDATE_SUPPORTING_DOC_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_SUPPORTING_DOC_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function updateQREData(engagementID, type, data, profile) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const qreData = getState().engagement.engagement.engagementData.QREData;

      const updateData = qreData[type];

      var body = {};

      type = "wageList"
        ? (body = {
            [type]: {
              ...updateData,
              data: {
                ...updateData.data,
                data: data,
                profile: profile,
              },
            },
          })
        : (body = {
            [type]: {
              ...updateData,
              data: {
                ...updateData.data,
                data: data,
              },
            },
          });

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((qreObject) => {
          var finalObj = { type: qreObject, qreType: type };

          resolve(finalObj);
          return dispatch({
            type: UPDATE_QRE_DATA_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_QRE_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function categorizeProjects(type, project, id) {
  return (dispatch, getState) => {
    switch (type) {
      case "add funded":
        return dispatch({
          type: ADD_FUNDED_PROJECT,
          payload: project,
        });
      case "add ius":
        return dispatch({
          type: ADD_IUS_PROJECT,
          payload: project,
        });
      case "add q":
        return dispatch({
          type: ADD_Q_PROJECT,
          payload: project,
        });
      case "add nq":
        return dispatch({
          type: ADD_NQ_PROJECT,
          payload: project,
        });
      case "remove funded":
        return dispatch({
          type: REMOVE_FUNDED_PROJECT,
          payload: id,
        });
      case "remove ius":
        return dispatch({
          type: REMOVE_IUS_PROJECT,
          payload: id,
        });
      case "remove q":
        return dispatch({
          type: REMOVE_Q_PROJECT,
          payload: id,
        });

      case "remove nq":
        return dispatch({
          type: REMOVE_NQ_PROJECT,
          payload: id,
        });
      default:
        return dispatch({
          type: PROJECT_ERROR,
          payload: "Error classifiying project",
        });
    }
  };
}

export function editProject(type, value, key, index) {
  return (dispatch, getState) => {
    switch (type) {
      case "edit funded":
        return dispatch({
          type: EDIT_FUNDED_PROJECT,
          payload: {
            index: index,
            key: key,
            value: value,
          },
        });
      case "edit ius":
        return dispatch({
          type: EDIT_IUS_PROJECT,
          payload: {
            index: index,
            key: key,
            value: value,
          },
        });
      case "edit q":
        return dispatch({
          type: EDIT_Q_PROJECT,
          payload: {
            index: index,
            key: key,
            value: value,
          },
        });
      case "edit nq":
        return dispatch({
          type: EDIT_NQ_PROJECT,
          payload: {
            index: index,
            key: key,
            value: value,
          },
        });
      default:
        return dispatch({
          type: PROJECT_ERROR,
          payload: "Error classifiying project",
        });
    }
  };
}

export function runMLModel(engagementID, toEmail, userName, method) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        toEmail: toEmail,
        userName: userName,
        engagementID: engagementID,
      };

      console.log(engagementID);
      console.log(body);

      amplifyService
        .runMLModel({ body: body })
        .then((mlObject) => {
          resolve(mlObject);
          return dispatch({
            type: RUN_ML_MODEL_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: RUN_ML_MODEL_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setFlags(engagementID, body) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((qreObject) => {
          var finalObj = { type: qreObject };

          resolve(finalObj);
          return dispatch({
            type: SET_FLAGS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_FLAGS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function rolloverEngagement(engagementID, rolloverEngagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getEngagementData({ engagementID: rolloverEngagementID })
        .then((engagementData) => {
          console.log(engagementData);

          var body = {
            averageAnnualGR: engagementData.calculation.averageAnnualGR,
            fixedBasePercentage: engagementData.calculation.fixedBasePercentage,
            cgAllocation: engagementData.calculation.cgAllocation,
            entityEIN: engagementData.calculation.entityEIN,
            entityName: engagementData.calculation.entityName,
            entityType: engagementData.calculation.entityType,
            method: engagementData.calculation.method,
            py2QRE: engagementData.calculation.pyQRE.py1QRE,
            py3QRE: engagementData.calculation.pyQRE.py2QRE,
            reducedCredit: engagementData.calculation.reducedCredit,
            controlledGroup: engagementData.creditOverviewData.controlledGroup,
            eligibleSmallBusiness:
              engagementData.creditOverviewData.eligibleSmallBusiness,
            qualifiedSmallBusiness:
              engagementData.creditOverviewData.qualifiedSmallBusiness,
            qualifiedEnergyConsortiaPayments:
              engagementData.creditOverviewData
                .qualifiedEnergyConsortiaPayments,
            qualifiedBasicResearchPayments:
              engagementData.creditOverviewData.qualifiedBasicResearchPayments,
            rolloverQRE: engagementData.QREData,
            rollOver: true,
            rollOverID: rolloverEngagementID,
            rollOverName: engagementData.engagementName,
            rollOverS3Bucket: engagementData.s3Bucket,
          };

          amplifyService
            .updateEngagementData({ engagementID: engagementID, body: body })
            .then((rolloverObject) => {
              var finalObj = { type: rolloverObject };

              resolve(finalObj);
              return dispatch({
                type: ROLL_OVER_ENGAGEMENT_SUCCESS,
              });
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: ROLL_OVER_ENGAGEMENT_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function computeCredit(engagementID, method) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeCredit({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: RECOMPUTE_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: RECOMPUTE_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeStateCredits(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeStateCredit({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: RECOMPUTE_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: RECOMPUTE_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateDeliverables(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateDeliverables({ engagementID: engagementID })
        .then((result) => {
          console.log(result);
          resolve(result);
          return dispatch({
            type: GENERATE_DELIVERABLES_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATE_DELIVERABLES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateReviewReports(engagementID, templateName) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateReviewReports({
          engagementID: engagementID,
          templateName: templateName,
        })
        .then((result) => {
          resolve(result);
          return dispatch({
            type: GENERATE_REVIEW_REPORTS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATE_REVIEW_REPORTS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateParsedUploadFile(engagementID, templateName, type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateParsedUploadFile({
          templateName: templateName,
          type: type,
          engagementID: engagementID,
        })
        .then((result) => {
          console.log(result);
          resolve(result);
          return dispatch({
            type: GENERATED_PARSED_UPLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          console.error("ERROR", error);
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATED_PARSED_UPLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function deliverableDownload(fileName, bucket) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setTemplateLoading());

      amplifyService
        .requestSignedURL({
          bucket: bucket,
          key: fileName,
          action: "getObject",
        })
        .then((signedURL) => {
          window.location.href = signedURL.data.signedRequest;
          dispatch(setTemplateLoading());
          resolve(signedURL);
          return dispatch({
            type: DELIVERABLE_DOWNLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: DELIVERABLE_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function jsonDataDownload(fileName, bucket) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setTemplateLoading());

      amplifyService
        .requestSignedURL({
          bucket: bucket,
          key: fileName,
          action: "getObject",
        })
        .then((signedURL) => {
          dispatch(setTemplateLoading());
          resolve(signedURL);
          return dispatch({
            type: DELIVERABLE_DOWNLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: DELIVERABLE_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function checkEmployeeLocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setTemplateLoading());

      amplifyService
        .checkEmployeeLocation({ engagementID: engagementID })
        .then((locations) => {
          resolve(locations);

          return dispatch({
            type: CHECK_EMPLOYEE_LOCATION_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: CHECK_EMPLOYEE_LOCATION_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setEngagementNotes(engagementID, notes) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        notes: notes,
      };

      amplifyService
        .updateEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getEngagement(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getEngagement({ engagementID: engagementID })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: GET_ENGAGEMENT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GET_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadEngagementImage(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_ENGAGEMENT_IMAGE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_ENGAGEMENT_IMAGE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function updateEngagement(engagementID, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        clientName: data.clientName,
        firmID: data.firmID,
        clientID: data.clientID,
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
        engagementType: data.engagementType,
        taxYearEnd: data.taxYearEnd,
        taxYearStart: data.taxYearStart,
        engagementLogo: data.engagementLogo,
      };

      var engagementDataBody = {
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
      };

      amplifyService
        .updateEngagement({ engagementID: engagementID, body: body })
        .then((engagement) => {
          amplifyService
            .updateEngagementData({
              engagementID: engagementID,
              body: engagementDataBody,
            })
            .then((data) => {
              resolve(data);
              return dispatch({
                type: UPDATE_ENGAGEMENT_SUCCESS,
              });
            });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

//ERC Credit

export function getERCEngagementData(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getERCEngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          resolve(engagementData);

          return dispatch({
            type: ENGAGEMENT_DATA_SUCCCESS,
            payload: engagementData,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: ENGAGEMENT_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setERCCreditOverviewData(engagementID, type, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        [type]: data,
      };

      amplifyService
        .updateERCEngagementData({ engagementID: engagementID, body: body })
        .then((creditOverviewData) => {
          resolve(creditOverviewData);
          return dispatch({
            type: SET_CREDIT_OVERVIEW_DATA_SUCCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: SET_CREDIT_OVERVIEW_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function selectChangeERCCostData(engagementID, type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setUploadLoading());

      var body = {
        [type]: {
          bucket: "N/A",
          data: {
            currentCredit: 0,
            potentialCredit: 0,
            data: [],
          },
          name: "N/A",
          costType: "N/A",
          url: "N/A",
        },
        credit: {
          currentCredit: 0,
          potentialCredit: 0,
          totalAvailableCredit: 0,
        },
      };

      amplifyService
        .updateERCEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          dispatch(getERCEngagementData(engagementID));
          resolve(data);
        });
    });
  };
}

export function uploadERCCostTemplate(data) {
  // bucket, key, file, type, engagementID
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_QRE_TEMPLATE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_QRE_TEMPLATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function parseERCCostTemplate(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        [data.costType]: {
          bucket: data.bucket,
          data: data.data,
          name: data.name,
          costType: data.costType,
          url: data.url,
        },
      };

      amplifyService
        .updateERCEngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((costObject) => {
          var finalObj = { type: costObject, costType: data.costType };
          resolve(finalObj);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_SUCCESS,
            payload: finalObj,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function calculateERCAvgEmployees(data, engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .calculateERCAvgEmployees({
          average2019NumberEmployeesPerMonth: data,
        })
        .then((avgEmpResult) => {
          if (avgEmpResult.status === true) {
            var body = {
              greaterThan100Employees: avgEmpResult.over100,
            };

            amplifyService
              .updateERCEngagementData({
                engagementID: engagementID,
                body: body,
              })
              .then((qreObject) => {
                resolve(avgEmpResult);
                return dispatch({
                  type: CALCULATE_ERC_AVGERAGE_EMPLOYEES_SUCCESS,
                  payload: avgEmpResult,
                });
              });
          }
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: CALCULATE_ERC_AVGERAGE_EMPLOYEES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function determineERCSignificantDecline(data, entities, engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeGRDecline({
          pqGR: data,
        })
        .then((declineResult) => {
          var body = {
            cgAllocation: entities,
            q12019GR: data.q12019GR,
            q22019GR: data.q22019GR,
            q32019GR: data.q32019GR,
            q42019GR: data.q42019GR,
            q12020GR: data.q12020GR,
            q22020GR: data.q22020GR,
            q32020GR: data.q32020GR,
            q42020GR: data.q42020GR,
          };

          amplifyService
            .updateERCEngagementData({
              engagementID: engagementID,
              body: body,
            })
            .then((qreObject) => {
              console.log(declineResult);
              resolve(declineResult);
              return dispatch({
                type: COMPUTE_GR_DECLINE_SUCCESS,
                payload: declineResult,
              });
            });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_GR_DECLINE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function checkERCEmployeeLocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .checkERCEmployeeLocation(engagementID)
        .then((locationResult) => {
          if (locationResult.status === true) {
            resolve(locationResult);

            return dispatch({
              type: CHECK_ERC_EMPLOYEE_LOCATION_SUCCESS,
              payload: locationResult,
            });
          }
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: CHECK_ERC_EMPLOYEE_LOCATION_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function checkERCEmployeeHCP(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .checkERCEmployeeHCP(engagementID)
        .then((hcpResult) => {
          if (hcpResult.status === true) {
            resolve(hcpResult);

            return dispatch({
              type: CHECK_ERC_EMPLOYEE_HCP_SUCCESS,
              payload: hcpResult,
            });
          }
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: CHECK_ERC_EMPLOYEE_HCP_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeERCPppAllocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeERCPppAllocation({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeERCReallocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeERCReallocation({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeERCOptimal2020PppAllocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeERCOptimal2020PppAllocation({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeERCOptimal2021PppAllocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeERCOptimal2021PppAllocation({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeERCCredit(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeERCCredit({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeCredit2020(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeCredit2020({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeCredit2021(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeCredit2021({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computePppAllocation2020(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computePppAllocation2020({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computePppAllocation2021(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computePppAllocation2021({ engagementID: engagementID })
        .then((creditObject) => {
          resolve(creditObject);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: COMPUTE_ERC_CREDIT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setERCFlags(engagementID, body) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .updateERCEngagementData({ engagementID: engagementID, body: body })
        .then((result) => {
          resolve(result);
          return dispatch({
            type: SET_FLAGS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_FLAGS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function updateERCCostData(engagementID, type, data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const costData = getState().engagement.engagement.engagementData.costData;

      const updateData = costData[type];

      var body = {};

      type = "wageList"
        ? (body = {
            [type]: {
              ...updateData,
              data: {
                ...updateData.data,
                data: data,
              },
            },
          })
        : (body = {
            [type]: {
              ...updateData,
              data: {
                ...updateData.data,
                data: data,
              },
            },
          });

      amplifyService
        .updateERCEngagementData({ engagementID: engagementID, body: body })
        .then((costObject) => {
          var finalObj = { type: costObject, qreType: type };

          resolve(finalObj);
          return dispatch({
            type: UPDATE_QRE_DATA_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_QRE_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setERCCalculationData(
  engagementID,
  calcData,
  controlledGroupData
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const currentCalcData =
        getState().engagement.engagement.engagementData.calculation;

      var body = {
        method: calcData.hasOwnProperty("method")
          ? calcData.method
          : currentCalcData.method,
        cgAllocation: controlledGroupData,
        entityName: calcData.hasOwnProperty("entityName")
          ? calcData.entityName
          : currentCalcData.entityName,
        entityEIN: calcData.hasOwnProperty("entityEIN")
          ? calcData.entityEIN
          : currentCalcData.entityEIN,
      };

      amplifyService
        .updateERCEngagementData({ engagementID: engagementID, body: body })
        .then((calcObject) => {
          resolve(calcObject);
          return dispatch({
            type: UPDATE_QRE_DATA_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_QRE_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateERCReviewReports(engagementID, templateName) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateERCReviewReports({
          engagementID: engagementID,
          templateName: templateName,
        })
        .then((result) => {
          resolve(result);
          return dispatch({
            type: GENERATE_REVIEW_REPORTS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATE_REVIEW_REPORTS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setERCEngagementNotes(engagementID, notes) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        notes: notes,
      };

      amplifyService
        .updateERCEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getERCEngagement(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getERCEngagement({ engagementID: engagementID })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: GET_ENGAGEMENT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GET_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadERCEngagementImage(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_ENGAGEMENT_IMAGE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_ENGAGEMENT_IMAGE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function updateERCEngagement(engagementID, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        clientName: data.clientName,
        firmID: data.firmID,
        clientID: data.clientID,
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
        engagementType: data.engagementType,
        taxYearEnd: data.taxYearEnd,
        taxYearStart: data.taxYearStart,
        engagementLogo: data.engagementLogo,
      };

      var engagementDataBody = {
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
      };

      amplifyService
        .updateERCEngagement({ engagementID: engagementID, body: body })
        .then((engagement) => {
          amplifyService
            .updateERCEngagementData({
              engagementID: engagementID,
              body: engagementDataBody,
            })
            .then((data) => {
              resolve(data);
              return dispatch({
                type: UPDATE_ENGAGEMENT_SUCCESS,
              });
            });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateERCDeliverables(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateERCCreditReport({ engagementID: engagementID })
        .then((creditReport) => {
          amplifyService
            .generateERCAnalysisStatement({ engagementID: engagementID })
            .then((analysisStatement) => {
              console.log(analysisStatement);
              resolve(analysisStatement);
              return dispatch({
                type: GENERATE_DELIVERABLES_SUCCESS,
              });
            });
        })

        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATE_DELIVERABLES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

//DXR Tool

export function getDXREngagementData(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getDXREngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          resolve(engagementData);

          return dispatch({
            type: ENGAGEMENT_DATA_SUCCCESS,
            payload: engagementData,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: ENGAGEMENT_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function updateDXRDocumentationDatabase(engagementID, documents) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getDXREngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          var filteredDocs = documents;

          engagementData.documentation.documents.forEach((doc) => {
            filteredDocs = filteredDocs.filter((item) => item.key !== doc.key);
          });

          var body = {
            documentation: {
              documents: [
                ...engagementData.documentation.documents,
                ...filteredDocs,
              ],
            },
          };

          amplifyService
            .updateDXREngagementData({ engagementID: engagementID, body: body })
            .then((status) => {
              resolve(status);
              return dispatch({
                type: DOCUMENT_UPDATE_SUCCESS,
                payload: status,
              });
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: DOCUMENT_UPDATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function updateDXRSupportingDocumentation(engagementID, documents) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        documentation: {
          documents: documents,
        },
      };

      amplifyService
        .updateDXREngagementData({ engagementID: engagementID, body: body })
        .then((obj) => {
          resolve(obj);
          return dispatch({
            type: UPDATE_SUPPORTING_DOC_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_SUPPORTING_DOC_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function dxrExtractSupportingDocuments(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: false,
      };

      amplifyService
        .updateDXREngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((engagementData) => {
          amplifyService
            .dxrExtractSupportingDocuments({
              engagementID: data.engagementID,
            })
            .then((extractData) => {
              console.log(extractData);
              resolve(extractData);

              return dispatch({
                type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                payload: extractData,
              });
            })
            .catch((error) => {
              // console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function extractDXRSupporingDocData(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: false,
      };

      amplifyService
        .updateDXREngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((engagementData) => {
          amplifyService
            .extractDXROCRSupportingDoc({
              bucketName: data.bucketName,
              fileName: data.fileName,
              url: data.url,
              engagementID: data.engagementID,
            })
            .then((extractData) => {
              console.log(extractData);

              if (extractData.status === true) {
                var pollBody = {
                  ...extractData.response.body,
                  engagementID: data.engagementID,
                };

                amplifyService
                  .pollDXROCRSupportingDoc(pollBody)
                  .then((poll) => {
                    console.log(poll);
                    resolve(true);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                      payload: poll,
                    });
                  })
                  .catch((error) => {
                    console.log(error);

                    dispatch(Actions.showMessage({ message: error.message }));
                    reject(error.message);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE,
                      payload: error.message,
                    });
                  });
              }
            })
            .catch((error) => {
              // console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function transcribeDXRSupporingDocData(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: false,
      };

      amplifyService
        .updateDXREngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((engagementData) => {
          amplifyService
            .transcribeDXRSupportingDoc({
              bucketName: data.bucketName,
              fileName: data.fileName,
              url: data.url,
              engagementID: data.engagementID,
            })
            .then((extractData) => {
              console.log(extractData);

              if (extractData.status === true) {
                var pollBody = {
                  ...extractData.response.body,
                  engagementID: data.engagementID,
                };

                amplifyService
                  .pollTranscribeDXRSupportingDoc(pollBody)
                  .then((poll) => {
                    console.log(poll);
                    resolve(true);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                      payload: poll,
                    });
                  })
                  .catch((error) => {
                    console.log(error);

                    dispatch(Actions.showMessage({ message: error.message }));
                    reject(error.message);
                    return dispatch({
                      type: EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE,
                      payload: error.message,
                    });
                  });
              }
            })
            .catch((error) => {
              // console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_6765_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function dxrOCRMSOSupportingDocument(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        processing: false,
      };

      amplifyService
        .updateDXREngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((engagementData) => {
          amplifyService
            .dxrOCRMSOSupportingDocument({
              bucketName: data.bucketName,
              fileName: data.fileName,
              url: data.url,
              engagementID: data.engagementID,
              extractType: data.extractType,
            })
            .then((poll) => {
              console.log(poll);
              resolve(true);
              return dispatch({
                type: EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS,
                payload: poll,
              });
            })
            .catch((error) => {
              console.log(error);

              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE,
                payload: error.message,
              });
            });
        })
        .catch((error) => {
          // console.log(error);

          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: EXTRACT_6765_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setDXRFlags(engagementID, body) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .updateDXREngagementData({ engagementID: engagementID, body: body })
        .then((result) => {
          resolve(result);
          return dispatch({
            type: SET_FLAGS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_FLAGS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateDXRDeliverables(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateDXRReports({ engagementID: engagementID })
        .then((result) => {
          console.log(result);
          resolve(result);
          return dispatch({
            type: GENERATE_DELIVERABLES_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATE_DELIVERABLES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setDXREngagementNotes(engagementID, notes) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        notes: notes,
      };

      amplifyService
        .updateDXREngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getDXREngagement(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getDXREngagement({ engagementID: engagementID })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: GET_ENGAGEMENT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GET_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadDXREngagementImage(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_ENGAGEMENT_IMAGE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_ENGAGEMENT_IMAGE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function updateDXREngagement(engagementID, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        clientName: data.clientName,
        firmID: data.firmID,
        clientID: data.clientID,
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
        engagementType: data.engagementType,
        taxYearEnd: data.taxYearEnd,
        taxYearStart: data.taxYearStart,
        engagementLogo: data.engagementLogo,
      };

      var engagementDataBody = {
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
      };

      amplifyService
        .updateDXREngagement({ engagementID: engagementID, body: body })
        .then((engagement) => {
          amplifyService
            .updateDXREngagementData({
              engagementID: engagementID,
              body: engagementDataBody,
            })
            .then((data) => {
              resolve(data);
              return dispatch({
                type: UPDATE_ENGAGEMENT_SUCCESS,
              });
            });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateDXRDemoSet(targetID, sourceID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateDXRDemoSet({ targetID: targetID, sourceID: sourceID })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: UPDATE_ENGAGEMENT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

//Jobs Tool

export function getJobsEngagementData(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getJobsEngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          resolve(engagementData);

          return dispatch({
            type: ENGAGEMENT_DATA_SUCCCESS,
            payload: engagementData,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: ENGAGEMENT_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setJobsFlags(engagementID, body) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .updateJobsEngagementData({ engagementID: engagementID, body: body })
        .then((result) => {
          resolve(result);
          return dispatch({
            type: SET_FLAGS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_FLAGS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadJobsCostTemplate(data) {
  // bucket, key, file, type, engagementID
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_QRE_TEMPLATE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_QRE_TEMPLATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function parseJobsCostTemplate(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        [data.costType]: {
          bucket: data.bucket,
          data: data.data,
          name: data.name,
          costType: data.costType,
          url: data.url,
        },
      };

      amplifyService
        .updateJobsEngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((costObject) => {
          var finalObj = { type: costObject, costType: data.costType };
          resolve(finalObj);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_SUCCESS,
            payload: finalObj,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateParsedJobsUploadFiles(
  engagementID,
  templateName,
  type
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateParsedJobsUploadFiles({
          templateName: templateName,
          type: type,
          engagementID: engagementID,
        })
        .then((result) => {
          console.log(result);
          resolve(result);
          return dispatch({
            type: GENERATED_PARSED_UPLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          console.error("ERROR", error);
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATED_PARSED_UPLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function selectChangeJobsCostData(engagementID, type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setUploadLoading());

      var body = {
        [type]: {
          bucket: "N/A",
          data: {
            currentCredit: 0,
            potentialCredit: 0,
            data: [],
          },
          name: "N/A",
          costType: "N/A",
          url: "N/A",
        },
      };

      amplifyService
        .updateJobsEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          dispatch(getJobsEngagementData(engagementID));
          resolve(data);
        });
    });
  };
}

export function checkEmployeeJobsLocation(engagementID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .checkEmployeeJobsLocation(engagementID)
        .then((locationResult) => {
          if (locationResult.status === true) {
            resolve(locationResult);

            return dispatch({
              type: CHECK_ERC_EMPLOYEE_LOCATION_SUCCESS,
              payload: locationResult,
            });
          }
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: CHECK_ERC_EMPLOYEE_LOCATION_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setJobsCreditOverviewData(engagementID, type, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        [type]: data,
      };

      amplifyService
        .updateJobsEngagementData({ engagementID: engagementID, body: body })
        .then((creditOverviewData) => {
          resolve(creditOverviewData);
          return dispatch({
            type: SET_CREDIT_OVERVIEW_DATA_SUCCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: SET_CREDIT_OVERVIEW_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function computeJobsCredit(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .computeJobsCredit(engagementID)
        .then((token) => {
          resolve(token);

          return dispatch({
            type: COST_SEG_TOKEN_SUCCCESS,
            payload: token,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: COST_SEG_TOKEN_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getJobsEngagement(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getJobsEngagement({ engagementID: engagementID })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: GET_ENGAGEMENT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GET_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setJobsEngagementNotes(engagementID, notes) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        notes: notes,
      };

      amplifyService
        .updateJobsEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_ENGAGEMENT_NOTES_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadJobsEngagementImage(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_ENGAGEMENT_IMAGE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_ENGAGEMENT_IMAGE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function updateJobsEngagement(engagementID, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var body = {
        clientName: data.clientName,
        firmID: data.firmID,
        clientID: data.clientID,
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
        engagementType: data.engagementType,
        taxYearEnd: data.taxYearEnd,
        taxYearStart: data.taxYearStart,
        engagementLogo: data.engagementLogo,
      };

      var engagementDataBody = {
        engagementFees: data.engagementFees,
        engagementName: data.engagementName,
      };

      amplifyService
        .updateJobsEngagement({ engagementID: engagementID, body: body })
        .then((engagement) => {
          amplifyService
            .updateDXREngagementData({
              engagementID: engagementID,
              body: engagementDataBody,
            })
            .then((data) => {
              resolve(data);
              return dispatch({
                type: UPDATE_ENGAGEMENT_SUCCESS,
              });
            });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: UPDATE_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

//Cost Seg Tool

export function getCostSegEngagementData(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getCostSegEngagementData({ engagementID: engagementID })
        .then((engagementData) => {
          resolve(engagementData);

          return dispatch({
            type: ENGAGEMENT_DATA_SUCCCESS,
            payload: engagementData,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: ENGAGEMENT_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

// export function setERCCreditOverviewData(engagementID, type, data) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var body = {
//         [type]: data,
//       };

//       amplifyService
//         .updateERCEngagementData({ engagementID: engagementID, body: body })
//         .then((creditOverviewData) => {
//           resolve(creditOverviewData);
//           return dispatch({
//             type: SET_CREDIT_OVERVIEW_DATA_SUCCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error);
//           return dispatch({
//             type: SET_CREDIT_OVERVIEW_DATA_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

export function selectChangeCostSegCostData(engagementID, type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setUploadLoading());

      var body = {
        [type]: {
          bucket: "N/A",
          data: {
            currentCredit: 0,
            potentialCredit: 0,
            data: [],
          },
          name: "N/A",
          costType: "N/A",
          url: "N/A",
        },
      };

      amplifyService
        .updateCostSegEngagementData({ engagementID: engagementID, body: body })
        .then((data) => {
          dispatch(getCostSegEngagementData(engagementID));
          resolve(data);
        });
    });
  };
}

export function uploadCostSegCostTemplate(data) {
  // bucket, key, file, type, engagementID
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_QRE_TEMPLATE_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_QRE_TEMPLATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function parseCostSegCostTemplate(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = {
        [data.costType]: {
          bucket: data.bucket,
          data: data.data,
          name: data.name,
          costType: data.costType,
          url: data.url,
        },
      };

      amplifyService
        .updateCostSegEngagementData({
          engagementID: data.engagementID,
          body: body,
        })
        .then((costObject) => {
          var finalObj = { type: costObject, costType: data.costType };
          resolve(finalObj);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_SUCCESS,
            payload: finalObj,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: PARSE_QRE_TEMPLATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadCostSegImage(data) {
  // bucket, key, file, engagementID
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL };

              if (result.statusText === "OK") {
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_SUPPORTING_DOC_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_SUPPORTING_DOC_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function getRSMeansToken(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getRSMeansToken(engagementID)
        .then((token) => {
          resolve(token);

          return dispatch({
            type: COST_SEG_TOKEN_SUCCCESS,
            payload: token,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: COST_SEG_TOKEN_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getRSMeansAsset(asset) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var data = {
        type: asset.type,
        book: asset.book,
        location: asset.location,
        assetID: asset.id,
        authToken: asset.authToken,
      };

      amplifyService
        .getRSMeansAsset(data)
        .then((token) => {
          resolve(token);

          return dispatch({
            type: COST_SEG_TOKEN_SUCCCESS,
            payload: token,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: COST_SEG_TOKEN_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function searchRSMeansAsset(asset) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var data = {
        type: asset.type,
        book: asset.book,
        location: asset.location,
        authToken: asset.authToken,
        searchTerm: asset.searchTerm,
      };

      amplifyService
        .searchRSMeansAsset(data)
        .then((token) => {
          resolve(token);

          return dispatch({
            type: COST_SEG_TOKEN_SUCCCESS,
            payload: token,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: COST_SEG_TOKEN_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function setCostSegFlags(engagementID, body) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .updateCostSegEngagementData({ engagementID: engagementID, body: body })
        .then((result) => {
          resolve(result);
          return dispatch({
            type: SET_FLAGS_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: SET_FLAGS_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function compute481a(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .compute481a(engagementID)
        .then((token) => {
          resolve(token);

          return dispatch({
            type: COST_SEG_TOKEN_SUCCCESS,
            payload: token,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: COST_SEG_TOKEN_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateParsedCostSegUploadFile(
  engagementID,
  templateName,
  type
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateParsedCostSegUploadFile({
          templateName: templateName,
          type: type,
          engagementID: engagementID,
        })
        .then((result) => {
          console.log(result);
          resolve(result);
          return dispatch({
            type: GENERATED_PARSED_UPLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          console.error("ERROR", error);
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GENERATED_PARSED_UPLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function generateCostSegCreditReport(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .generateCostSegCreditReport(engagementID)
        .then((token) => {
          resolve(token);

          return dispatch({
            type: COST_SEG_TOKEN_SUCCCESS,
            payload: token,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);
          return dispatch({
            type: COST_SEG_TOKEN_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

// export function calculateERCAvgEmployees(data, engagementID) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .calculateERCAvgEmployees({
//           average2019NumberEmployeesPerMonth: data,
//         })
//         .then((avgEmpResult) => {
//           if (avgEmpResult.status === true) {
//             var body = {
//               greaterThan100Employees: avgEmpResult.over100,
//             };

//             amplifyService
//               .updateERCEngagementData({
//                 engagementID: engagementID,
//                 body: body,
//               })
//               .then((qreObject) => {
//                 resolve(avgEmpResult);
//                 return dispatch({
//                   type: CALCULATE_ERC_AVGERAGE_EMPLOYEES_SUCCESS,
//                   payload: avgEmpResult,
//                 });
//               });
//           }
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: CALCULATE_ERC_AVGERAGE_EMPLOYEES_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function determineERCSignificantDecline(data, entities, engagementID) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .computeGRDecline({
//           pqGR: data,
//         })
//         .then((declineResult) => {
//           var body = {
//             cgAllocation: entities,
//             q12019GR: data.q12019GR,
//             q22019GR: data.q22019GR,
//             q32019GR: data.q32019GR,
//             q42019GR: data.q42019GR,
//             q12020GR: data.q12020GR,
//             q22020GR: data.q22020GR,
//             q32020GR: data.q32020GR,
//             q42020GR: data.q42020GR,
//           };

//           amplifyService
//             .updateERCEngagementData({
//               engagementID: engagementID,
//               body: body,
//             })
//             .then((qreObject) => {
//               console.log(declineResult);
//               resolve(declineResult);
//               return dispatch({
//                 type: COMPUTE_GR_DECLINE_SUCCESS,
//                 payload: declineResult,
//               });
//             });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: COMPUTE_GR_DECLINE_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function checkERCEmployeeLocation(engagementID) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .checkERCEmployeeLocation(engagementID)
//         .then((locationResult) => {
//           if (locationResult.status === true) {
//             resolve(locationResult);

//             return dispatch({
//               type: CHECK_ERC_EMPLOYEE_LOCATION_SUCCESS,
//               payload: locationResult,
//             });
//           }
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: CHECK_ERC_EMPLOYEE_LOCATION_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function checkERCEmployeeHCP(engagementID) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .checkERCEmployeeHCP(engagementID)
//         .then((hcpResult) => {
//           if (hcpResult.status === true) {
//             resolve(hcpResult);

//             return dispatch({
//               type: CHECK_ERC_EMPLOYEE_HCP_SUCCESS,
//               payload: hcpResult,
//             });
//           }
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: CHECK_ERC_EMPLOYEE_HCP_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function computeERCCredit(engagementID) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .computeERCCredit({ engagementID: engagementID })
//         .then((creditObject) => {
//           resolve(creditObject);
//           return dispatch({
//             type: COMPUTE_ERC_CREDIT_SUCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: COMPUTE_ERC_CREDIT_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function setERCFlags(engagementID, body) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .updateERCEngagementData({ engagementID: engagementID, body: body })
//         .then((result) => {
//           resolve(result);
//           return dispatch({
//             type: SET_FLAGS_SUCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: SET_FLAGS_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function updateERCCostData(engagementID, type, data) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       const costData = getState().engagement.engagement.engagementData.costData;

//       const updateData = costData[type];

//       var body = {};

//       type = "wageList"
//         ? (body = {
//             [type]: {
//               ...updateData,
//               data: {
//                 ...updateData.data,
//                 data: data,
//               },
//             },
//           })
//         : (body = {
//             [type]: {
//               ...updateData,
//               data: {
//                 ...updateData.data,
//                 data: data,
//               },
//             },
//           });

//       amplifyService
//         .updateERCEngagementData({ engagementID: engagementID, body: body })
//         .then((costObject) => {
//           var finalObj = { type: costObject, qreType: type };

//           resolve(finalObj);
//           return dispatch({
//             type: UPDATE_QRE_DATA_SUCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: UPDATE_QRE_DATA_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function setERCCalculationData(
//   engagementID,
//   calcData,
//   controlledGroupData
// ) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       const currentCalcData = getState().engagement.engagement.engagementData
//         .calculation;

//       var body = {
//         method: calcData.hasOwnProperty("method")
//           ? calcData.method
//           : currentCalcData.method,
//         cgAllocation: controlledGroupData,
//         entityName: calcData.hasOwnProperty("entityName")
//           ? calcData.entityName
//           : currentCalcData.entityName,
//         entityEIN: calcData.hasOwnProperty("entityEIN")
//           ? calcData.entityEIN
//           : currentCalcData.entityEIN,
//       };

//       amplifyService
//         .updateERCEngagementData({ engagementID: engagementID, body: body })
//         .then((calcObject) => {
//           resolve(calcObject);
//           return dispatch({
//             type: UPDATE_QRE_DATA_SUCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: UPDATE_QRE_DATA_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function generateERCReviewReports(engagementID, templateName) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .generateERCReviewReports({
//           engagementID: engagementID,
//           templateName: templateName,
//         })
//         .then((result) => {
//           resolve(result);
//           return dispatch({
//             type: GENERATE_REVIEW_REPORTS_SUCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: GENERATE_REVIEW_REPORTS_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function setERCEngagementNotes(engagementID, notes) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var body = {
//         notes: notes,
//       };

//       amplifyService
//         .updateERCEngagementData({ engagementID: engagementID, body: body })
//         .then((data) => {
//           resolve(data);
//           return dispatch({
//             type: SET_ENGAGEMENT_NOTES_SUCCESS,
//           });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: SET_ENGAGEMENT_NOTES_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

export function getCostSegEngagement(engagementID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getCostSegEngagement({ engagementID: engagementID })
        .then((data) => {
          resolve(data);
          return dispatch({
            type: GET_ENGAGEMENT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: GET_ENGAGEMENT_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

// export function uploadERCEngagementImage(data) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .requestSignedURL({
//           bucket: data.bucket,
//           key: data.key,
//           action: data.action,
//           type: data.type,
//         })
//         .then((signedURL) => {
//           const options = {
//             headers: {
//               "Content-Type": data.type,
//             },
//           };

//           var signedRequest = signedURL.data.signedRequest;

//           var itemURL = signedURL.data.url;

//           axios
//             .put(signedRequest, data.file, options)
//             .then((result) => {
//               var fileObj = { result, url: itemURL };

//               if (result.statusText === "OK") {
//                 resolve(fileObj);
//                 return dispatch({
//                   type: UPLOAD_ENGAGEMENT_IMAGE_SUCCESS,
//                   payload: fileObj,
//                 });
//               }
//             })
//             .catch((error) => {
//               alert("ERROR " + JSON.stringify(error));
//             })
//             .catch((error) => {
//               dispatch(Actions.showMessage({ message: error.message }));
//               reject(error.message);
//               return dispatch({
//                 type: UPLOAD_ENGAGEMENT_IMAGE_FAILURE,
//                 payload: error.message,
//               });
//             });
//         });
//     });
//   };
// }

// export function updateERCEngagement(engagementID, data) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var body = {
//         clientName: data.clientName,
//         firmID: data.firmID,
//         clientID: data.clientID,
//         engagementFees: data.engagementFees,
//         engagementName: data.engagementName,
//         engagementType: data.engagementType,
//         taxYearEnd: data.taxYearEnd,
//         taxYearStart: data.taxYearStart,
//         engagementLogo: data.engagementLogo,
//       };

//       var engagementDataBody = {
//         engagementFees: data.engagementFees,
//         engagementName: data.engagementName,
//       };

//       amplifyService
//         .updateERCEngagement({ engagementID: engagementID, body: body })
//         .then((engagement) => {
//           amplifyService
//             .updateERCEngagementData({
//               engagementID: engagementID,
//               body: engagementDataBody,
//             })
//             .then((data) => {
//               resolve(data);
//               return dispatch({
//                 type: UPDATE_ENGAGEMENT_SUCCESS,
//               });
//             });
//         })
//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: UPDATE_ENGAGEMENT_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }

// export function generateERCDeliverables(engagementID) {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       amplifyService
//         .generateERCCreditReport({ engagementID: engagementID })
//         .then((creditReport) => {
//           amplifyService
//             .generateERCAnalysisStatement({ engagementID: engagementID })
//             .then((analysisStatement) => {
//               console.log(analysisStatement);
//               resolve(analysisStatement);
//               return dispatch({
//                 type: GENERATE_DELIVERABLES_SUCCESS,
//               });
//             });
//         })

//         .catch((error) => {
//           dispatch(Actions.showMessage({ message: error.message }));
//           reject(error.message);
//           return dispatch({
//             type: GENERATE_DELIVERABLES_FAILURE,
//             payload: error.message,
//           });
//         });
//     });
//   };
// }
