import { combineReducers } from "redux";
import clients from "./clients.reducer";
import firms from "./firm.reducer";
import admin from "./admin.reducer";
import engagement from "./engagement.reducer";

const logicReducers = combineReducers({
  clients,
  firms,
  admin,
  engagement
});

export default logicReducers;
