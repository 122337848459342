import React from "react";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import UserMenu from "./UserMenu";
import * as adminActions from "../../../logicStore/actions";
import * as Actions from "../../../store/actions";

const ToolBar = (props) => {
  const {
    classNamees,
    settings,
    toolbarTheme,
    setSearchText,
    searchText,
    history,
    navbarToggle,
    navbar,
    engagementData,
  } = props;

  return (
    <nav className="elevation-2 bg-sprxToolbar">
      <div className="mx-auto">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex justify-center">
            {/* TOGGLE NAVBAR START */}
            {navbar.folded && (
              <button
                className="h-16 w-16 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none hover:bg-gray-200 transition ease-in-out duration-150"
                aria-label="Open sidebar"
                onClick={navbarToggle}
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            )}

            {/* TOGGLE NAVBAR END */}
            <div className="flex flex-1">
              <div className="flex-1 flex justify-center px-4">
                {/* SEARCHBAR START */}
                {history.location.pathname.includes("clientlist") ? (
                  <div className="w-full pt-2">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        id="search"
                        className="block w-full h-12 elevation-1 pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 text-black placeholder-gray-400 focus:outline-none focus:bg-white focus:text-gray-900 sm:text-sm transition duration-150 ease-in-out"
                        placeholder="Search"
                        type="search"
                        value={searchText}
                        onChange={(newValue) => {
                          setSearchText(newValue.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                {/* SEARCHBAR END */}
              </div>
            </div>
            {/* USERMENU START */}
            <div className="flex">
              <UserMenu />
            </div>
            {/* USERMENU END */}
          </div>
        </div>
      </div>
    </nav>
  );
};

function mapStateToProps({ sprx, admin }) {
  return {
    searchText: admin.admin.searchText,
    navbar: sprx.navbar,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSearchText: adminActions.setSearchText,
      navbarToggle: Actions.navbarToggle,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolBar)
);
