import React from "react";

const ProjectTiles = (props) => {
  return (
    <div className="mb-4 w-full grid grid-cols-1 gap-3 sm:grid-cols-4">
      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Qualified Projects
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.qProjects}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Non-Qualified Projects
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.nqProjects}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Total Projects
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.totalProjects}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Total QREs
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {"$" +
                props.totalQRES
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ProjectTiles;
