import * as Actions from "../actions";

const initialState = {
  addSuccess: false,
  fetchSuccess: false,
  userSuccess: false,
  error: {},
  firms: {},
  roles: {},
  users: {},
  firmData: {},
  firmDataFetchSuccess: false,
};

const firms = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FIRM_CREATE_SUCCESS: {
      return {
        ...state,
        addSuccess: true,
      };
    }
    case Actions.FIRM_CREATE_FAILURE: {
      return {
        ...state,
        addSuccess: false,
        error: action.payload,
      };
    }
    case Actions.FIRM_LIST_SUCCESS: {
      return {
        ...state,
        firms: action.payload,
        fetchSuccess: true,
      };
    }
    case Actions.FIRM_LIST_FAILURE: {
      return {
        ...state,
        fetchSuccess: false,
        error: action.payload,
      };
    }
    case Actions.GET_FIRM_SUCCESS: {
      return {
        ...state,
        firmData: action.payload,
        firmDataFetchSuccess: true,
      };
    }
    case Actions.GET_FIRM_FAILURE: {
      return {
        ...state,
        firmDataFetchSuccess: false,
        error: action.payload,
      };
    }
    case Actions.UPDATE_FIRM_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.UPDATE_FIRM_FAILURE: {
      return {
        ...state,
      };
    }
    case Actions.OCR_DOCUMENT_DOWNLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.OCR_DOCUMENT_DOWNLOAD_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.EXTRACT_OCR_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.EXTRACT_OCR_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPLOAD_OCR_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.UPLOAD_OCR_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.USER_LIST_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        userSuccess: true,
      };
    }
    case Actions.USER_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.CREATE_TICKET_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.CREATE_TICKET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.RUN_ML_MODEL_ESTIMATE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.RUN_ML_MODEL_ESTIMATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default firms;
