import React from "react";
import classNames from "classnames";

const ButtonBodyContainer = (props) => {
  return (
    <div
      className={classNames(
        "flex flex-row min-w-buttonWidth items-center justify-center p-4 mb-8",
        props.className && props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default ButtonBodyContainer;
