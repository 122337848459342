import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";
import { authRoles } from "../../auth";

export const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    // {
    //   path: "/login",
    //   component: SprxLoadable({
    //     loader: () => import("./Login"),
    //   }),
    // },
    // {
    //   path: "/loginChallenge",
    //   component: SprxLoadable({
    //     loader: () => import("./LoginChallenge"),
    //   }),
    // },
    // {
    //   path: "/finalizeChallenge/:challenge",
    //   component: SprxLoadable({
    //     loader: () => import("./LoginChallengeProcessing"),
    //   }),
    // },
    {
      path: "/login",
      component: SprxLoadable({
        loader: () => import("./LoginChallenge"),
      }),
    },
    {
      path: "/finalize-login/:challenge",
      component: SprxLoadable({
        loader: () => import("./LoginChallenge"),
      }),
    },
  ],
};
