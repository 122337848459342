import React from "react";

const AppGrid = (props) => {
  return (
    <div className="px-2 w-full">
      <div className="flex flex-wrap -mx-2">{props.children}</div>
    </div>
  );
};

export default AppGrid;
