import * as Actions from "../actions";

const initialState = {
  success: false,
  codeSuccess: false,
  error: {
    username: null,
    password: null
  }
};

const login = function(state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS: {
      return {
        ...initialState,
        success: true
      };
    }
    case Actions.LOGIN_ERROR: {
      return {
        success: false,
        error: action.payload
      };
    }
    case Actions.RESET_SUCCESS: {
      return {
        ...initialState
      };
    }
    case Actions.RESET_ERROR: {
      return {
        error: action.payload
      };
    }
    case Actions.CODE_REQUEST_SUCCESS: {
      return {
        ...initialState,
        codeSuccess: true
      };
    }
    case Actions.CODE_REQUEST_ERROR: {
      return {
        ...initialState,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default login;
