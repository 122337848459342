import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";
import React from "react";
import { Redirect } from "react-router-dom";

export const CostSegEngagementConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/cs/engagement/:engagementId?/dashboard",
      component: SprxLoadable({
        loader: () => import("./CostSegEngagementDashboard"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/asset-upload",
      component: SprxLoadable({
        loader: () => import("./CostSegAssetUpload"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/estimator",
      component: SprxLoadable({
        loader: () => import("./CostSegEstimator"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/segregator",
      component: SprxLoadable({
        loader: () => import("./CostSegSegregator"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/compute",
      component: SprxLoadable({
        loader: () => import("./CostSegEngagementCompute"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/review",
      component: SprxLoadable({
        loader: () => import("./CostSegEngagementReview"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/finalize-cs",
      component: SprxLoadable({
        loader: () => import("./CostSegFinalize"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/reports",
      component: SprxLoadable({
        loader: () => import("./CostSegEngagementReports"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/notes",
      component: SprxLoadable({
        loader: () => import("./CostSegNotes"),
      }),
    },
    {
      path: "/cs/engagement/:engagementId?/engagement-settings",
      component: SprxLoadable({
        loader: () => import("./CostSegEngagementSettings"),
      }),
    },
    {
      path: "/engagement",
      component: () => <Redirect to="/home" />,
    },
  ],
};
