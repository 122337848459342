import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { matchRoutes, renderRoutes } from "react-router-config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import LeftSideLayout from "./components/LeftSideLayout";
import NavigationBarWrapper from "./components/NavigationBarWrapper";
import * as Actions from "../../store/actions";
import AppContext from "../../AppContext";
import FoundationContainer from "./components/FoundationContainer";
import ToolBar from "./components/ToolBar";
import ScrollBars from "./components/ScrollBars";
import Dialog from "./components/Dialog";
import Message from "./components/Message";

var settings = {
  layout: {
    config: {
      navbar: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      leftSidePanel: {
        display: false,
      },
    },
  },
};

class SprxLayout extends Component {
  constructor(props, context) {
    super(props);
    this.appContext = context;
    this.routeSettingsCheck();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      this.routeSettingsCheck();
    }
  }

  routeSettingsCheck = () => {
    const { routes } = this.appContext;

    const matched = matchRoutes(routes, this.props.location.pathname)[0];

    if (matched && matched.route.settings) {
      const routeSettings = _.merge({}, settings, matched.route.settings);
      if (!_.isEqual(settings, routeSettings)) {
        const newSetings = _.merge({}, routeSettings);
        settings = newSetings;
      }
    } else {
      settings = {
        layout: {
          config: {
            navbar: {
              display: false,
            },
            toolbar: {
              display: false,
            },
            leftSidePanel: {
              display: false,
            },
          },
        },
      };
    }
  };

  render() {
    const { children } = this.props;

    return (
      <AppContext.Consumer>
        {({ routes }) => (
          <FoundationContainer>
            {settings.layout.config.leftSidePanel.display && <LeftSideLayout />}

            <div className="flex flex-1 flex-col overflow-hidden relative">
              <div className="flex flex-auto relative w-screen h-screen">
                {settings.layout.config.navbar.display && (
                  <NavigationBarWrapper />
                )}

                <div className="flex flex-col relative z-10 overflow-hidden flex-auto">
                  {settings.layout.config.toolbar.display && <ToolBar />}

                  <ScrollBars>
                    <Dialog />
                    {renderRoutes(routes)}
                    {children}
                  </ScrollBars>
                </div>
                <Message />
              </div>
            </div>
          </FoundationContainer>
        )}
      </AppContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ sprx }) {
  return {
    sprx: sprx,
  };
}

SprxLayout.contextType = AppContext;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SprxLayout)
);
