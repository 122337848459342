import React from "react";
import classNames from "classnames";

const SubHeading = (props) => {
  return (
    <h2
      className={classNames(
        "font-light text-base text-center",
        props.className && props.className
      )}
    >
      {props.children}
    </h2>
  );
};

export default SubHeading;
