import React from "react";
import classNames from "classnames";
import { ResponsivePie } from "@nivo/pie";

const GaugeGraph = (props) => {
  return (
    <div
      className={classNames(
        "bg-white overflow-hidden rounded-lg elevation-2",
        props.height && props.height,
        props.width && props.width
      )}
    >
      <h2 className={classNames("font-light text-base text-center my-1")}>
        {props.title}
      </h2>
      <div className={classNames(props.graphHeight && props.graphHeight)}>
        <ResponsivePie
          data={props.data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={props.innerRadius}
          startAngle={-90}
          endAngle={90}
          padAngle={0}
          cornerRadius={0}
          colors={[
            "#9E9EAE",
            "#8DC3E4",
            "#E4AF8D",
            "#E4DA8D",
            "#FBFFAD",
            "#7ED4A6",
            "#C5555B",
            "#D6514A",
            "#D6694A",
            "#907590",
            "#D6884A",
            "#7F80A1",
            "#D5B64A",
            "#6D8BB2",
            "#4AA36C",
            "#4A95D5",
            "#C4785C",
            "#C4B65C",
            "#3BA37B",
          ]}
          // colors={(d) => d.color}
          // colors={{ scheme: props.colors ? props.colors : "nivo" }}          borderWidth={0}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          radialLabelsSkipAngle={5}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: "color" }}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="#333333"
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              anchor: "left",
              direction: "column",
              translateX: -30,
              itemWidth: 80,
              itemHeight: 22,
              itemTextColor: "#999",
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
          onClick={(node, event) => {
            console.log(node);
            console.log(event);
          }}
        />
      </div>
    </div>
  );
};

export default GaugeGraph;
