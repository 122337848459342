import React from "react";

const ColBodyContainer = (props) => {
  return (
    <div className="flex flex-col min-h-colBodyHeight w-full items-center justify-center p-4 mb-4">
      {props.children}
    </div>
  );
};

export default ColBodyContainer;
