import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";
import React from "react";
import { Redirect } from "react-router-dom";

export const EngagementConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/rd/engagement/:engagementId?/dashboard",
      component: SprxLoadable({
        loader: () => import("./EngagementDashboard"),
      }),
    },
    // {
    //   path: "/rd/engagement/:engagementId?/6765-upload",
    //   component: SprxLoadable({
    //     loader: () => import("./Form6765Upload"),
    //   }),
    // },
    {
      path: "/rd/engagement/:engagementId?/current-year-project-upload",
      component: SprxLoadable({
        loader: () => import("./ProjectUpload"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/current-year-wage-upload",
      component: SprxLoadable({
        loader: () => import("./WageUpload"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/current-year-supply-upload",
      component: SprxLoadable({
        loader: () => import("./SupplyUpload"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/current-year-contract-research-upload",
      component: SprxLoadable({
        loader: () => import("./ContractResearchUpload"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/current-year-supporting-documentation-upload",
      component: SprxLoadable({
        loader: () => import("./DocumentationUpload"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/current-year-computer-lease-upload",
      component: SprxLoadable({
        loader: () => import("./ComputerLeaseUpload"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/project-classification",
      component: SprxLoadable({
        loader: () => import("./ProjectClassification"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/project-qualification",
      component: SprxLoadable({
        loader: () => import("./ProjectQualification"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/controlled-group",
      component: SprxLoadable({
        loader: () => import("./ControlledGroup"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/calculation-elections",
      component: SprxLoadable({
        loader: () => import("./CalculationElections"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/calculation-method",
      component: SprxLoadable({
        loader: () => import("./CalculationMethod"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/state-credit-profile",
      component: SprxLoadable({
        loader: () => import("./StateCreditProfile"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/compute-qre",
      component: SprxLoadable({
        loader: () => import("./ComputeQRE"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/elections-review",
      component: SprxLoadable({
        loader: () => import("./ElectionsReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/documentation-review",
      component: SprxLoadable({
        loader: () => import("./DocumentationReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/project-qualification-review",
      component: SprxLoadable({
        loader: () => import("./ProjectQualificationReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/project-allocation-review",
      component: SprxLoadable({
        loader: () => import("./ProjectAllocationReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/wage-qre-review",
      component: SprxLoadable({
        loader: () => import("./WageQREReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/supply-qre-review",
      component: SprxLoadable({
        loader: () => import("./SupplyQREReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/contract-research-qre-review",
      component: SprxLoadable({
        loader: () => import("./CRQREReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/computer-lease-qre-review",
      component: SprxLoadable({
        loader: () => import("./CompLeaseQREReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/state-credit-review",
      component: SprxLoadable({
        loader: () => import("./StateCreditReview"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/6765-review",
      component: SprxLoadable({
        loader: () => import("./Form6765Review"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/finalize-engagement",
      component: SprxLoadable({
        loader: () => import("./FinalizeEngagement"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/fileable-form-6765",
      component: SprxLoadable({
        loader: () => import("./Fileable6765"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/state-credit-forms",
      component: SprxLoadable({
        loader: () => import("./StateCreditForms"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/project-list-report",
      component: SprxLoadable({
        loader: () => import("./ProjectListReport"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/employee-list-report",
      component: SprxLoadable({
        loader: () => import("./EmployeeListReport"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/supply-list-report",
      component: SprxLoadable({
        loader: () => import("./SupplyListReport"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/contract-research-list-report",
      component: SprxLoadable({
        loader: () => import("./CRListReport"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/computer-lease-list-report",
      component: SprxLoadable({
        loader: () => import("./CompLeaseListReport"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/documentation-report",
      component: SprxLoadable({
        loader: () => import("./DocumentationReport"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/engagement-analysis-statements",
      component: SprxLoadable({
        loader: () => import("./EngagementAnalysisStatements"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/notes",
      component: SprxLoadable({
        loader: () => import("./Notes"),
      }),
    },
    {
      path: "/rd/engagement/:engagementId?/engagement-settings",
      component: SprxLoadable({
        loader: () => import("./EngagementSettings"),
      }),
    },
    // {
    //   path: "/rd/engagement/:engagementId?/client-actions",
    //   component: SprxLoadable({
    //     loader: () => import("./ClientActions"),
    //   }),
    // },
    {
      path: "/engagement",
      component: () => <Redirect to="/home" />,
    },
  ],
};
