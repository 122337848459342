import React from "react";
import classNames from "classnames";
import MaterialTable from "material-table";

const DashboardCard = (props) => {
  return (
    <div
      className={classNames("bg-white overflow-hidden rounded-lg elevation-2")}
    >
      <MaterialTable
        title={props.title}
        columns={props.columns}
        data={props.data}
        isLoading={props.isLoading}
        actions={props.actions}
        editable={props.editable}
        options={props.options}
        onSelectionChange={props.onSelectionChange}
      />
    </div>
  );
};

export default DashboardCard;
