import React, { useState } from "react";
import classNames from "classnames";
import MaterialTable, { MTableToolbar, MTableAction } from "material-table";
import { Icon } from "./index";

const AppTableEstimator = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className={classNames(
        "w-full m-4",
        props.parentClassName && props.parentClassName
      )}
    >
      <MaterialTable
        title={props.title}
        columns={props.columns}
        data={props.data}
        isLoading={props.isLoading}
        actions={props.actions}
        editable={props.editable}
        options={props.options}
        onSelectionChange={props.onSelectionChange}
        detailPanel={props.detailPanel}
        tableRef={props.tableRef}
        // onRowClick={(event, rowData, togglePanel) => togglePanel()}
        components={
          (props.customToolbar
            ? {
                Toolbar: (toolBarProps) => (
                  <div>
                    <MTableToolbar {...toolBarProps} />
                    <button
                      className={classNames(
                        "flex justify-between bg-gray-100 p-4 w-full text-left border-none outline-none focus:outline-none duration-500"
                      )}
                      onClick={() => setExpanded(!expanded)}
                    >
                      Conservativity Profile
                      <Icon
                        className="text-black self-center text-lg flex-no-shrink"
                        icon={expanded ? "expand_less" : "expand_more"}
                      />
                    </button>
                    <div
                      className={classNames(
                        "flex flex-col px-2 pt-2 bg-gray-100",
                        !expanded && "hidden"
                      )}
                    >
                      <div className="flex flex-row">
                        <p className="font-light text-center text-sm px-2">
                          More Conservative
                        </p>
                        <input
                          className="w-full"
                          onChange={props.onChange}
                          type="range"
                          id="outlook"
                          name="outlook"
                          min="1"
                          max="10"
                          step=".01"
                          defaultValue={props.rangeValue}
                        />
                        <p className="font-light text-center text-sm px-2">
                          Less Conservative
                        </p>
                      </div>
                      <div className={"flex flex-row justify-center"}>
                        <button
                          className={classNames(
                            "my-2 mr-2 self-center text-white font-bold py-2 px-4 min-w-80 elevation-2 text-center focus:outline-none rounded",
                            "bg-sprxGrey hover:bg-gray-400"
                          )}
                          onClick={props.onClickAdjust}
                        >
                          <p className="font-light text-base text-center">
                            Adjust Profile
                          </p>
                        </button>
                        <button
                          className={classNames(
                            "my-2 ml-2 self-center text-white font-bold py-2 px-4 min-w-80 elevation-2 text-center focus:outline-none rounded",
                            "bg-sprxGrey hover:bg-gray-400"
                          )}
                          onClick={props.onClickRevert}
                        >
                          <p className="font-light text-base text-center">
                            Revert to Original
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                ),
              }
            : null,
          props.customActions
            ? {
                Action: (props) => {
                  //If isn't the add action
                  if (
                    typeof props.action === typeof Function ||
                    props.action.tooltip !== "Add"
                  ) {
                    return <MTableAction {...props} />;
                  } else {
                    return null;
                  }
                },
              }
            : null)
        }
      />
    </div>
  );
};

export default AppTableEstimator;
