import history from "../../../../history";
import _ from "lodash";
import amplifyService from "../../../api/amplifyService";

export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";

/**
 * Set user data from AWS data
 */
export function setUserDataAWS(user, authUser) {
  if (user && user.data) {
    // Set user data but do not update
    return setUserData(user);
  } else {
    // Create missing user settings
    return createUserSettingsAWS(authUser);
  }
}

/**
 * Create User Settings with AWS data
 */
export function createUserSettingsAWS(authUser) {
  return (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const currentUser = amplifyService.getCurrentUser();

    /**
     * Merge with current Settings
     */
    if (authUser.role === "admin") {
      const user = _.merge({}, guestUser, {
        userID: authUser.uid,
        role: authUser.role,
        fullName: authUser.fullName,
        data: {
          displayName: authUser.firstName,
          email: authUser.email,
        },
      });

      return dispatch(setUserData(user));
    } else {
      const user = _.merge({}, guestUser, {
        userID: authUser.uid,
        role: authUser.role,
        firmName: authUser.firmName,
        firmID: authUser.firmId,
        fullName: authUser.fullName,
        data: {
          displayName: authUser.firstName,
          email: authUser.email,
          createdBy: currentUser.uid,
        },
      });

      return dispatch(setUserData(user));
    }
  };
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return (dispatch) => {
    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const user = getState().auth.user;

    if (user.role === "guest") {
      return null;
    }

    history.push({
      pathname: "/",
    });

    amplifyService.signOut();

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
