import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import NavCollapse from "./NavCollapse";
import NavItem from "./NavItem";

const propTypes = {
  navigation: PropTypes.array.isRequired,
};

const defaultProps = {
  layout: "vertical",
};

const SprxNavigation = ({ navigation, layout, active, dense, className }) => {
  const Nav = (
    <ul className={classNames("navigation whitespace-no-wrap pt-2", className)}>
      {navigation.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === "collapse" && (
            <NavCollapse
              item={item}
              nestedLevel={0}
              active={active}
              dense={dense}
            />
          )}

          {item.type === "item" && (
            <NavItem
              item={item}
              nestedLevel={0}
              active={active}
              dense={dense}
            />
          )}
        </React.Fragment>
      ))}
    </ul>
  );

  if (navigation.length > 0) {
    switch (layout) {
      case "horizontal":
      case "vertical":
      default: {
        return Nav;
      }
    }
  } else {
    return null;
  }
};

SprxNavigation.propTypes = propTypes;
SprxNavigation.defaultProps = defaultProps;

export default withRouter(SprxNavigation);
