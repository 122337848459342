import React from "react";
import classNames from "classnames";

const ClientCard = (props) => {
  return (
    <div className="bg-white overflow-hidden elevation-2 rounded h-full">
      <button
        className={classNames(
          "h-full w-full focus:outline-none",
          !props.disabled && "hover:bg-gray-200",
          props.disabled && "opacity-50 cursor-not-allowed"
        )}
        onClick={!props.disabled ? props.onClick : undefined}
      >
        <div className="flex h-70/100 items-center justify-center">
          <img className="h-full w-full p-2" src={props.image} alt="" />
        </div>
        <div className="flex h-1/5 font-medium text-2xl items-center justify-center text-center">
          {props.engagementName}
        </div>
        <div className="flex text-lg h-1/10 font-light items-center justify-center text-center">
          {props.clientName}
        </div>
      </button>
    </div>
  );
};

export default ClientCard;
