import React from "react";
import { Button } from "./";

const QREDashboardTiles = (props) => {
  return (
    <div className="mb-4 w-full grid grid-cols-1 gap-3 sm:grid-cols-4">
      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              {props.tileOne.title}
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.tileOne.amount}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              {props.tileTwo.title}
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.tileTwo.amount}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              {props.tileThree.title}
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.tileThree.amount}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              {props.tileFour.title}
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {props.tileFour.amount}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default QREDashboardTiles;
