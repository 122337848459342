import * as Actions from "../../store/actions";

export const ROLE_SUCCESS = "ROLE_SUCCESS";
export const ROLE_FAILURE = "ROLE_FAILURE";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

export function getRoleList() {
  return (dispatch) => {
    var roles = ["staff", "manager", "client"];
    return dispatch({
      type: ROLE_SUCCESS,
      payload: roles,
    });
  };
}

export function setSearchText(value) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: value,
  };
}

export function setEngagementNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "engagement-dashboard",
          title: "Dashboard",
          type: "item",
          icon: "dashboard",
          url: `/rd/engagement/${engagementId}/dashboard`,
        },
        {
          id: "data-load",
          title: "Data Load",
          type: "collapse",
          icon: "cloud_upload",
          children: [
            // {
            //   id: "6765-upload",
            //   title: "6765 Upload",
            //   type: "item",
            //   url: `/rd/engagement/${engagementId}/6765-upload`,
            //   icon: "assignment",
            // },
            {
              id: "current-year-project-upload",
              title: "Project Upload",
              type: "item",
              url: `/rd/engagement/${engagementId}/current-year-project-upload`,
              icon: "description",
            },
            {
              id: "current-year-wage-upload",
              title: "Wage Upload",
              type: "item",
              url: `/rd/engagement/${engagementId}/current-year-wage-upload`,
              icon: "face",
            },
            {
              id: "current-year-supply-upload",
              title: "Supply Upload",
              type: "item",
              url: `/rd/engagement/${engagementId}/current-year-supply-upload`,
              icon: "business",
            },
            {
              id: "current-year-contract-research-upload",
              title: "CR Upload",
              type: "item",
              url: `/rd/engagement/${engagementId}/current-year-contract-research-upload`,
              icon: "supervisor_account",
            },
            {
              id: "current-year-computer-lease-upload",
              title: "Computer Lease Upload",
              type: "item",
              url: `/rd/engagement/${engagementId}/current-year-computer-lease-upload`,
              icon: "computer",
            },
            // {
            //   id: "current-year-supporting-documentation-upload",
            //   title: "Documentation Upload",
            //   type: "item",
            //   url: `/rd/engagement/${engagementId}/current-year-supporting-documentation-upload`,
            //   icon: "folder_shared",
            // },
          ],
        },
        {
          id: "analysis",
          title: "Analysis",
          type: "collapse",
          icon: "laptop",
          children: [
            {
              id: "project-classification",
              title: "Project Classification",
              type: "item",
              url: `/rd/engagement/${engagementId}/project-classification`,
              icon: "description",
            },
            {
              id: "project-qualification",
              title: "Project Qualification",
              type: "item",
              url: `/rd/engagement/${engagementId}/project-qualification`,
              icon: "description",
            },
            {
              id: "controlled-group",
              title: "Controlled Group",
              type: "item",
              url: `/rd/engagement/${engagementId}/controlled-group`,
              icon: "class",
            },
            {
              id: "calculation-elections",
              title: "Calculation Elections",
              type: "item",
              url: `/rd/engagement/${engagementId}/calculation-elections`,
              icon: "format_list_numbered",
            },
            {
              id: "calculation-method",
              title: "Calculation Methodology",
              type: "item",
              url: `/rd/engagement/${engagementId}/calculation-method`,
              icon: "build",
            },
            {
              id: "state-credit-profile",
              title: "State Credit Profile",
              type: "item",
              url: `/rd/engagement/${engagementId}/state-credit-profile`,
              icon: "account_balance",
            },
            {
              id: "compute-qre",
              title: "Compute QRE",
              type: "item",
              url: `/rd/engagement/${engagementId}/compute-qre`,
              icon: "attach_money",
            },
          ],
        },
        {
          id: "review",
          title: "Review",
          type: "collapse",
          icon: "thumb_up",
          children: [
            {
              id: "wage-qre-review",
              title: "Wage QRE Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/wage-qre-review`,
              icon: "face",
            },
            {
              id: "supply-qre-review",
              title: "Supply QRE Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/supply-qre-review`,
              icon: "business",
            },
            {
              id: "contract-research-qre-review",
              title: "CR QRE Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/contract-research-qre-review`,
              icon: "supervisor_account",
            },
            {
              id: "computer-lease-qre-review",
              title: "Rental/Lease QRE Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/computer-lease-qre-review`,
              icon: "computer",
            },
            {
              id: "state-credit-review",
              title: "State Credit Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/state-credit-review`,
              icon: "account_balance",
            },
            {
              id: "project-qualification-review",
              title: "Project Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/project-qualification-review`,
              icon: "description",
            },
            {
              id: "project-allocation-review",
              title: "Project Allocation Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/project-allocation-review`,
              icon: "ballot",
            },
            {
              id: "elections-review",
              title: "Elections Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/elections-review`,
              icon: "check",
            },
            {
              id: "6765-review",
              title: "6765 Review",
              type: "item",
              url: `/rd/engagement/${engagementId}/6765-review`,
              icon: "assignment",
            },
          ],
        },
        {
          id: "documentation-review",
          title: "Documentation Review",
          type: "item",
          url: `/rd/engagement/${engagementId}/documentation-review`,
          icon: "find_in_page",
        },
        {
          id: "finalize-engagement",
          title: "Finalize",
          type: "item",
          icon: "assessment",
          url: `/rd/engagement/${engagementId}/finalize-engagement`,
        },
        {
          id: "reports",
          title: "Reports",
          type: "collapse",
          icon: "assignment_turned_in",
          children: [
            {
              id: "fileable-form-6765",
              title: "Fileable Form 6765",
              type: "item",
              url: `/rd/engagement/${engagementId}/fileable-form-6765`,
              icon: "assignment_turned_in",
            },
            // {
            //   id: "state-credit-forms",
            //   title: "State Credit Forms",
            //   type: "item",
            //   url: `/rd/engagement/${engagementId}/state-credit-forms`,
            //   icon: "book",
            // },
            {
              id: "supporting-schedules",
              title: "Supporting Schedules",
              type: "collapse",
              icon: "view_headline",
              children: [
                {
                  id: "project-list-report",
                  title: "Project List Report",
                  type: "item",
                  url: `/rd/engagement/${engagementId}/project-list-report`,
                  icon: "description",
                },
                {
                  id: "employee-list-report",
                  title: "Employee List Report",
                  type: "item",
                  url: `/rd/engagement/${engagementId}/employee-list-report`,
                  icon: "face",
                },
                {
                  id: "supply-list-report",
                  title: "Supply List Report",
                  type: "item",
                  url: `/rd/engagement/${engagementId}/supply-list-report`,
                  icon: "business",
                },
                {
                  id: "contract-research-list-report",
                  title: "CR List Report",
                  type: "item",
                  url: `/rd/engagement/${engagementId}/contract-research-list-report`,
                  icon: "supervisor_account",
                },
                {
                  id: "computer-lease-list-report",
                  title: "Rental/Lease List Report",
                  type: "item",
                  url: `/rd/engagement/${engagementId}/computer-lease-list-report`,
                  icon: "computer",
                },
                {
                  id: "documentation-report",
                  title: "Documentation Report",
                  type: "item",
                  url: `/rd/engagement/${engagementId}/documentation-report`,
                  icon: "assignment_turned_in",
                },
              ],
            },
            {
              id: "engagement-analysis-statements",
              title: "Analysis Statements",
              type: "item",
              url: `/rd/engagement/${engagementId}/engagement-analysis-statements`,
              icon: "extension",
            },
          ],
        },
        {
          id: "Notes",
          title: "Notes",
          type: "item",
          icon: "speaker_notes",
          url: `/rd/engagement/${engagementId}/notes`,
        },
        // {
        //   id: "client-actions",
        //   title: "Client Actions",
        //   type: "item",
        //   icon: "dashboard",
        //   url: `/rd/engagement/${engagementId}/client-actions`,
        // },
        {
          id: "EngagementSettings",
          title: "Engagement Settings",
          type: "item",
          icon: "settings",
          url: `/rd/engagement/${engagementId}/engagement-settings`,
        },
        {
          id: "client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/rd/clientlist",
        },
      ])
    );
}

export function setClientEngagementNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "engagement-dashboard",
          title: "Dashboard",
          type: "item",
          icon: "dashboard",
          url: `/rd/engagement/${engagementId}/client-review/dashboard`,
        },
        {
          id: "wage-qre-review",
          title: "Wage QRE Review",
          type: "item",
          url: `/rd/engagement/${engagementId}/client-review/wage-qre-review`,
          icon: "face",
        },
        {
          id: "supply-qre-review",
          title: "Supply QRE Review",
          type: "item",
          url: `/rd/engagement/${engagementId}/client-review/supply-qre-review`,
          icon: "business",
        },
        {
          id: "contract-research-qre-review",
          title: "CR QRE Review",
          type: "item",
          url: `/rd/engagement/${engagementId}/client-review/contract-research-qre-review`,
          icon: "supervisor_account",
        },
        {
          id: "client-engagement-list",
          title: "Engagement List",
          type: "item",
          url: `/rd/client-engagement-list`,
          icon: "home",
        },
      ])
    );
}

export function setClientEngagementListNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "client-engagement-list",
          title: "Engagement List",
          type: "item",
          url: `/rd/client-engagement-list`,
          icon: "view_list",
        },
      ])
    );
}

export function setPluginNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        // {
        //   id: "exit-plugin",
        //   title: "Exit Plugin",
        //   type: "item",
        //   icon: "exit_to_app",
        //   url: `/`
        // }
      ])
    );
}

export function setRDNavigaion() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/rd/clientlist",
        },
        {
          id: "ocr",
          title: "A.I.X Plugin",
          type: "item",
          icon: "library_books",
          url: "/rd/plugins/aix",
        },
        // {
        //   id: "transformer",
        //   title: "Transformer Plugin",
        //   type: "item",
        //   icon: "transform",
        //   url: "/rd/plugins/transformer",
        // },
        {
          id: "nto",
          title: "Sprx Insights Plugin",
          type: "item",
          icon: "flag",
          url: "/rd/plugins/insights",
        },
        // {
        //   id: "client-suite",
        //   title: "Client Suite Plugin",
        //   type: "item",
        //   icon: "supervisor_account",
        //   url: "/rd/plugins/client-suite",
        // },
        {
          id: "estimate",
          title: "Estimator Plugin",
          type: "item",
          icon: "dns",
          url: "/rd/plugins/estimate",
        },
        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setERCNavigaion() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "erc-client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/erc/clientlist",
        },
        {
          id: "erc-plugins",
          title: "State Orders",
          type: "item",
          icon: "toc",
          url: "/erc/plugins/orders",
        },

        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setERCEngagementNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "engagement-dashboard",
          title: "Dashboard",
          type: "item",
          icon: "dashboard",
          url: `/erc/engagement/${engagementId}/dashboard`,
        },
        {
          id: "analysis",
          title: "Analysis",
          type: "collapse",
          icon: "laptop",
          children: [
            {
              id: "controlled-group",
              title: "Controlled Group",
              type: "item",
              url: `/erc/engagement/${engagementId}/controlled-group`,
              icon: "class",
            },
            {
              id: "entity-identification",
              title: "Entity Identification",
              type: "item",
              url: `/erc/engagement/${engagementId}/entity-identification`,
              icon: "business",
            },
            {
              id: "entity-qualification",
              title: "Entity Qualification",
              type: "item",
              url: `/erc/engagement/${engagementId}/entity-qualification`,
              icon: "assignment_turned_in",
            },
          ],
        },
        {
          id: "data-load",
          title: "Data Load",
          type: "collapse",
          icon: "cloud_upload",
          children: [
            {
              id: "employee-information",
              title: "Employee Information",
              type: "item",
              url: `/erc/engagement/${engagementId}/employee-information`,
              icon: "face",
            },
            // {
            //   id: "health-care-costs",
            //   title: "Health Care Costs",
            //   type: "item",
            //   url: `/erc/engagement/${engagementId}/health-care-costs`,
            //   icon: "healing",
            // },
            {
              id: "state-information",
              title: "State Information",
              type: "item",
              url: `/erc/engagement/${engagementId}/state-information`,
              icon: "explore",
            },
          ],
        },
        {
          id: "compute-credit",
          title: "Compute Credit",
          type: "item",
          url: `/erc/engagement/${engagementId}/compute-erc`,
          icon: "attach_money",
        },
        {
          id: "review",
          title: "Review",
          type: "collapse",
          icon: "thumb_up",
          children: [
            {
              id: "employee-erc-review",
              title: "Employee Credit",
              type: "item",
              url: `/erc/engagement/${engagementId}/employee-erc-review`,
              icon: "face",
            },
            {
              id: "credit-review",
              title: "Credit Review",
              type: "item",
              url: `/erc/engagement/${engagementId}/credit-review`,
              icon: "account_balance",
            },
            {
              id: "deliverable-review",
              title: "Deliverable Review",
              type: "item",
              url: `/erc/engagement/${engagementId}/deliverable-review`,
              icon: "all_inbox",
            },
          ],
        },
        {
          id: "finalize",
          title: "Finalize",
          type: "item",
          icon: "assessment",
          url: `/erc/engagement/${engagementId}/finalize-erc`,
        },
        {
          id: "reports",
          title: "Reports",
          type: "collapse",
          icon: "assignment_turned_in",
          children: [
            {
              id: "credit-detail-report",
              title: "Credit Detail Report",
              type: "item",
              url: `/erc/engagement/${engagementId}/credit-detail-report`,
              icon: "description",
            },
            {
              id: "engagement-analysis-statements",
              title: "Analysis Statements",
              type: "item",
              url: `/erc/engagement/${engagementId}/engagement-analysis-statements`,
              icon: "extension",
            },
          ],
        },
        {
          id: "Notes",
          title: "Notes",
          type: "item",
          icon: "speaker_notes",
          url: `/erc/engagement/${engagementId}/notes`,
        },
        {
          id: "EngagementSettings",
          title: "Engagement Settings",
          type: "item",
          icon: "settings",
          url: `/erc/engagement/${engagementId}/engagement-settings`,
        },
        {
          id: "client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/erc/clientlist",
        },
      ])
    );
}

export function setDXRNavigaion() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "dxr-client-list",
          title: "DXR Client List",
          type: "item",
          icon: "view_list",
          url: "/dxr/clientlist",
        },
        {
          id: "ocr",
          title: "A.I.X Plugin",
          type: "item",
          icon: "library_books",
          url: "/dxr/plugins/aix",
        },
        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setDXREngagementNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "engagement-dashboard",
          title: "Dashboard",
          type: "item",
          icon: "dashboard",
          url: `/dxr/engagement/${engagementId}/dashboard`,
        },
        {
          id: "documentation-upload",
          title: "Documentation Upload",
          type: "item",
          url: `/dxr/engagement/${engagementId}/documentation-upload`,
          icon: "cloud_upload",
        },
        {
          id: "documentation-review",
          title: "Documentation Review",
          type: "item",
          url: `/dxr/engagement/${engagementId}/documentation-review`,
          icon: "find_in_page",
        },
        {
          id: "finalize",
          title: "Finalize",
          type: "item",
          icon: "assessment",
          url: `/dxr/engagement/${engagementId}/finalize-dxr`,
        },
        {
          id: "reports",
          title: "Reports",
          type: "collapse",
          icon: "assignment_turned_in",
          children: [
            {
              id: "document-detail-report",
              title: "Document Detail Report",
              type: "item",
              url: `/dxr/engagement/${engagementId}/document-detail-report`,
              icon: "description",
            },
          ],
        },
        {
          id: "Notes",
          title: "Notes",
          type: "item",
          icon: "speaker_notes",
          url: `/dxr/engagement/${engagementId}/notes`,
        },
        {
          id: "EngagementSettings",
          title: "Engagement Settings",
          type: "item",
          icon: "settings",
          url: `/dxr/engagement/${engagementId}/engagement-settings`,
        },
        {
          id: "client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/dxr/clientlist",
        },
      ])
    );
}

export function setWOTCNavigaion() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "wotc-client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/wotc/clientlist",
        },
        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setITCNavigaion() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "itc-client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/itc/clientlist",
        },
        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setJobsNavigaion() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "jobs-client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/jobs/clientlist",
        },
        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setJobsEngagementNavigaion(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "engagement-dashboard",
          title: "Dashboard",
          type: "item",
          icon: "dashboard",
          url: `/jobs/engagement/${engagementId}/dashboard`,
        },
        {
          id: "data-load",
          title: "Data Load",
          type: "collapse",
          icon: "cloud_upload",
          children: [
            {
              id: "employee-information",
              title: "Employee Information",
              type: "item",
              url: `/jobs/engagement/${engagementId}/employee-information`,
              icon: "face",
            },
            {
              id: "location-information",
              title: "Location Information",
              type: "item",
              url: `/jobs/engagement/${engagementId}/location-information`,
              icon: "explore",
            },
          ],
        },
        {
          id: "analysis",
          title: "Analysis",
          type: "collapse",
          icon: "laptop",
          children: [
            {
              id: "state-credit-profile",
              title: "State Credit Profile",
              type: "item",
              url: `/jobs/engagement/${engagementId}/state-credit-profile`,
              icon: "account_balance",
            },
            {
              id: "compute-jobs",
              title: "Compute Credits",
              type: "item",
              url: `/jobs/engagement/${engagementId}/compute-jobs`,
              icon: "attach_money",
            },
          ],
        },
        {
          id: "credit-review",
          title: "Credit Review",
          type: "item",
          url: `/jobs/engagement/${engagementId}/review`,
          icon: "thumb_up",
        },
        {
          id: "finalize",
          title: "Finalize",
          type: "item",
          icon: "assessment",
          url: `/jobs/engagement/${engagementId}/finalize-jobs`,
        },
        {
          id: "reports",
          title: "Reports",
          type: "collapse",
          icon: "assignment_turned_in",
          children: [
            {
              id: "credit-detail-report",
              title: "Credit Detail Report",
              type: "item",
              url: `/jobs/engagement/${engagementId}/reports`,
              icon: "description",
            },
          ],
        },
        {
          id: "Notes",
          title: "Notes",
          type: "item",
          icon: "speaker_notes",
          url: `/jobs/engagement/${engagementId}/notes`,
        },
        {
          id: "EngagementSettings",
          title: "Engagement Settings",
          type: "item",
          icon: "settings",
          url: `/jobs/engagement/${engagementId}/engagement-settings`,
        },
        {
          id: "client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/jobs/clientlist",
        },
      ])
    );
}

export function setCSNavigation() {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "cs-client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/cs/clientlist",
        },
        {
          id: "cs-template",
          title: "Templator",
          type: "item",
          icon: "build",
          url: "/cs/plugins/template",
        },
        {
          id: "cs-aix",
          title: "A.I.X",
          type: "item",
          icon: "library_books",
          url: "/cs/plugins/aix",
        },
        {
          id: "home",
          title: "Home",
          type: "item",
          icon: "home",
          url: "/home",
        },
      ])
    );
}

export function setCSEngagementNavigation(engagementId) {
  return (dispatch) =>
    dispatch(
      Actions.setNavigation([
        {
          id: "engagement-dashboard",
          title: "Dashboard",
          type: "item",
          icon: "dashboard",
          url: `/cs/engagement/${engagementId}/dashboard`,
        },
        {
          id: "asset-upload",
          title: "Asset Upload",
          type: "item",
          icon: "cloud_upload",
          url: `/cs/engagement/${engagementId}/asset-upload`,
        },
        {
          id: "estimator",
          title: "Estimator",
          type: "item",
          icon: "build",
          url: `/cs/engagement/${engagementId}/estimator`,
        },
        {
          id: "segregator",
          title: "Segregator",
          type: "item",
          icon: "business",
          url: `/cs/engagement/${engagementId}/segregator`,
        },
        {
          id: "compute",
          title: "Compute",
          type: "item",
          icon: "attach_money",
          url: `/cs/engagement/${engagementId}/compute`,
        },
        {
          id: "review",
          title: "Review",
          type: "item",
          icon: "thumb_up",
          url: `/cs/engagement/${engagementId}/review`,
        },
        {
          id: "finalize",
          title: "Finalize",
          type: "item",
          icon: "assessment",
          url: `/cs/engagement/${engagementId}/finalize-cs`,
        },
        {
          id: "reports",
          title: "Reports",
          type: "item",
          icon: "description",
          url: `/cs/engagement/${engagementId}/reports`,
        },
        {
          id: "Notes",
          title: "Notes",
          type: "item",
          icon: "speaker_notes",
          url: `/cs/engagement/${engagementId}/notes`,
        },
        {
          id: "engagement-settings",
          title: "Engagement Settings",
          type: "item",
          icon: "settings",
          url: `/cs/engagement/${engagementId}/engagement-settings`,
        },
        {
          id: "client-list",
          title: "Client List",
          type: "item",
          icon: "view_list",
          url: "/cs/clientlist",
        },
      ])
    );
}
