import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";

export const PluginsConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/rd/plugins/aix",
      component: SprxLoadable({
        loader: () => import("./OCRPlugin"),
      }),
    },
    {
      path: "/rd/plugins/insights",
      component: SprxLoadable({
        loader: () => import("./NTOPlugin"),
      }),
    },
    // {
    //   path: "/rd/plugins/client-suite",
    //   component: SprxLoadable({
    //     loader: () => import("./ClientSuitePlugin"),
    //   }),
    // },
    {
      path: "/rd/plugins/estimate",
      component: SprxLoadable({
        loader: () => import("./EstimatePlugin"),
      }),
    },
    // {
    //   path: "/rd/plugins/transformer",
    //   component: SprxLoadable({
    //     loader: () => import("./TransformerPlugin"),
    //   }),
    // },
  ],
};
