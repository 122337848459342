import React from "react";
import classNames from "classnames";

const ERCResultsCard = (props) => {
  return (
    <div
      className={classNames("bg-white overflow-hidden rounded-lg elevation-2")}
    >
      <div className="border-b font-light text-base text-center border-gray-200 px-4 py-5 sm:px-6">
        Employee Retention Credit Results
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                Total Progress Credit
              </dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {"$" +
                  props.currentCredit
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                Total Potential Available Credit Remaining
              </dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {"$" +
                  props.totalAvailableCredit
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t-2 sm:border-gray-800 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                Total Potential Credit
              </dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {"$" +
                  props.potentialCredit
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ERCResultsCard;
