import axios from "axios";
import amplifyService from "../../api/amplifyService";
import * as Actions from "../../store/actions";

export const FIRM_CREATE_SUCCESS = "FIRM_CREATE_SUCCESS";
export const FIRM_CREATE_FAILURE = "FIRM_CREATE_FAILURE";
export const FIRM_LIST_SUCCESS = "FIRM_LIST_SUCCESS";
export const FIRM_LIST_FAILURE = "FIRM_LIST_FAILURE";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";
export const GET_FIRM_SUCCESS = "GET_FIRM_SUCCESS";
export const GET_FIRM_FAILURE = "GET_FIRM_FAILURE";
export const UPDATE_FIRM_SUCCESS = "UPDATE_FIRM_SUCCESS";
export const UPDATE_FIRM_FAILURE = "UPDATE_FIRM_FAILURE";
export const OCR_DOCUMENT_DOWNLOAD_SUCCESS = "OCR_DOCUMENT_DOWNLOAD_SUCCESS";
export const OCR_DOCUMENT_DOWNLOAD_FAILURE = "OCR_DOCUMENT_DOWNLOAD_FAILURE";
export const EXTRACT_OCR_DATA_SUCCESS = "EXTRACT_OCR_DATA_SUCCESS";
export const EXTRACT_OCR_DATA_FAILURE = "EXTRACT_OCR_DATA_FAILURE";
export const UPLOAD_OCR_DATA_SUCCESS = "UPLOAD_OCR_DATA_SUCCESS";
export const UPLOAD_OCR_DATA_FAILURE = "UPLOAD_OCR_DATA_FAILURE";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";
export const RUN_ML_MODEL_ESTIMATE_SUCCESS = "RUN_ML_MODEL_ESTIMATE_SUCCESS";
export const RUN_ML_MODEL_ESTIMATE_FAILURE = "RUN_ML_MODEL_ESTIMATE_FAILURE";

export function createNewFirm(model) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .createFirm(model)
        .then((firm) => {
          resolve(firm);

          dispatch(
            Actions.showMessage({
              message: `Created Firm with ID ${firm.params.firmID}`,
            })
          );

          return dispatch({
            type: FIRM_CREATE_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));

          reject(error);
          return dispatch({
            type: FIRM_CREATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getFirmList() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .listFirms()
        .then((firms) => {
          resolve(firms);
          return dispatch({
            type: FIRM_LIST_SUCCESS,
            payload: firms,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));

          reject(error);
          return dispatch({
            type: FIRM_LIST_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getUserList(firm) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .listFirmUsers(firm)
        .then((users) => {
          resolve(users);
          return dispatch({
            type: USER_LIST_SUCCESS,
            payload: users.params,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));

          reject(error);
          return dispatch({
            type: USER_LIST_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getFirmData(firmID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .getFirmData({ firmID: firmID })
        .then((firmData) => {
          resolve(firmData);

          return dispatch({
            type: GET_FIRM_SUCCESS,
            payload: firmData,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);

          return dispatch({
            type: GET_FIRM_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function updateFirm(firmID, data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var body = data;

      amplifyService
        .updateFirm({ firmID: firmID, body: body })
        .then((data) => {
          resolve(data);

          return dispatch({
            type: UPDATE_FIRM_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error);

          return dispatch({
            type: UPDATE_FIRM_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function ocrDocumentDownload(fileName, bucket) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: bucket,
          key: fileName,
          action: "getObject",
        })
        .then((signedURL) => {
          window.location.href = signedURL.data.signedRequest;
          resolve(signedURL);
          return dispatch({
            type: OCR_DOCUMENT_DOWNLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: OCR_DOCUMENT_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function ntoDocumentOpen(fileName, bucket) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: bucket,
          key: fileName,
          action: "getObject",
        })
        .then((signedURL) => {
          resolve(signedURL);
          return dispatch({
            type: OCR_DOCUMENT_DOWNLOAD_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: OCR_DOCUMENT_DOWNLOAD_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function uploadOCRDocument(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .requestSignedURL({
          bucket: data.bucket,
          key: data.key,
          action: data.action,
          type: data.type,
        })
        .then((signedURL) => {
          const options = {
            headers: {
              "Content-Type": data.type,
            },
          };

          var signedRequest = signedURL.data.signedRequest;

          var itemURL = signedURL.data.url;

          axios
            .put(signedRequest, data.file, options)
            .then((result) => {
              var fileObj = { result, url: itemURL, taxYear: data.taxYear };

              if (result.statusText === "OK") {
                // dispatch(extract6765Data(bucket, key, taxYear));
                resolve(fileObj);
                return dispatch({
                  type: UPLOAD_OCR_DATA_SUCCESS,
                  payload: fileObj,
                });
              }
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            })
            .catch((error) => {
              dispatch(Actions.showMessage({ message: error.message }));
              reject(error.message);
              return dispatch({
                type: UPLOAD_OCR_DATA_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function extractOCRData(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .extractOCRData({
          bucketName: data.bucketName,
          fileName: data.fileName,
          featureType: data.featureType,
          url: data.url,
          firmID: data.firmID,
        })
        .then((data) => {
          console.log(data);

          if (data.status === true) {
            console.log(data.response.body);

            amplifyService
              .pollOCRData(data.response.body)
              .then((poll) => {
                console.log(poll);
                dispatch(
                  Actions.showMessage({
                    message:
                      "Your document is processing. Please check the SPRX OCR Tool Library for status.",
                  })
                );
                resolve(true);
                return dispatch({
                  type: EXTRACT_OCR_DATA_SUCCESS,
                  payload: poll,
                });
              })
              .catch((error) => {
                console.log(error);

                dispatch(Actions.showMessage({ message: error.message }));
                reject(error.message);
                return dispatch({
                  type: EXTRACT_OCR_DATA_FAILURE,
                  payload: error.message,
                });
              });
          }
        })
        .catch((error) => {
          // console.log(error);

          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: EXTRACT_OCR_DATA_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function createTicket(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .createSupportTicket({
          subject: data.subject,
          content: data.content,
          email: data.email,
          phoneNumber: data.phoneNumber,
          userName: data.userName,
          userID: data.userID,
          firmName: data.firmName,
          firmID: data.firmID,
        })
        .then((result) => {
          console.log(result);
          dispatch(
            Actions.showMessage({
              message:
                "The ticket has been sucessfully created. Someone will reach out as soon as possible.",
            })
          );

          resolve(result);
          return dispatch({
            type: CREATE_TICKET_SUCCESS,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: CREATE_TICKET_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function runMLModelEstimate(employees) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .runMLModelEstimate({ body: employees })
        .then((mlObject) => {
          resolve(mlObject);
          return dispatch({
            type: RUN_ML_MODEL_ESTIMATE_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: RUN_ML_MODEL_ESTIMATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}
