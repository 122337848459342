import React from "react";

const FoundationContainer = (props) => {
  return (
    <div className="relative flex flex-row w-screen h-screen overflow-hidden">
      {props.children}
    </div>
  );
};

export default FoundationContainer;
