import { authRoles } from "../auth";

const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: "home",
    url: "/home",
  },
  {
    id: "team",
    title: "Team",
    type: "item",
    icon: "person_outline",
    url: "/team",
  },
  {
    id: "add-user",
    title: "Add User",
    type: "item",
    icon: "person_add",
    url: "/adduser",
    auth: authRoles.admin,
  },
  {
    id: "add-firm",
    title: "Add Firm",
    type: "item",
    icon: "business_center",
    url: "/addfirm",
    auth: authRoles.admin,
  },
  {
    id: "login",
    title: "Login",
    type: "item",
    url: "/login",
    auth: authRoles.onlyGuest,
    icon: "lock",
  },
  {
    id: "register",
    title: "Register",
    type: "item",
    url: "/register",
    auth: authRoles.onlyGuest,
    icon: "person_add",
  },
  {
    id: "forgotpassword",
    title: "Forgot Password",
    type: "item",
    url: "/forgotpassword",
    auth: authRoles.onlyGuest,
    icon: "person_add",
  },
];

export default navigationConfig;
