/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  manager: ["admin", "staff"],
  staff: ["admin", "manager", "staff"],
  client: ["admin", "manager", "staff", "client"],
  onlyGuest: ["guest"],
};

export default authRoles;
