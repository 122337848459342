import { combineReducers } from "redux";
import sprx from "./sprx";
import auth from "../../auth/store/reducers";
import clients from "../../logicStore/reducers";
import firms from "../../logicStore/reducers";
import admin from "../../logicStore/reducers";
import engagement from "../../logicStore/reducers";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    clients,
    firms,
    admin,
    engagement,
    sprx,
    ...asyncReducers,
  });

export default createReducer;
