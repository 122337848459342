const dev = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.sprx.app/dev",
    STATERDURL: "https://state-rd.api.sprx.app/dev",
    ERCURL: "https://erc.api.sprx.app/dev",
    DXRURL: "https://dxr.api.sprx.app/dev",
    JOBSURL: "https://jobs.api.sprx.app/dev",
    COSTSEGURL: "https://costseg.api.sprx.app/dev",
    PAYROLLCONNECT: "https://payroll-connect.api.sprx.app/dev",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_Ejltl1xA5",
    APP_CLIENT_ID: "2f67hn1egrpu2vrsujt3q0pimh",
    IDENTITY_POOL_ID: "us-east-2:43084e53-32ba-4c50-84e2-decc02e354dc",
    DOMAIN_URL: "dev-auth.sprx.app",
    REDIRECT_SIGNIN: "http://localhost:3000/home",
    REDIRECT_SIGNOUT: "http://localhost:3000/",
  },
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.sprx.app/prod",
    STATERDURL: "https://state-rd.api.sprx.app/prod",
    ERCURL: "https://erc.api.sprx.app/prod",
    DXRURL: "https://dxr.api.sprx.app/prod",
    JOBSURL: "https://jobs.api.sprx.app/prod",
    COSTSEGURL: "https://costseg.api.sprx.app/prod",
    PAYROLLCONNECT: "https://payroll-connect.api.sprx.app/prod",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_a1XNVsg87",
    APP_CLIENT_ID: "1af0t8s4hhiguu7ohht9nn6nr7",
    IDENTITY_POOL_ID: "us-east-2:b088ce69-c744-44d1-8ce6-62b006e6c43b",
    DOMAIN_URL: "prod-auth.sprx.app",
    REDIRECT_SIGNIN: "https://tool.sprx.app/home",
    REDIRECT_SIGNOUT: "https://tool.sprx.app/",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
