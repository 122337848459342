import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";
import React from "react";
import { Redirect } from "react-router-dom";

export const ErcEngagementConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/erc/engagement/:engagementId?/dashboard",
      component: SprxLoadable({
        loader: () => import("./ErcEngagementDashboard"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/controlled-group",
      component: SprxLoadable({
        loader: () => import("./ErcControlledGroup"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/entity-identification",
      component: SprxLoadable({
        loader: () => import("./ErcEntityID"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/entity-qualification",
      component: SprxLoadable({
        loader: () => import("./ErcEntityQualification"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/employee-information",
      component: SprxLoadable({
        loader: () => import("./EmployeeInformationUpload"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/health-care-costs",
      component: SprxLoadable({
        loader: () => import("./ErcHealthcareCostUpload"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/state-information",
      component: SprxLoadable({
        loader: () => import("./ErcStateInformation"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/compute-erc",
      component: SprxLoadable({
        loader: () => import("./ComputeERC"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/employee-erc-review",
      component: SprxLoadable({
        loader: () => import("./EmployeeERCReview"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/credit-review",
      component: SprxLoadable({
        loader: () => import("./ERCCreditReview"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/deliverable-review",
      component: SprxLoadable({
        loader: () => import("./ERCDeliverableReview"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/finalize-erc",
      component: SprxLoadable({
        loader: () => import("./FinalizeERCEngagement"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/credit-detail-report",
      component: SprxLoadable({
        loader: () => import("./ErcCreditDetailReport"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/engagement-analysis-statements",
      component: SprxLoadable({
        loader: () => import("./ErcAnalysisStatement"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/notes",
      component: SprxLoadable({
        loader: () => import("./ErcNotes"),
      }),
    },
    {
      path: "/erc/engagement/:engagementId?/engagement-settings",
      component: SprxLoadable({
        loader: () => import("./ErcEngagementSettings"),
      }),
    },
    {
      path: "/engagement",
      component: () => <Redirect to="/home" />,
    },
  ],
};
