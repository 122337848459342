import React from "react";
import classNames from "classnames";

const Heading = (props) => {
  return (
    <h1
      className={classNames(
        "font-normal text-2xl text-center",
        props.className && props.className
      )}
    >
      {props.children}
    </h1>
  );
};

export default Heading;
