import React from "react";
import classNames from "classnames";

const BasicToggle = (props) => {
  return (
    <span
      role="checkbox"
      tabIndex="0"
      aria-checked="false"
      className={classNames(
        "relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-none",
        props.checked ? "bg-sprxBlue" : "bg-gray-200"
      )}
      onClick={() => {
        var value = props.checked === true ? false : true;
        props.onClick(value);
      }}
    >
      <span
        aria-hidden="true"
        className={classNames(
          "inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",
          props.checked ? "translate-x-5" : "translate-x-0 "
        )}
      ></span>
    </span>
  );
};

export default BasicToggle;
