import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import createPlotlyComponent from "react-plotly.js/factory";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";

// create Plotly React component via dependency injection
const Plot = createPlotlyComponent(window.Plotly);

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivotTable = (props) => {
  const [data, setData] = useState(props.data);

  return (
    <div
      className={classNames("bg-white overflow-hidden rounded-lg elevation-2")}
    >
      <div className={"flex flex-col w-full"}>
        <PivotTableUI
          data={data}
          onChange={(s) => {
            setData(s);
          }}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...data}
        />
      </div>
    </div>
  );
};

export default PivotTable;
