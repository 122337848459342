import React from "react";

const AppGridTile = (props) => {
  return (
    <div className="w-1/3 mb-8 px-2">
      <div className="max-w-300 min-h-249 h-64">{props.children}</div>
    </div>
  );
};

export default AppGridTile;
