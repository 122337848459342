import React from "react";
import SprxNavigation from "./Navigation/SprxNavigation";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

const SprxNavigationWrapper = ({ navigation, layout, dense, className }) => {
  return (
    <SprxNavigation
      className={classNames("navigation", className)}
      navigation={navigation}
      layout={layout}
      dense={dense}
    />
  );
};

function mapStateToProps({ sprx }) {
  return {
    navigation: sprx.navigation,
  };
}

SprxNavigationWrapper.defaultProps = {
  layout: "vertical",
};

export default withRouter(connect(mapStateToProps)(SprxNavigationWrapper));
