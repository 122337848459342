import React from "react";
import classNames from "classnames";

const ArticleCard = (props) => {
  return (
    <div className="bg-white overflow-hidden overflow-hidden elevation-2 rounded h-full">
      <button
        className={classNames(
          "h-90/100 w-full focus:outline-none",
          !props.disabled && "hover:bg-gray-200",
          props.disabled && "opacity-50 cursor-not-allowed"
        )}
        onClick={!props.disabled ? props.onClick : undefined}
      >
        <div className="h-80/100">
          <img className="h-full w-full" src={props.articleImage} alt="" />
        </div>
        <div className="flex h-1/5 font-medium items-center justify-center text-center">
          {props.articleHeader}
        </div>
      </button>
      <div className="h-1/10 w-full">
        {props.tags.map((tag) => {
          return (
            <span
              key={tag}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 mx-1 bg-sprxGrey text-white"
            >
              {tag}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default ArticleCard;
