import * as Actions from "../actions";

const initialState = {
  fetchSuccess: false,
  setCreditDataSuccess: false,
  setCalculationDataSuccess: false,
  setAllocationMethodSuccess: false,
  form6765Loading: false,
  uploadLoading: false,
  error: {},
  engagementData: {},
  form6765Data: [],
  qreData: [],
  s3UploadURL: [],
  qreTemplateUploadURL: [],
  supportingDocUploadURL: [],
  documentList: [],
  uploadSuccess: false,
  surveyType: null,
  creditMethod: null,
  projectAllocationMethod: null,
  templateDownloading: false,
  docListDownloading: false,
  fundedProjects: [],
  iusProjects: [],
  qProjects: [],
  nqProjects: [],
  mlModelPredictions: {},
};

const engagement = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ENGAGEMENT_DATA_SUCCCESS: {
      return {
        ...state,
        engagementData: action.payload,
        fetchSuccess: true,
      };
    }
    case Actions.ENGAGEMENT_DATA_FAILURE: {
      return {
        ...state,
        fetchSuccess: false,
        error: action.payload,
      };
    }
    case Actions.ENGAGEMENT_DATA_RESET: {
      return {
        ...state,
        engagementData: initialState.engagementData,
      };
    }
    case Actions.SET_CREDIT_OVERVIEW_DATA_SUCCCESS: {
      return {
        ...state,
        setCreditDataSuccess: true,
      };
    }
    case Actions.SET_CREDIT_OVERVIEW_DATA_FAILURE: {
      return {
        ...state,
        setCreditDataSuccess: false,
        error: action.payload,
      };
    }
    case Actions.SET_CALCULATION_DATA_SUCCESS: {
      return {
        ...state,
        setCalculationDataSuccess: true,
      };
    }
    case Actions.SET_CALCULATION_DATA_FAILURE: {
      return {
        ...state,
        setCalculationDataSuccess: false,
        error: action.payload,
      };
    }
    case Actions.SET_ALLOCATION_METHOD_DATA_SUCCESS: {
      return {
        ...state,
        setAllocationMethodSuccess: true,
      };
    }
    case Actions.SET_ALLOCATION_METHOD_DATA_FAILURE: {
      return {
        ...state,
        setAllocationMethodSuccess: false,
        error: action.payload,
      };
    }
    case Actions.SELECT_SURVEY_TYPE: {
      return {
        ...state,
        surveyType: action.payload,
      };
    }
    case Actions.SELECT_CREDIT_METHOD: {
      return {
        ...state,
        creditMethod: action.payload,
      };
    }
    case Actions.SELECT_PROJECT_ALLOCATION_METHOD: {
      return {
        ...state,
        projectAllocationMethod: action.payload,
      };
    }

    case Actions.EXTRACT_6765_DATA_SUCCESS: {
      return {
        ...state,
        form6765Data: [...state.form6765Data, action.payload],
      };
    }
    case Actions.EXTRACT_6765_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPLOAD_6765_DATA_SUCCESS: {
      return {
        ...state,
        s3UploadURL: [...state.s3UploadURL, action.payload],
      };
    }
    case Actions.UPLOAD_6765_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPLOAD_LOADING: {
      return {
        ...state,
        uploadLoading: action.payload,
      };
    }
    case Actions.EXTRACT_LOADING: {
      return {
        ...state,
        form6765Loading: action.payload,
      };
    }
    case Actions.TEMPLATE_LOADING: {
      return {
        ...state,
        templateDownloading: action.payload,
      };
    }
    case Actions.DOCUMENT_LIST_LOADING: {
      return {
        ...state,
        docListDownloading: action.payload,
      };
    }
    case Actions.SELECT_CHANGE_6765: {
      return {
        ...state,
      };
    }
    case Actions.TEMPLATE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.TEMPLATE_DOWNLOAD_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPLOAD_QRE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        qreTemplateUploadURL: [...state.qreTemplateUploadURL, action.payload],
      };
    }
    case Actions.UPLOAD_QRE_TEMPLATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.PARSE_QRE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        qreData: [...state.qreData, action.payload],
      };
    }
    case Actions.PARSE_QRE_TEMPLATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.DOCUMENT_LIST_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        documentList: action.payload,
      };
    }
    case Actions.DOCUMENT_LIST_DOWNLOAD_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPLOAD_SUPPORTING_DOC_SUCCESS: {
      return {
        ...state,
        supportingDocUploadURL: [
          ...state.supportingDocUploadURL,
          action.payload,
        ],
      };
    }
    case Actions.UPLOAD_SUPPORTING_DOC_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPDATE_QRE_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.UPDATE_QRE_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.ADD_FUNDED_PROJECT: {
      return state.fundedProjects.some(
        (project) =>
          project["Project ID Number"] === action.payload["Project ID Number"]
      )
        ? state
        : {
            ...state,
            fundedProjects: [...state.fundedProjects, action.payload],
          };
    }
    case Actions.ADD_IUS_PROJECT: {
      return state.iusProjects.some(
        (project) =>
          project["Project ID Number"] === action.payload["Project ID Number"]
      )
        ? state
        : {
            ...state,
            iusProjects: [...state.iusProjects, action.payload],
          };
    }
    case Actions.ADD_Q_PROJECT: {
      return state.qProjects.some(
        (project) =>
          project["Project ID Number"] === action.payload["Project ID Number"]
      )
        ? state
        : {
            ...state,
            qProjects: [...state.qProjects, action.payload],
          };
    }
    case Actions.ADD_NQ_PROJECT: {
      return state.nqProjects.some(
        (project) =>
          project["Project ID Number"] === action.payload["Project ID Number"]
      )
        ? state
        : {
            ...state,
            nqProjects: [...state.nqProjects, action.payload],
          };
    }
    case Actions.REMOVE_FUNDED_PROJECT: {
      return {
        ...state,
        fundedProjects: [
          ...state.fundedProjects.filter(
            (project) => project["Project ID Number"] !== action.payload
          ),
        ],
      };
    }
    case Actions.REMOVE_IUS_PROJECT: {
      return {
        ...state,
        iusProjects: [
          ...state.iusProjects.filter(
            (project) => project["Project ID Number"] !== action.payload
          ),
        ],
      };
    }
    case Actions.REMOVE_Q_PROJECT: {
      return {
        ...state,
        qProjects: [
          ...state.qProjects.filter(
            (project) => project["Project ID Number"] !== action.payload
          ),
        ],
      };
    }
    case Actions.REMOVE_NQ_PROJECT: {
      return {
        ...state,
        nqProjects: [
          ...state.nqProjects.filter(
            (project) => project["Project ID Number"] !== action.payload
          ),
        ],
      };
    }
    case Actions.EDIT_FUNDED_PROJECT: {
      let key = action.payload.key;
      return {
        ...state,
        fundedProjects: [
          ...state.fundedProjects.slice(0, action.payload.index),
          {
            ...state.fundedProjects[action.payload.index],
            [key]: action.payload.value,
          },
          ...state.fundedProjects.slice(action.payload.index + 1),
        ],
      };
    }
    case Actions.EDIT_IUS_PROJECT: {
      let key = action.payload.key;
      return {
        ...state,
        iusProjects: [
          ...state.iusProjects.slice(0, action.payload.index),
          {
            ...state.iusProjects[action.payload.index],
            [key]: action.payload.value,
          },
          ...state.iusProjects.slice(action.payload.index + 1),
        ],
      };
    }
    case Actions.EDIT_Q_PROJECT: {
      let key = action.payload.key;
      return {
        ...state,
        qProjects: [
          ...state.qProjects.slice(0, action.payload.index),
          {
            ...state.qProjects[action.payload.index],
            [key]: action.payload.value,
          },
          ...state.qProjects.slice(action.payload.index + 1),
        ],
      };
    }
    case Actions.EDIT_NQ_PROJECT: {
      let key = action.payload.key;
      return {
        ...state,
        nqProjects: [
          ...state.nqProjects.slice(0, action.payload.index),
          {
            ...state.nqProjects[action.payload.index],
            [key]: action.payload.value,
          },
          ...state.nqProjects.slice(action.payload.index + 1),
        ],
      };
    }
    case Actions.RUN_ML_MODEL_SUCCESS: {
      return {
        ...state,
        mlModelPredictions: action.payload,
      };
    }
    case Actions.RUN_ML_MODEL_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.SET_FLAGS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.SET_FLAGS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.RECOMPUTE_CREDIT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.RECOMPUTE_CREDIT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.GENERATE_DELIVERABLES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.GENERATE_DELIVERABLES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.GENERATE_REVIEW_REPORTS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.GENERATE_REVIEW_REPORTS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.DELIVERABLE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.DELIVERABLE_DOWNLOAD_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.ROLL_OVER_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.ROLL_OVER_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.CHECK_EMPLOYEE_LOCATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.CHECK_EMPLOYEE_LOCATION_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.SET_ENGAGEMENT_NOTES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.SET_ENGAGEMENT_NOTES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.GET_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.GET_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPDATE_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.UPDATE_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPLOAD_ENGAGEMENT_IMAGE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.UPLOAD_ENGAGEMENT_IMAGE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.DOCUMENT_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.DOCUMENT_UPDATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.EXTRACT_SUPPORTING_DOCUMENT_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.EXTRACT_SUPPORTING_DOCUMENT_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.UPDATE_SUPPORTING_DOC_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.UPDATE_SUPPORTING_DOC_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.CALCULATE_ERC_AVGERAGE_EMPLOYEES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.CALCULATE_ERC_AVGERAGE_EMPLOYEES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.COMPUTE_GR_DECLINE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.COMPUTE_GR_DECLINE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.CHECK_ERC_EMPLOYEE_LOCATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.CHECK_ERC_EMPLOYEE_LOCATION_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.CHECK_ERC_EMPLOYEE_HCP_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.CHECK_ERC_EMPLOYEE_HCP_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.COMPUTE_ERC_CREDIT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Actions.COMPUTE_ERC_CREDIT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.COST_SEG_TOKEN_SUCCCESS: {
      return {
        ...state,
      };
    }
    case Actions.COST_SEG_TOKEN_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default engagement;
