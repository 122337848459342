import React, { Component } from "react";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "../AppContext";

class SprxAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute(this.props);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, user } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];

    const accessGranted =
      matched && matched.route.auth && matched.route.auth.length > 0
        ? matched.route.auth.includes(user.role)
        : true;

    return {
      accessGranted,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  redirectRoute(props) {
    const { location, user, history } = props;
    const { pathname, state } = location;
    /*
        User is guest
        Redirect to Login Page
        */
    if (user.role === "guest") {
      history.push({
        pathname: "/login",
        state: { redirectUrl: pathname },
      });
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
      const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/";

      history.push({
        pathname: redirectUrl,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { accessGranted } = this.state;

    return accessGranted ? <React.Fragment>{children}</React.Fragment> : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

SprxAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(SprxAuthorization));
