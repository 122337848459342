import React from "react";
import classNames from "classnames";

const BasicDateInput = (props) => {
  return (
    <div>
      <label htmlFor={props.id} className="sr-only">
        {props.label && props.label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <input
          id={props.id}
          className={classNames(
            "form-input block w-full sm:text-sm sm:leading-5",
            props.className && props.className
          )}
          type={"text"}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          placeholder={props.placeholder && props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export default BasicDateInput;
