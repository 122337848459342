import React from "react";
import classNames from "classnames";
import { Icon } from "./";

const JobsSteps = (props) => {
  return (
    <div className="bg-white w-full h-32 overflow-hidden rounded-lg elevation-2 ">
      <div className="flex px-4 py-5 sm:p-6">
        <div className="w-1/5">
          <div className="relative mb-2">
            <div
              className={classNames(
                "w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center",
                props.stepOne.complete === true
                  ? "bg-sprxGreen"
                  : "bg-white border-2 border-gray-200"
              )}
            >
              <Icon
                icon={"face"}
                className={classNames(
                  "text-center text-white w-full",
                  props.stepOne.complete === true
                    ? "text-white"
                    : "text-gray-600"
                )}
              />
            </div>
          </div>

          <div className="text-xs text-center md:text-base">
            Employee Upload
          </div>
        </div>

        <div className="w-1/5">
          <div className="relative mb-2">
            <div
              className="absolute flex align-center items-center align-middle content-center"
              style={{
                width: "calc(100% - 2.5rem - 1rem)",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-sprxBlue py-1 rounded"
                  style={{ width: props.stepOne.percentage }}
                ></div>
              </div>
            </div>

            <div
              className={classNames(
                "w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center",
                props.stepTwo.complete === true
                  ? "bg-sprxGreen"
                  : "bg-white border-2 border-gray-200"
              )}
            >
              <Icon
                icon={"place"}
                className={classNames(
                  "text-center w-full",
                  props.stepTwo.complete === true
                    ? "text-white"
                    : "text-gray-600"
                )}
              />
            </div>
          </div>

          <div className="text-xs text-center md:text-base">
            Location Upload
          </div>
        </div>

        <div className="w-1/5">
          <div className="relative mb-2">
            <div
              className="absolute flex align-center items-center align-middle content-center"
              style={{
                width: "calc(100% - 2.5rem - 1rem)",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-sprxBlue py-1 rounded"
                  style={{ width: props.stepTwo.percentage }}
                ></div>
              </div>
            </div>

            <div
              className={classNames(
                "w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center",
                props.stepThree.complete === true
                  ? "bg-sprxGreen"
                  : "bg-white border-2 border-gray-200"
              )}
            >
              <Icon
                icon={"attach_money"}
                className={classNames(
                  "text-center w-full",
                  props.stepThree.complete === true
                    ? "text-white"
                    : "text-gray-600"
                )}
              />
            </div>
          </div>

          <div className="text-xs text-center md:text-base">Compute</div>
        </div>

        <div className="w-1/5">
          <div className="relative mb-2">
            <div
              className="absolute flex align-center items-center align-middle content-center"
              style={{
                width: "calc(100% - 2.5rem - 1rem)",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-sprxBlue py-1 rounded"
                  style={{ width: props.stepThree.percentage }}
                ></div>
              </div>
            </div>

            <div
              className={classNames(
                "w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center",
                props.stepFour.complete === true
                  ? "bg-sprxGreen"
                  : "bg-white border-2 border-gray-200"
              )}
            >
              <Icon
                icon={"thumb_up"}
                className={classNames(
                  "text-center w-full",
                  props.stepFour.complete === true
                    ? "text-white"
                    : "text-gray-600"
                )}
              />
            </div>
          </div>

          <div className="text-xs text-center md:text-base">Review</div>
        </div>

        <div className="w-1/5">
          <div className="relative mb-2">
            <div
              className="absolute flex align-center items-center align-middle content-center"
              style={{
                width: "calc(100% - 2.5rem - 1rem)",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-sprxBlue py-1 rounded"
                  style={{ width: props.stepFour.percentage }}
                ></div>
              </div>
            </div>

            <div
              className={classNames(
                "w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center",
                props.stepFive.complete === true
                  ? "bg-sprxGreen"
                  : "bg-white border-2 border-gray-200"
              )}
            >
              <Icon
                icon={"description"}
                className={classNames(
                  "text-center w-full",
                  props.stepFive.complete === true
                    ? "text-white"
                    : "text-gray-600"
                )}
              />
            </div>
          </div>

          <div className="text-xs text-center md:text-base">Reports</div>
        </div>
      </div>
    </div>
  );
};

export default JobsSteps;
