import _ from "lodash";
import amplifyService from "../../api/amplifyService";
import * as Actions from "../../store/actions";

export const ENGAGEMENT_LIST_SUCCESS = "ENGAGEMENT_LIST_SUCCESS";
export const ENGAGEMENT_LIST_FAILURE = "ENGAGEMENT_LIST_FAILURE";
export const CLIENTS_LIST_SUCCESS = "CLIENTS_LIST_SUCCESS";
export const CLIENTS_LIST_FAILURE = "CLIENTS_LIST_FAILURE";
export const OPEN_NEW_CLIENT_DIALOG = "OPEN_NEW_CLIENT_DIALOG";
export const CLOSE_NEW_CLIENT_DIALOG = "CLOSE_NEW_CLIENT_DIALOG";
export const TOGGLE_POPUP_TYPE = "TOGGLE_POPUP_TYPE";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_CREATE_FAILURE = "CLIENT_CREATE_FAILURE";
export const ENGAGEMENT_CREATE_SUCCESS = "ENGAGEMENT_CREATE_SUCCESS";
export const ENGAGEMENT_CREATE_FAILURE = "CLIENT_CREATE_FAILURE";
export const REFRESH_INDEX = "REFRESH_INDEX";

export function getEngagementsList(firmID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .listEngagements({ firmID: firmID })
        .then((engagements) => {
          resolve(engagements);
          return dispatch({
            type: ENGAGEMENT_LIST_SUCCESS,
            payload: engagements.params,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);

          return dispatch({
            type: ENGAGEMENT_LIST_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function getClientsList(firmID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      amplifyService
        .listClients({ firmID: firmID })
        .then((clients) => {
          resolve(clients);
          return dispatch({
            type: CLIENTS_LIST_SUCCESS,
            payload: clients.params,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));

          return dispatch({
            type: CLIENTS_LIST_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function toggleClientDiaglog() {
  return (dispatch, getState) => {
    const open = getState().clients.clients.newClientDialogOpen;

    if (open === true) {
      return dispatch({
        type: CLOSE_NEW_CLIENT_DIALOG,
        payload: false,
      });
    } else if (open === false) {
      return dispatch({
        type: OPEN_NEW_CLIENT_DIALOG,
        payload: true,
      });
    }
  };
}

export function togglePopupType(popUpType) {
  return (dispatch, getState) => {
    return dispatch({
      type: TOGGLE_POPUP_TYPE,
      payload: popUpType,
    });
  };
}

export function refreshIndex(refreshing) {
  return (dispatch, getState) => {
    return dispatch({
      type: REFRESH_INDEX,
      payload: refreshing,
    });
  };
}

export function createNewClientAndEngagement(model) {
  return (dispatch, getState) => {
    amplifyService.createClient(model).then((client) => {
      const engagementModel = _.merge({}, model, {
        clientID: client.params.clientID,
      });

      amplifyService.createEngagement(engagementModel).then((engagement) => {
        const engagementDataModel = _.merge({}, model, {
          engagementID: engagement.params.engagementID,
          clientID: client.params.clientID,
        });

        amplifyService
          .createEngagementData(engagementDataModel)
          .then((engagementData) => {
            dispatch(
              Actions.showMessage({
                message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
              })
            );

            dispatch(getEngagementsList(model.firmID));
            dispatch(getClientsList(model.firmID));
            dispatch(togglePopupType("select"));
            dispatch(toggleClientDiaglog());
            // dispatch(refreshIndex(true));

            return dispatch({
              type: CLIENT_CREATE_SUCCESS,
              payload: true,
            });
          })
          .catch((error) => {
            console.log(error);
            dispatch(Actions.showMessage({ message: error.message }));

            return dispatch({
              type: CLIENT_CREATE_FAILURE,
              payload: error.message,
            });
          });
      });
    });
  };
}

export function createNewEngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    amplifyService.createEngagement(model).then((engagement) => {
      const engagementDataModel = _.merge({}, model, {
        engagementID: engagement.params.engagementID,
        clientID: model.clientID,
      });

      amplifyService
        .createEngagementData(engagementDataModel)
        .then((engagementData) => {
          dispatch(
            Actions.showMessage({
              message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
            })
          );

          dispatch(getEngagementsList(model.firmID));
          dispatch(getClientsList(model.firmID));
          dispatch(togglePopupType("select"));
          dispatch(toggleClientDiaglog());
          // dispatch(refreshIndex(true));

          return dispatch({
            type: CLIENT_CREATE_SUCCESS,
            payload: true,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch(Actions.showMessage({ message: error.message }));

          return dispatch({
            type: CLIENT_CREATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function createNewClientAndERCEngagement(model) {
  return (dispatch, getState) => {
    amplifyService.createClient(model).then((client) => {
      const engagementModel = _.merge({}, model, {
        clientID: client.params.clientID,
      });

      amplifyService.createERCEngagement(engagementModel).then((engagement) => {
        const engagementDataModel = _.merge({}, model, {
          engagementID: engagement.params.engagementID,
          clientID: client.params.clientID,
        });

        amplifyService
          .createERCEngagementData(engagementDataModel)
          .then((engagementData) => {
            dispatch(
              Actions.showMessage({
                message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
              })
            );

            dispatch(getEngagementsList(model.firmID));
            dispatch(getClientsList(model.firmID));
            dispatch(togglePopupType("select"));
            dispatch(toggleClientDiaglog());
            // dispatch(refreshIndex(true));

            return dispatch({
              type: CLIENT_CREATE_SUCCESS,
              payload: true,
            });
          })
          .catch((error) => {
            console.log(error);
            dispatch(Actions.showMessage({ message: error.message }));

            return dispatch({
              type: CLIENT_CREATE_FAILURE,
              payload: error.message,
            });
          });
      });
    });
  };
}

export function createNewERCEngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    amplifyService.createERCEngagement(model).then((engagement) => {
      const engagementDataModel = _.merge({}, model, {
        engagementID: engagement.params.engagementID,
        clientID: model.clientID,
      });

      amplifyService
        .createERCEngagementData(engagementDataModel)
        .then((engagementData) => {
          dispatch(
            Actions.showMessage({
              message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
            })
          );

          dispatch(getEngagementsList(model.firmID));
          dispatch(getClientsList(model.firmID));
          dispatch(togglePopupType("select"));
          dispatch(toggleClientDiaglog());
          // dispatch(refreshIndex(true));

          return dispatch({
            type: CLIENT_CREATE_SUCCESS,
            payload: true,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch(Actions.showMessage({ message: error.message }));

          return dispatch({
            type: CLIENT_CREATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function createNewClientAndDXREngagement(model) {
  return (dispatch, getState) => {
    amplifyService.createClient(model).then((client) => {
      const engagementModel = _.merge({}, model, {
        clientID: client.params.clientID,
      });

      amplifyService.createDXREngagement(engagementModel).then((engagement) => {
        const engagementDataModel = _.merge({}, model, {
          engagementID: engagement.params.engagementID,
          clientID: client.params.clientID,
        });

        amplifyService
          .createDXREngagementData(engagementDataModel)
          .then((engagementData) => {
            dispatch(
              Actions.showMessage({
                message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
              })
            );

            dispatch(getEngagementsList(model.firmID));
            dispatch(getClientsList(model.firmID));
            dispatch(togglePopupType("select"));
            dispatch(toggleClientDiaglog());
            // dispatch(refreshIndex(true));

            return dispatch({
              type: CLIENT_CREATE_SUCCESS,
              payload: true,
            });
          })
          .catch((error) => {
            console.log(error);
            dispatch(Actions.showMessage({ message: error.message }));

            return dispatch({
              type: CLIENT_CREATE_FAILURE,
              payload: error.message,
            });
          });
      });
    });
  };
}

export function createNewDXREngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    amplifyService.createDXREngagement(model).then((engagement) => {
      const engagementDataModel = _.merge({}, model, {
        engagementID: engagement.params.engagementID,
        clientID: model.clientID,
      });

      amplifyService
        .createDXREngagementData(engagementDataModel)
        .then((engagementData) => {
          dispatch(
            Actions.showMessage({
              message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
            })
          );

          dispatch(getEngagementsList(model.firmID));
          dispatch(getClientsList(model.firmID));
          dispatch(togglePopupType("select"));
          dispatch(toggleClientDiaglog());
          // dispatch(refreshIndex(true));

          return dispatch({
            type: CLIENT_CREATE_SUCCESS,
            payload: true,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch(Actions.showMessage({ message: error.message }));

          return dispatch({
            type: CLIENT_CREATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function createNewClientAndWOTCEngagement(model) {
  return (dispatch, getState) => {
    //TODO
    console.log("createNewClientAndWOTCEngagement");

    // amplifyService.createClient(model).then((client) => {
    //   const engagementModel = _.merge({}, model, {
    //     clientID: client.params.clientID,
    //   });

    //   amplifyService.createDXREngagement(engagementModel).then((engagement) => {
    //     const engagementDataModel = _.merge({}, model, {
    //       engagementID: engagement.params.engagementID,
    //       clientID: client.params.clientID,
    //     });

    //     amplifyService
    //       .createDXREngagementData(engagementDataModel)
    //       .then((engagementData) => {
    //         dispatch(
    //           Actions.showMessage({
    //             message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
    //           })
    //         );

    //         dispatch(getEngagementsList(model.firmID));
    //         dispatch(getClientsList(model.firmID));
    //         dispatch(togglePopupType("select"));
    //         dispatch(toggleClientDiaglog());
    //         // dispatch(refreshIndex(true));

    //         return dispatch({
    //           type: CLIENT_CREATE_SUCCESS,
    //           payload: true,
    //         });
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         dispatch(Actions.showMessage({ message: error.message }));

    //         return dispatch({
    //           type: CLIENT_CREATE_FAILURE,
    //           payload: error.message,
    //         });
    //       });
    //   });
    // });
  };
}

export function createNewWOTCEngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    //TODO
    console.log("createNewWOTCEngagementWithExistingClient");

    // amplifyService.createDXREngagement(model).then((engagement) => {
    //   const engagementDataModel = _.merge({}, model, {
    //     engagementID: engagement.params.engagementID,
    //     clientID: model.clientID,
    //   });

    //   amplifyService
    //     .createDXREngagementData(engagementDataModel)
    //     .then((engagementData) => {
    //       dispatch(
    //         Actions.showMessage({
    //           message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
    //         })
    //       );

    //       dispatch(getEngagementsList(model.firmID));
    //       dispatch(getClientsList(model.firmID));
    //       dispatch(togglePopupType("select"));
    //       dispatch(toggleClientDiaglog());
    //       // dispatch(refreshIndex(true));

    //       return dispatch({
    //         type: CLIENT_CREATE_SUCCESS,
    //         payload: true,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       dispatch(Actions.showMessage({ message: error.message }));

    //       return dispatch({
    //         type: CLIENT_CREATE_FAILURE,
    //         payload: error.message,
    //       });
    //     });
    // });
  };
}

export function createNewClientAndITCEngagement(model) {
  return (dispatch, getState) => {
    //TODO
    console.log("createNewClientAndITCEngagement");

    // amplifyService.createClient(model).then((client) => {
    //   const engagementModel = _.merge({}, model, {
    //     clientID: client.params.clientID,
    //   });

    //   amplifyService.createDXREngagement(engagementModel).then((engagement) => {
    //     const engagementDataModel = _.merge({}, model, {
    //       engagementID: engagement.params.engagementID,
    //       clientID: client.params.clientID,
    //     });

    //     amplifyService
    //       .createDXREngagementData(engagementDataModel)
    //       .then((engagementData) => {
    //         dispatch(
    //           Actions.showMessage({
    //             message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
    //           })
    //         );

    //         dispatch(getEngagementsList(model.firmID));
    //         dispatch(getClientsList(model.firmID));
    //         dispatch(togglePopupType("select"));
    //         dispatch(toggleClientDiaglog());
    //         // dispatch(refreshIndex(true));

    //         return dispatch({
    //           type: CLIENT_CREATE_SUCCESS,
    //           payload: true,
    //         });
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         dispatch(Actions.showMessage({ message: error.message }));

    //         return dispatch({
    //           type: CLIENT_CREATE_FAILURE,
    //           payload: error.message,
    //         });
    //       });
    //   });
    // });
  };
}

export function createNewITCEngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    //TODO
    console.log("createNewITCEngagementWithExistingClient");

    // amplifyService.createDXREngagement(model).then((engagement) => {
    //   const engagementDataModel = _.merge({}, model, {
    //     engagementID: engagement.params.engagementID,
    //     clientID: model.clientID,
    //   });

    //   amplifyService
    //     .createDXREngagementData(engagementDataModel)
    //     .then((engagementData) => {
    //       dispatch(
    //         Actions.showMessage({
    //           message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
    //         })
    //       );

    //       dispatch(getEngagementsList(model.firmID));
    //       dispatch(getClientsList(model.firmID));
    //       dispatch(togglePopupType("select"));
    //       dispatch(toggleClientDiaglog());
    //       // dispatch(refreshIndex(true));

    //       return dispatch({
    //         type: CLIENT_CREATE_SUCCESS,
    //         payload: true,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       dispatch(Actions.showMessage({ message: error.message }));

    //       return dispatch({
    //         type: CLIENT_CREATE_FAILURE,
    //         payload: error.message,
    //       });
    //     });
    // });
  };
}

export function createNewClientAndJobsEngagement(model) {
  return (dispatch, getState) => {
    amplifyService.createClient(model).then((client) => {
      const engagementModel = _.merge({}, model, {
        clientID: client.params.clientID,
      });

      amplifyService
        .createJobsEngagement(engagementModel)
        .then((engagement) => {
          const engagementDataModel = _.merge({}, model, {
            engagementID: engagement.params.engagementID,
            clientID: client.params.clientID,
          });

          amplifyService
            .createJobsEngagementData(engagementDataModel)
            .then((engagementData) => {
              dispatch(
                Actions.showMessage({
                  message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
                })
              );

              dispatch(getEngagementsList(model.firmID));
              dispatch(getClientsList(model.firmID));
              dispatch(togglePopupType("select"));
              dispatch(toggleClientDiaglog());
              // dispatch(refreshIndex(true));

              return dispatch({
                type: CLIENT_CREATE_SUCCESS,
                payload: true,
              });
            })
            .catch((error) => {
              console.log(error);
              dispatch(Actions.showMessage({ message: error.message }));

              return dispatch({
                type: CLIENT_CREATE_FAILURE,
                payload: error.message,
              });
            });
        });
    });
  };
}

export function createNewJobsEngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    amplifyService.createJobsEngagement(model).then((engagement) => {
      const engagementDataModel = _.merge({}, model, {
        engagementID: engagement.params.engagementID,
        clientID: model.clientID,
      });

      amplifyService
        .createJobsEngagementData(engagementDataModel)
        .then((engagementData) => {
          dispatch(
            Actions.showMessage({
              message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
            })
          );

          dispatch(getEngagementsList(model.firmID));
          dispatch(getClientsList(model.firmID));
          dispatch(togglePopupType("select"));
          dispatch(toggleClientDiaglog());
          // dispatch(refreshIndex(true));

          return dispatch({
            type: CLIENT_CREATE_SUCCESS,
            payload: true,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch(Actions.showMessage({ message: error.message }));

          return dispatch({
            type: CLIENT_CREATE_FAILURE,
            payload: error.message,
          });
        });
    });
  };
}

export function createNewClientAndCSEngagement(model) {
  return (dispatch, getState) => {
    //TODO
    console.log("createNewClientAndCSEngagement");

    amplifyService.createClient(model).then((client) => {
       const engagementModel = _.merge({}, model, {
         clientID: client.params.clientID,
       });

       amplifyService.createCostSegEngagement(engagementModel).then((engagement) => {
         const engagementDataModel = _.merge({}, model, {
           engagementID: engagement.params.engagementID,
           clientID: client.params.clientID,
         });

         amplifyService
           .createCostSegEngagementData(engagementDataModel)
           .then((engagementData) => {
             dispatch(
               Actions.showMessage({
                 message: `Created New Engagement: ${engagementData.params.engagementName}. \
                          Please refresh the page to display the new engagement.`,
               })
             );

             dispatch(getEngagementsList(model.firmID));
             dispatch(getClientsList(model.firmID));
             dispatch(togglePopupType("select"));
             dispatch(toggleClientDiaglog());
             // dispatch(refreshIndex(true));

             return dispatch({
               type: CLIENT_CREATE_SUCCESS,
               payload: true,
             });
           })
           .catch((error) => {
             console.log(error);
             dispatch(Actions.showMessage({ message: error.message }));

             return dispatch({
               type: CLIENT_CREATE_FAILURE,
               payload: error.message,
             });
           });
       });
     });
  };
}

export function createNewCSEngagementWithExistingClient(model) {
  return (dispatch, getState) => {
    //TODO
    console.log("createNewCSEngagementWithExistingClient");

    amplifyService.createCostSegEngagement(model).then((engagement) => {
       const engagementDataModel = _.merge({}, model, {
         engagementID: engagement.params.engagementID,
         clientID: model.clientID,
       });

       amplifyService
         .createCostSegEngagementData(engagementDataModel)
         .then((engagementData) => {
           dispatch(
             Actions.showMessage({
               message: `Created New Engagement: ${engagementData.params.engagementName}. Please refresh the page to display the new engagement.`,
             })
           );

           dispatch(getEngagementsList(model.firmID));
           dispatch(getClientsList(model.firmID));
           dispatch(togglePopupType("select"));
           dispatch(toggleClientDiaglog());
           // dispatch(refreshIndex(true));

           return dispatch({
             type: CLIENT_CREATE_SUCCESS,
             payload: true,
           });
         })
         .catch((error) => {
           console.log(error);
           dispatch(Actions.showMessage({ message: error.message }));

           return dispatch({
             type: CLIENT_CREATE_FAILURE,
             payload: error.message,
           });
         });
     });
  };
}
