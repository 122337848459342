import React, { Component } from "react";
import { connect } from "react-redux";
import * as authActions from "../../../auth/store/actions";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Transition from "./Transition";
import { Icon } from "../../../ui-components";

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userMenu: null,
    };
  }

  userMenuClick = (event) => {
    this.setState({ userMenu: event.currentTarget });
  };

  userMenuClose = () => {
    this.setState({ userMenu: null });
  };

  render() {
    const { user } = this.props;
    const { userMenu } = this.state;

    return (
      <React.Fragment>
        <div className="relative inline-block text-left h-16 pt-2 pr-2">
          <div>
            <span className="">
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 pb-2 pt-1 bg-transparent text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
                onClick={
                  userMenu === null ? this.userMenuClick : this.userMenuClose
                }
              >
                <div className="flex items-center">
                  <div>
                    {/* <img
                      className="inline-block h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    /> */}
                  </div>
                  <div className="ml-3 mr-3">
                    <p className="text-sm text-left leading-5 font-medium text-black group-hover:text-gray-900">
                      {user.data.displayName}
                    </p>
                    <p className="text-xs text-left leading-4 font-medium text-black text-opacity-50 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150">
                      {user.role}
                    </p>
                  </div>
                  <div>
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </span>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={userMenu === null ? false : true}
            onExited={this.userMenuClose}
          >
            <div className="origin-top-right z-40 absolute right-0 mt-2 mx-2 w-56 rounded-md shadow-lg">
              <div className="rounded-md bg-white shadow-xs">
                <div className="py-1">
                  <Link
                    className="flex w-full items-center text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/home"
                    onClick={this.userMenuClose}
                  >
                    <Icon className="mr-1" icon={"home"} />
                    Home
                  </Link>
                  <Link
                    className="flex w-full items-center text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to="/help"
                    target="_blank"
                    onClick={this.userMenuClose}
                  >
                    <Icon className="mr-1" icon={"live_help"} />
                    Help
                  </Link>
                  <button
                    type="submit"
                    className="flex w-full items-center text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    onClick={() => {
                      this.userMenuClose();
                      this.props.logout();
                    }}
                  >
                    <Icon className="mr-1" icon={"exit_to_app"} />
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logoutUser,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
