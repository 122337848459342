import React from "react";
import classNames from "classnames";

const BasicSelectInput = (props) => {
  return (
    <div>
      <label
        htmlFor={props.id}
        className={classNames(props.visible ? "" : "sr-only")}
      >
        {props.label && props.label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <select
          id={props.id}
          className={classNames(
            "form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
            props.className && props.className
          )}
          value={
            props.value === "" || props.value === "N/A"
              ? `-- ${props.label} --`
              : props.value
          }
          onChange={props.onChange}
        >
          <option disabled value={`-- ${props.label} --`}>
            -- {props.label} --
          </option>

          {props.options.map((option) => {
            return (
              <option
                value={option.value ? option.value : option.name}
                key={option.key}
                className={classNames(props.className && props.className)}
              >
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default BasicSelectInput;
