import React from "react";
import { SprxAuthorization, SprxLayout } from "./base-components";
import Provider from "react-redux/es/components/Provider";
import { Router } from "react-router-dom";
import history from "../history";
import { Auth } from "./auth";
import store from "./store";
import AppContext from "./AppContext";
import routes from "./navigation/routesConfig";

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <Auth>
          <Router history={history}>
            <SprxAuthorization>
              <SprxLayout />
            </SprxAuthorization>
          </Router>
        </Auth>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
