import React, { useState } from "react";
import ReactLoading from "react-loading";
import { Button } from "./";

const ReviewTiles = (props) => {
  const [expandedTiles, setExpandedTiles] = useState(false);

  return (
    <div className="mb-4 grid grid-cols-1 gap-3 sm:grid-cols-5">
      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          {props.fedRecompute ? (
            <dl>
              <dd className="mt-1">
                <Button
                  primary
                  // buttonClassName=""
                  onClick={props.fedRecomputeAction}
                >
                  {props.loading ? (
                    <div className="flex justify-center items-center h-full w-full">
                      <ReactLoading
                        type={"spin"}
                        color={"#039be5"}
                        height={"50%"}
                        width={"50%"}
                      />
                    </div>
                  ) : (
                    "Recompute Credit"
                  )}
                </Button>
              </dd>
            </dl>
          ) : (
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Federal Credit
              </dt>
              <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                {"$" +
                  props.fedCredit
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </dl>
          )}
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          {props.stateRecompute ? (
            <dl>
              <dd className="mt-1">
                <Button primary onClick={props.stateRecomputeAction}>
                  {props.loading ? (
                    <div className="flex justify-center items-center h-full w-full">
                      <ReactLoading
                        type={"spin"}
                        color={"#039be5"}
                        height={"50%"}
                        width={"50%"}
                      />
                    </div>
                  ) : (
                    "Recompute Credit"
                  )}
                </Button>
              </dd>
            </dl>
          ) : (
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                State Credits
              </dt>
              <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                {"$" +
                  props.stateCredit
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </dl>
          )}
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Wage QRE
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {"$" +
                props.wageQRE
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Supply QRE
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {"$" +
                props.suppyQRE
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </dd>
          </dl>
        </div>
      </div>

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg flex-1 flex items-center justify-between">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              CR QRE @ 65%
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {"$" +
                props.crQRE.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </dd>
          </dl>
        </div>
        <div className="flex-shrink-0 pr-2">
          <button
            className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setExpandedTiles(!expandedTiles);
            }}
          >
            <span className="sr-only">Open options</span>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
        </div>
      </div>

      {expandedTiles ? (
        <div>
          <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
            <div className="px-4 py-5 sm:p-4">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  Computer Rental QRE
                </dt>
                <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                  {"$" +
                    props.compLeaseQRE
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReviewTiles;
