import React from "react";

const BasicRadioGroup = (props) => {
  return (
    <div className="max-w-lg">
      <div className="mt-4">
        {props.choices.map((item) => {
          return (
            <div className="mt-4 flex items-center" key={Math.random()}>
              <input
                id={`${item}`}
                name={`form-input`}
                type="radio"
                value={item}
                checked={props.value === item}
                onChange={props.onChange}
                className="form-radio h-4 w-4 text-sprxBlue transition duration-150 ease-in-out"
              />
              <label htmlFor={`${item}`} className="ml-3">
                <span className="block text-sm leading-5 font-medium text-sprxGrey">
                  {item}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BasicRadioGroup;
