import React, { useState } from "react";
import {
  Button,
  BasicTextInput,
  BasicSelectInput,
  BasicDateInput,
  AppLoading,
} from "./";
import Transition from "../base-components/layout/components/Transition";

const AddClientModal = (props) => {
  const [clientName, setClientName] = useState("");
  const [engagementName, setEngagementName] = useState("");
  const [engagementType, setEngagementType] = useState("");
  const [taxYearStart, setTaxYearStart] = useState("");
  const [taxYearEnd, setTaxYearEnd] = useState("");
  const [engagementFees, setEngagementFees] = useState("");

  const [clientID, setClientID] = useState("");

  const clientArray = props.clients;
  var modClientArry = [];

  clientArray.forEach((client) => {
    var obj = { name: client.clientName, key: client.clientID };
    modClientArry.push(obj);
  });

  return (
    <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={props.show}
          onExited={props.hide}
        >
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={props.hide}
            ></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          show={props.show}
          onExited={props.hide}
        >
          <div
            // className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {(() => {
                    switch (props.type) {
                      case "select":
                        return "Select whether the engagement is for a new client or an existing client";
                      case "loading":
                        return "Creating Engagement...";
                      case "new":
                        return "New engagement for a new client";
                      case "existing":
                        return "New engagement for an existing client";
                      default:
                        return null;
                    }
                  })()}
                </h3>
                <div className="mt-2">
                  {(() => {
                    switch (props.type) {
                      case "select":
                        return null;
                      case "loading":
                        return <AppLoading />;
                      case "new":
                        return (
                          <div>
                            <BasicTextInput
                              label="Client Name"
                              showLabel={true}
                              id={"clientName"}
                              type={"text"}
                              placeholder={"Client Name"}
                              value={clientName}
                              className={"mb-2"}
                              onChange={(event) =>
                                setClientName(event.target.value)
                              }
                            />
                            <BasicTextInput
                              label="Engagement Name"
                              showLabel={true}
                              id={"engagementName"}
                              type={"text"}
                              placeholder={"Engagement Name"}
                              value={engagementName}
                              className={"mb-2"}
                              onChange={(event) =>
                                setEngagementName(event.target.value)
                              }
                            />
                            <BasicSelectInput
                              label="Engagement Type"
                              id={"engagementType"}
                              placeholder={"Engagement Type"}
                              value={engagementType}
                              className={"mb-2"}
                              options={[
                                { name: "Single Year", key: 1 },
                                { name: "Multi-Year", key: 2 },
                              ]}
                              onChange={(event) =>
                                setEngagementType(event.target.value)
                              }
                            />
                            <BasicDateInput
                              label="Tax Year Start"
                              id={"taxYearStart"}
                              placeholder={"Tax Year Start"}
                              value={taxYearStart}
                              className={"mb-2"}
                              onChange={(event) =>
                                setTaxYearStart(event.target.value)
                              }
                            />
                            <BasicDateInput
                              label="Tax Year End"
                              id={"taxYearEnd"}
                              placeholder={"Tax Year End"}
                              value={taxYearEnd}
                              className={"mb-2"}
                              onChange={(event) =>
                                setTaxYearEnd(event.target.value)
                              }
                            />
                            <BasicTextInput
                              label="Engagement Fees"
                              showLabel={true}
                              id={"engagementFees"}
                              type={"number"}
                              placeholder={"Engagement Fees"}
                              value={engagementFees}
                              className={"mb-2"}
                              onChange={(event) =>
                                setEngagementFees(event.target.value)
                              }
                            />
                          </div>
                        );
                      case "existing":
                        return (
                          <div>
                            <BasicSelectInput
                              label="Client Name"
                              id={"clientName"}
                              placeholder={"Client Name"}
                              value={clientName}
                              className={"mb-2"}
                              options={modClientArry}
                              onChange={(event) => {
                                setClientName(event.target.value);

                                setClientID(
                                  modClientArry.find(
                                    (x) => x.name === event.target.value
                                  ).key
                                );
                              }}
                            />
                            <BasicTextInput
                              label="Engagement Name"
                              showLabel={true}
                              id={"engagementName"}
                              type={"text"}
                              placeholder={"Engagement Name"}
                              value={engagementName}
                              className={"mb-2"}
                              onChange={(event) =>
                                setEngagementName(event.target.value)
                              }
                            />
                            <BasicSelectInput
                              label="Engagement Type"
                              id={"engagementType"}
                              placeholder={"Engagement Type"}
                              value={engagementType}
                              className={"mb-2"}
                              options={[
                                { name: "Single Year", key: 1 },
                                { name: "Multi-Year", key: 2 },
                              ]}
                              onChange={(event) =>
                                setEngagementType(event.target.value)
                              }
                            />
                            <BasicDateInput
                              label="Tax Year Start"
                              id={"taxYearStart"}
                              placeholder={"Tax Year Start"}
                              value={taxYearStart}
                              className={"mb-2"}
                              onChange={(event) =>
                                setTaxYearStart(event.target.value)
                              }
                            />
                            <BasicDateInput
                              label="Tax Year End"
                              id={"taxYearEnd"}
                              placeholder={"Tax Year End"}
                              value={taxYearEnd}
                              className={"mb-2"}
                              onChange={(event) =>
                                setTaxYearEnd(event.target.value)
                              }
                            />
                            <BasicTextInput
                              label="Engagement Fees"
                              showLabel={true}
                              id={"engagementFees"}
                              type={"number"}
                              placeholder={"Engagement Fees"}
                              value={engagementFees}
                              className={"mb-2"}
                              onChange={(event) =>
                                setEngagementFees(event.target.value)
                              }
                            />
                          </div>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              </div>
            </div>
            {(() => {
              switch (props.type) {
                case "select":
                  return (
                    <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <Button
                          secondary
                          onClick={() => {
                            props.toggle("new");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          New Client
                        </Button>
                      </span>
                      <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                        <Button
                          primary
                          onClick={() => {
                            props.toggle("existing");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          Existing Client
                        </Button>
                      </span>
                    </div>
                  );
                case "loading":
                  return null;
                case "new":
                  return (
                    <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <Button
                          secondary
                          onClick={() => {
                            props.toggle("select");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          Back
                        </Button>
                      </span>
                      <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                        <Button
                          primary
                          disabled={
                            clientName === "" ||
                            engagementName === "" ||
                            engagementType === "" ||
                            taxYearStart === "" ||
                            taxYearEnd === "" ||
                            engagementFees === ""
                          }
                          buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                          onClick={() => {
                            const model = {
                              clientName: clientName,
                              engagementName: engagementName,
                              engagementType: engagementType,
                              taxYearStart: taxYearStart,
                              taxYearEnd: taxYearEnd,
                              engagementFees: engagementFees,
                            };
                            props.submit(model);
                          }}
                        >
                          Submit
                        </Button>
                      </span>
                    </div>
                  );
                case "existing":
                  return (
                    <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <Button
                          secondary
                          onClick={() => {
                            props.toggle("select");
                          }}
                          buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                        >
                          Back
                        </Button>
                      </span>
                      <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                        <Button
                          primary
                          disabled={
                            clientName === "" ||
                            engagementName === "" ||
                            engagementType === "" ||
                            taxYearStart === "" ||
                            taxYearEnd === "" ||
                            engagementFees === ""
                          }
                          buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                          onClick={() => {
                            const model = {
                              clientName: clientName,
                              engagementName: engagementName,
                              engagementType: engagementType,
                              taxYearStart: taxYearStart,
                              taxYearEnd: taxYearEnd,
                              engagementFees: engagementFees,
                              clientID: clientID,
                            };
                            props.submit(model);
                          }}
                        >
                          Submit
                        </Button>
                      </span>
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default AddClientModal;
