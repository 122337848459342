import React, { useState } from "react";
import { Button, BasicSelectInput } from "./";
import Transition from "../base-components/layout/components/Transition";

const RolloverModal = (props) => {
  const [engagementName] = useState("");
  const [engagementID, setEngagementID] = useState("");

  const engagementArray = props.engagements;
  var modEngArry = [];

  engagementArray.forEach((eng) => {
    var obj = {
      name: eng.engagementName,
      key: eng.engagementID,
      value: eng.engagementID,
    };
    modEngArry.push(obj);
  });

  return (
    <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={props.show}
          onExited={props.hide}
        >
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={props.hide}
            ></div>
          </div>
        </Transition>

        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          show={props.show}
          onExited={props.hide}
        >
          <div
            className="bg-white ml-64 rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Select the Engagement you wish to rollover from.
                </h3>
                <div className="mt-2">
                  <BasicSelectInput
                    label="Engagement Type"
                    id={"engagementType"}
                    placeholder={"Engagement Type"}
                    value={engagementID}
                    name={engagementName}
                    className={"mb-2"}
                    options={modEngArry}
                    onChange={(event) => {
                      setEngagementID(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  secondary
                  onClick={() => {
                    props.hide("select");
                  }}
                  buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                >
                  Back
                </Button>
              </span>
              <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  primary
                  disabled={engagementID === ""}
                  buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                  onClick={() => {
                    props.submit(engagementID);
                  }}
                >
                  Submit
                </Button>
              </span>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default RolloverModal;
