import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { ResponsiveBar } from "@nivo/bar";

const BarGraph = (props) => {
  const [groupMode, setGroupMode] = useState(
    props.groupMode ? props.groupMode : "stacked"
  );
  const [layout, setLayout] = useState(
    props.layout ? props.layout : "vertical"
  );

  return (
    <div
      className={classNames(
        "bg-white overflow-hidden rounded-lg elevation-2",
        props.height && props.height,
        props.width && props.width
      )}
    >
      <h2 className={classNames("font-light text-base text-center my-1")}>
        {props.title}
      </h2>
      <div className={classNames(props.graphHeight && props.graphHeight)}>
        <ResponsiveBar
          data={props.data}
          keys={props.keys}
          indexBy={props.indexBy}
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          colors={[
            "#9E9EAE",
            "#8DC3E4",
            "#E4AF8D",
            "#E4DA8D",
            "#FBFFAD",
            "#7ED4A6",
            "#C5555B",
            "#D6514A",
            "#D6694A",
            "#907590",
            "#D6884A",
            "#7F80A1",
            "#D5B64A",
            "#6D8BB2",
            "#4AA36C",
            "#4A95D5",
            "#C4785C",
            "#C4B65C",
            "#3BA37B",
          ]}
          // colors={(d) => d.color}
          // colors={{ scheme: props.colors ? props.colors : "nivo" }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          groupMode={groupMode ? groupMode : "stacked"}
          layout={layout ? layout : "vertical"}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: props.axisBottomLegend,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: props.axisLeftLegend,
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          onClick={(node, event) => {
            console.log(node);
            console.log(event);
          }}
        />
      </div>
      <div
        className={classNames("flex flex-row flex-shrink justify-between mx-4")}
      >
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            className={classNames(
              "relative h-6 inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150",
              groupMode === "grouped"
                ? "bg-sprxBlue text-white"
                : "bg-white text-gray-700"
            )}
            onClick={() => {
              setGroupMode("grouped");
            }}
          >
            Grouped
          </button>

          <button
            type="button"
            className={classNames(
              "-ml-px relative h-6 inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150",
              groupMode === "stacked"
                ? "bg-sprxBlue text-white"
                : "bg-white text-gray-700"
            )}
            onClick={() => {
              setGroupMode("stacked");
            }}
          >
            Stacked
          </button>
        </span>

        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            className={classNames(
              "relative h-6 inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150",
              layout === "vertical"
                ? "bg-sprxBlue text-white"
                : "bg-white text-gray-700"
            )}
            onClick={() => {
              setLayout("vertical");
            }}
          >
            Vertical
          </button>

          <button
            type="button"
            className={classNames(
              "-ml-px relative h-6 inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150",
              layout === "horizontal"
                ? "bg-sprxBlue text-white"
                : "bg-white text-gray-700"
            )}
            onClick={() => {
              setLayout("horizontal");
            }}
          >
            Horizontal
          </button>
        </span>
      </div>
    </div>
  );
};

export default BarGraph;
