import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";

export const DXRClientListConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/dxr/clientlist",
      component: SprxLoadable({
        loader: () => import("./DXRClientList"),
      }),
    },
  ],
};
