import React from "react";
import classNames from "classnames";

const BottomButtonContainer = (props) => {
  return (
    <div
      className={classNames(
        "flex flex-row w-full p-4 mb-1",
        props.className && props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default BottomButtonContainer;
