import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from "../../../../ui-components";
import * as Actions from "../../../../store/actions";

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

const defaultProps = {};

function NavItem({
  item,
  classes,
  nestedLevel,
  userRole,
  navbarCloseMobile,
  active,
}) {
  if (
    item.auth &&
    (!item.auth.includes(userRole) ||
      (userRole !== "guest" &&
        item.auth.length === 1 &&
        item.auth.includes("guest")))
  ) {
    return null;
  }

  let paddingValue = 8 + nestedLevel * 4;
  const listItemPadding =
    nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-6";

  return (
    <li className="flex">
      <NavLink
        exact={item.exact}
        to={item.url}
        className={classNames(
          "flex items-center h-10 w-navGroup rounded-r-navGroup pr-3 transition hover:bg-white hover:bg-opacity-25 ease-in-out duration-150 text-white",
          listItemPadding,
          "list-item",
          active
        )}
        activeClassName="bg-sprxBlue text-white"
      >
        {item.icon && (
          <Icon
            className="text-white self-center text-lg mr-4 flex-no-shrink"
            icon={item.icon}
          />
        )}
        <p className="text-sd">{item.title}</p>
      </NavLink>
    </li>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}

NavItem.propTypes = propTypes;
NavItem.defaultProps = defaultProps;

const NavVerticalItem = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavItem)
);

export default NavVerticalItem;
