import React from "react";
import FlatfileImporter from "@flatfile/adapter";
import { Button } from "./";
import {
  global,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  rootProgressBar,
  currentProgressBar,
  completeProgressBar,
  incompleteProgressBar,
  headerRoot,
  headerTitle,
  headerCloseButton,
  footerRoot,
  dropzoneRoot,
  dropzoneContent,
  dropzoneFileTypes,
  dropzoneAccepted,
  manualInputRoot,
  manualInputTitle,
  manualInputTable,
  headerMatchRoot,
  headerMatchContent,
  headerMatchIcon,
  headerMatchTable,
  columnMatchRoot,
  columnMatchContent,
  columnMatchRule,
  columnMatchAutoMatchRule,
  dialogRoot,
  dialogContent,
  dialogFooter,
  dialogOverlayColor,
  dataSourceRoot,
  dataSourceTitle,
  dataSourceSubtitle,
  dataSourceStep,
  dataSourceFooter,
  loaderRoot,
  loaderOverlayColor,
  iteratorRoot,
  iteratorOverlayColor,
} from "./FlatFileButtonStyles";

const FlatFileButton = (props) => {
  const importer = new FlatfileImporter(
    "aa6a479e-b7fb-4b73-bbe5-ba42d2bbfc29",
    {
      devMode: process.env.REACT_APP_STAGE === "prod" ? false : true,
      type: props.importType,
      fields: props.fields,
      managed: true,
      title: props.title,
      disableManualInput: props.manualInput ? false : true,
      theme: {
        global: global,
        buttons: {
          primary: primaryButton,
          secondary: secondaryButton,
          tertiary: tertiaryButton,
          success: primaryButton,
          headerMatchYes: {
            ...primaryButton,
            padding: "6px 40px",
          },
          headerMatchNo: {
            ...secondaryButton,
            padding: "6px 40px",
          },
        },
        progressBar: {
          root: rootProgressBar,
          current: currentProgressBar,
          complete: completeProgressBar,
          incomplete: incompleteProgressBar,
        },
        header: {
          root: headerRoot,
          title: headerTitle,
          closeButton: headerCloseButton,
        },
        footer: {
          root: footerRoot,
        },
        dropzone: {
          root: dropzoneRoot,
          content: dropzoneContent,
          fileTypes: dropzoneFileTypes,
          accepted: dropzoneAccepted,
        },
        manualInput: {
          root: manualInputRoot,
          title: manualInputTitle,
          table: manualInputTable,
        },
        headerMatch: {
          root: headerMatchRoot,
          content: headerMatchContent,
          icon: headerMatchIcon,
          table: headerMatchTable,
        },
        columnMatch: {
          root: columnMatchRoot,
          content: columnMatchContent,
          rule: columnMatchRule,
          autoMatchRule: columnMatchAutoMatchRule,
        },
        dialog: {
          root: dialogRoot,
          content: dialogContent,
          footer: dialogFooter,
          // overlayColor: dialogOverlayColor,
        },
        dataSource: {
          root: dataSourceRoot,
          title: dataSourceTitle,
          subtitle: dataSourceSubtitle,
          step: dataSourceStep,
          footer: dataSourceFooter,
        },
        loader: {
          root: loaderRoot,
          // overlayColor: loaderOverlayColor,
        },
        iterator: {
          root: iteratorRoot,
          // overlayColor: iteratorOverlayColor,
        },
      },
      i18nOverrides: {
        en: {
          otherLocales: ["en-US", "en-CA", "en-GB"],
          overrides: {
            manual: "Manually enter data below",
            dropzone: {
              accepted: "{{types}} spreadsheets accepted.",
              button: "Upload data from file",
            },
          },
        },
      },
    }
  );

  importer.setCustomer({
    companyId: props.engagementID,
    companyName: props.engagementName,
    userId: props.userID,
  });

  function launch() {
    importer
      .requestDataFromUser()
      .then((results) => {
        importer.displayLoader();
        setTimeout(() => {
          importer.displaySuccess("Upload Complete!");
          props.onSuccess(results.validData, results);
        }, 1500);
      })
      .catch(function (error) {
        console.info(error || "window close");
      });
  }

  return (
    <Button primary onClick={launch}>
      {props.buttonTitle}
    </Button>
  );
};

export default FlatFileButton;
