import React from "react";
import classNames from "classnames";

const BasicCheckbox = (props) => {
  return (
    <div className="relative flex items-start">
      <div className="absolute flex items-center h-5">
        <input
          id={props.id}
          type="checkbox"
          className={classNames(
            "form-checkbox h-5 w-5 transition duration-150 ease-in-out",
            props.className && props.className
          )}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
      <div
        className={classNames(
          props.prompt ? "pl-7 text-sm leading-5" : "hidden"
        )}
      >
        <label htmlFor="candidates" className="font-medium text-black">
          {props.prompt ? props.prompt : ""}
        </label>
      </div>
    </div>
  );
};

export default BasicCheckbox;
