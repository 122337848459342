import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { ResponsiveChoropleth } from "@nivo/geo";
import useWindowSize from "../base-components/useWindowSize";
import USA from "./USA.js";

const MapGraph = (props) => {
  const size = useWindowSize();
  const scaleModifier = props.scaleModifier;
  const projectionModifier = props.projectionModifier;

  const [data] = useState(props.data);
  const [projectionScale, setProjection] = useState([]);
  const [projectionTranslation, setProjectionTranslation] = useState([]);

  useEffect(() => {
    setProjection((size.width / scaleModifier) * 0.390625);
    setProjectionTranslation((size.width / projectionModifier) * 0.00078125);
  }, [size, scaleModifier, projectionModifier]);

  return (
    <div
      className={classNames(
        "bg-white overflow-hidden rounded-lg elevation-2",
        props.height && props.height,
        props.width && props.width
      )}
    >
      <h2 className={classNames("font-light text-base text-center my-1")}>
        {props.title}
      </h2>
      <div className={classNames(props.graphHeight && props.graphHeight, "")}>
        <ResponsiveChoropleth
          data={data}
          features={USA}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          colors={[
            "#9E9EAE",
            "#8DC3E4",
            "#E4AF8D",
            "#E4DA8D",
            "#FBFFAD",
            "#7ED4A6",
            "#C5555B",
            "#D6514A",
            "#D6694A",
            "#907590",
            "#D6884A",
            "#7F80A1",
            "#D5B64A",
            "#6D8BB2",
            "#4AA36C",
            "#4A95D5",
            "#C4785C",
            "#C4B65C",
            "#3BA37B",
          ]}
          // colors={(d) => d.color}
          // colors={{ scheme: props.colors ? props.colors : "nivo" }}
          domain={[0, 1000000]}
          unknownColor="#666666"
          label="properties.name"
          valueFormat=".2s"
          projectionScale={projectionScale}
          projectionTranslation={[1.4, projectionTranslation]}
          projectionRotation={[0, 0, 0]}
          graticuleLineColor="#dddddd"
          borderWidth={0.5}
          borderColor="#152538"
          enableGraticule={false}
          legends={[
            {
              anchor: "bottom-left",
              direction: "column",
              justify: true,
              translateX: 20,
              translateY: -100,
              itemsSpacing: 0,
              itemWidth: 94,
              itemHeight: 18,
              itemDirection: "left-to-right",
              itemTextColor: "#444444",
              itemOpacity: 0.85,
              symbolSize: 18,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000000",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default MapGraph;
