import React from "react";
import { Redirect } from "react-router-dom";
import routeUtil from "./routeUtil";
import { LoginConfig } from "../main/login/LoginConfig";
// import { RegisterConfig } from "../main/register/RegisterConfig";
import { ForgotPasswordConfig } from "../main/forgot-password/ForgotPasswordConfig";
// import { LogoutConfig } from "../main/logout/LogoutConfig";
import { ClientListConfig } from "../main/client-list/ClientListConfig";
import { AddUserConfig } from "../main/add-user/AddUserConfig";
import { AddFirmConfig } from "../main/add-firm/AddFirmConfig";
import { EngagementConfig } from "../main/engagement/EngagementConfig";
import { TeamConfig } from "../main/team/TeamConfig";
import { PluginsConfig } from "../main/plugins/PluginsConfig";
import { DXRPluginsConfig } from "../main/dxr-plugins/DXRPluginsConfig";
// import { AccountConfig } from "../main/account/AccountConfig";
// import { ClientReviewConfig } from "../main/client-review/ClientReviewConfig";
import { HelpConfig } from "../main/help/HelpConfig";
import { HomeConfig } from "../main/home/HomeConfig";
import { ERCClientListConfig } from "../main/client-list-erc/ERCClientListConfig";
import { ErcEngagementConfig } from "../main/erc-engagement/ErcEngagementConfig";
import { ERCPluginsConfig } from "../main/erc-plugins/ERCPluginsConfig";
import { DXRClientListConfig } from "../main/client-list-dxr/DXRClientListConfig";
import { DxrEngagementConfig } from "../main/dxr-engagement/DxrEngagementConfig";
import { WOTCClientListConfig } from "../main/client-list-wotc/WOTCClientListConfig";
//TODO WOTC Engagement Config
import { ITCClientListConfig } from "../main/client-list-itc/ITCClientListConfig";
//TODO ITC Engagement Config
import { JobsClientListConfig } from "../main/client-list-jobs/JobsClientListConfig";
import { JobsEngagementConfig } from "../main/jobs-engagement/JobsEngagementConfig";
import { CostSegClientListConfig } from "../main/client-list-cs/CostSegClientListConfig";
import { CSPluginsConfig } from "../main/cs-plugins/CSPluginsConfig";
import { CostSegEngagementConfig } from "../main/cs-engagement/CostSegEngagementConfig";
import { authRoles } from "../auth";
import _ from "lodash";

function setUserAuth(configs) {
  return configs.map((config) =>
    _.merge({}, config, { auth: authRoles.staff })
  );
}

function setAdminAuth(configs) {
  return configs.map((config) =>
    _.merge({}, config, { auth: authRoles.admin })
  );
}

function setClientAuth(configs) {
  return configs.map((config) =>
    _.merge({}, config, { auth: authRoles.client })
  );
}

const routeConfigs = [
  ...setClientAuth([ClientListConfig]),
  ...setUserAuth([
    HomeConfig,
    ClientListConfig,
    EngagementConfig,
    ERCClientListConfig,
    ErcEngagementConfig,
    ERCPluginsConfig,
    DXRClientListConfig,
    DxrEngagementConfig,
    WOTCClientListConfig,
    //TODO WOTC Engagement Config
    ITCClientListConfig,
    //TODO ITC Engagement Config
    JobsClientListConfig,
    JobsEngagementConfig,
    CostSegClientListConfig,
    CostSegEngagementConfig,
    //TODO Cost Seg Engagement Config
    TeamConfig,
    PluginsConfig,
    DXRPluginsConfig,
    CSPluginsConfig,
    HelpConfig,
    // AccountConfig,
  ]),
  ...setAdminAuth([AddUserConfig, AddFirmConfig]),
  LoginConfig,
  // LogoutConfig,
  // RegisterConfig,
  ForgotPasswordConfig,
];

const routes = [
  ...routeUtil.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to="/home" />,
  },
];

export default routes;
