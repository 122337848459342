export const global = {
  // backgroundColor: "#212327",
  // textColor: "#454543",
  // primaryTextColor: "#454543",
  // secondaryTextColor: "#454543",
  // successColor: "#c2c3c3",
  // warningColor: "#c2c3c3",
  // fontFamily: "font",
};

export const primaryButton = {
  backgroundColor: "#454553",
  // color: "#4AA0D5",
  border: "none",
  // padding: "6px 16px",
  // fontSize: "15px",
  // borderRadius: "8px",
  ":hover": {
    backgroundColor: "#858599",
    border: "none",
  },
};

export const secondaryButton = {
  backgroundColor: "#4AA0D5",
  color: "white",
  border: "none",
  // padding: "6px 16px",
  // fontSize: "15px",
  // borderRadius: "8px",
  ":hover": {
    backgroundColor: "#60a5fa",
    border: "none",
    color: "white",
  },
};

export const tertiaryButton = {
  // backgroundColor: "#DE781F",
  // color: "#002d3d",
  // border: "1px solid #002d3d",
  // padding: "6px 16px",
  // fontSize: "15px",
  // borderRadius: "8px",
  // ":hover": {
  //   backgroundColor: "transparent",
  //   border: "1px solid #DE781F",
  //   color: "#DE781F",
  // },
};

export const rootProgressBar = {
  // backgroundColor: "grey",
  // borderRadius: "4px",
  // border: "1px solid white",
  // padding: "20px",
};

export const currentProgressBar = {
  // color: "blue",
  // fontSize: "24px",
  // fontWeight: "600",
};

export const completeProgressBar = {
  color: "#3BB273",
};

export const incompleteProgressBar = {
  // color: "yellow",
};

export const headerRoot = {
  // backgroundColor: "#171a1d",
};

export const headerTitle = {
  // color: "white",
};

export const headerCloseButton = {
  // color: "white",
  // ":hover": {
  //   color: "yellow",
  // },
};

export const footerRoot = {
  // backgroundColor: "#171a1d",
};

export const dropzoneRoot = {
  // backgroundColor: "#171a1d",
};

export const dropzoneContent = {
  // backgroundColor: "#171a1d",
  // border: "none",
  // borderRadius: "20px",
  // padding: "40px 60px",
};

export const dropzoneFileTypes = {
  // border: "none",
  // color: "yellow",
};

export const dropzoneAccepted = {
  // color: "yellow",
};

export const manualInputRoot = {
  padding: "16px",
};

export const manualInputTitle = {
  // margin: "0",
  // padding: "16px 30px",
  // backgroundColor: "#4c5057",
  // borderRadius: "20px 20px 0 0",
  // fontSize: "14px",
  // fontWeight: "400",
};

export const manualInputTable = {
  // th: {
  //   backgroundColor: "#2c3135",
  //   color: "#6a7c87",
  //   borderColor: "#2c3135",
  // },
  // td: {
  //   color: "black",
  // },
};

export const headerMatchRoot = {
  // backgroundColor: "#171a1d",
};

export const headerMatchContent = {
  // fontSize: "14px",
};

export const headerMatchIcon = {
  // fill: "yellow",
};

export const headerMatchTable = {
  // th: {
  //   backgroundColor: "#212327",
  //   color: "#6a7c87",
  //   borderColor: "#212327",
  // },
  // td: {
  //   color: "black",
  // },
};

export const columnMatchRoot = {
  // backgroundColor: "orange",
};

export const columnMatchContent = {
  // backgroundColor: "white",
  // color: "blue",
};

export const columnMatchRule = {
  // color: "red",
};

export const columnMatchAutoMatchRule = {
  // root: {
  //   backgroundColor: "grey",
  // },
  // field: {
  //   color: "purple",
  // },
  // description: {
  //   color: "green",
  // },
  // icon: {
  //   fill: "pink",
  // },
};

export const dialogRoot = {
  // backgroundColor: "#2c3135",
  borderRadius: "10px",
  // border: "none",
};

export const dialogContent = {
  // fontSize: "16px",
  // fontWeight: "500",
  // border: "none",
  // padding: "30px 30px 15px",
};

export const dialogFooter = {
  // backgroundColor: "orange",
  // border: "none",
  // padding: "15px 30px 30px",
};

export const dialogOverlayColor = {
  overlayColor: "#BF6b7280",
};

export const dataSourceRoot = {
  // color: "yellow",
};

export const dataSourceTitle = {
  // fontSize: "17px",
  // textAlign: "left",
};

export const dataSourceSubtitle = {
  // fontSize: "15px",
  // textAlign: "left",
  // fontWeight: "400",
};

export const dataSourceStep = {
  // margin: "0",
  // padding: "0 0 0 16px",
};

export const dataSourceFooter = {
  // color: "yellow",
};

export const loaderRoot = {
  // backgroundColor: "#1B3D46",
  color: "#454553",
};

export const loaderOverlayColor = {
  // overlayColor: "rgba(0,0,0,0.5)",
};

export const iteratorRoot = {
  // backgroundColor: "#1B3D46",
  // color: "#c2c3c3",
};

export const iteratorOverlayColor = {
  // overlayColor: "rgba(0,0,0,0.5)",
};
