import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";

export const ERCPluginsConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/erc/plugins/orders",
      component: SprxLoadable({
        loader: () => import("./ERCOrderPlugin"),
      }),
    },
  ],
};
