import React from "react";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import UserNavBarHeader from "./UserNavBarHeader";
import SprxNavigationWrapper from "./SprxNavigationWrapper";
import ScrollBars from "./ScrollBars";
import * as Actions from "../../../store/actions";

const NavigationBar = (props) => {
  var start_pos = props.history.location.pathname.indexOf("/") + 1;
  var end_pos = props.history.location.pathname.indexOf("/", start_pos);
  var toolPath = props.history.location.pathname.substring(start_pos, end_pos);
  var toolImage;

  switch (toolPath) {
    case "rd":
      toolImage = "assets/images/logos/RDWhite@svg.svg";
      break;
    case "erc":
      toolImage = "assets/images/logos/ERCWhite@svg.svg";
      break;
    case "dxr":
      toolImage = "assets/images/logos/DXRWhite@svg.svg";
      break;
    case "wotc":
      toolImage = "assets/images/logos/WOTCWhite@svg.svg";
      break;
    case "itc":
      toolImage = "assets/images/logos/ITCWhite@svg.svg";
      break;
    case "jobs":
      toolImage = "assets/images/logos/JobsWhite@svg.svg";
      break;
    case "cs":
      toolImage = "assets/images/logos/CSWhite@svg.svg";
      break;
    default:
      toolImage = "assets/images/logos/sprxWhite.svg";
      break;
  }

  return (
    <div
      className={
        "flex flex-col flex-auto overflow-hidden h-full bg-sprxDarkNavBar"
      }
    >
      <div className="flex flex-row items-center justify-center flex-shrink h-16 min-h-16 bg-sprxGrey">
        <div className="flex justify-center items-center flex-shrink-0 px-4 mb-4 mt-4 mr-8">
          <img
            style={{ width: 85, height: 56 }}
            src={toolImage}
            alt="SPRX.app"
          />
        </div>

        {/* TOGGLE NAVBAR START */}
        {!props.navbar.folded && (
          <button
            className="-mr-10 inline-flex items-center justify-center rounded-md text-white hover:text-gray-900 focus:outline-none hover:bg-gray-200 transition ease-in-out duration-150"
            aria-label="Open sidebar"
            onClick={props.navbarToggle}
          >
            <svg
              className="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        )}
        {/* TOGGLE NAVBAR END */}
      </div>
      <ScrollBars>
        <UserNavBarHeader />

        <SprxNavigationWrapper layout="vertical" />
      </ScrollBars>
    </div>
  );
};

function mapStateToProps({ sprx }) {
  return {
    navbar: sprx.navbar,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navbarToggle: Actions.navbarToggle,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
);
