import React, { Component } from "react";
import { connect } from "react-redux";
import * as userActions from "../auth/store/actions";
import { bindActionCreators } from "redux";
import * as Actions from "../store/actions";
import amplifyService from "../api";
import { AppLoading } from "../ui-components";

class Auth extends Component {
  /*eslint-disable-next-line no-useless-constructor*/
  constructor(props) {
    super(props);
    // this.state = {
    //   loading: true,
    // };

    /**
     * AWS Amplify check
     */
    this.amplifyCheck();
  }

  amplifyCheck = () => {
    amplifyService.init();

    amplifyService
      .getCurrentSession()
      .then((currentUser) => {
        // this.setState({ loading: false });
        if (currentUser) {
          this.props.showMessage({ message: "Logging in" });

          /**
           * Retrieve user data from AWS
           */
          amplifyService.getCurrentUser().then((user) => {
            amplifyService
              .getUserData({ userID: user.attributes.sub })
              .then((userData) => {
                this.props.setUserDataAWS(userData, currentUser);

                this.props.showMessage({ message: "Logged in" });
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { children } = this.props;
    // if (this.state.loading === true) {
    //   return <AppLoading />;
    // } else {
    //   return <React.Fragment>{children}</React.Fragment>;
    // }
    return <React.Fragment>{children}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserDataAWS: userActions.setUserDataAWS,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
