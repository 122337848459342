import React from "react";
import classNames from "classnames";

const Icon = (props) => {
  return (
    <span className={classNames("material-icons", props.className)}>
      {props.icon}
    </span>
  );
};

export default Icon;
