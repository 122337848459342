import React from "react";
import ReactLoading from "react-loading";
import { Button } from "./";

const CostSegReviewTiles = (props) => {
  return (
    <div className="mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2">
      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          {props.recompute ? (
            <dl>
              <dd className="mt-1">
                <Button primary onClick={props.recomputeAction}>
                  {props.loading ? (
                    <div className="flex justify-center items-center h-full w-full">
                      <ReactLoading
                        type={"spin"}
                        color={"#039be5"}
                        height={"50%"}
                        width={"50%"}
                      />
                    </div>
                  ) : (
                    "Recompute Credit"
                  )}
                </Button>
              </dd>
            </dl>
          ) : (
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Total Reclassified Cost
              </dt>
              <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                {"$" +
                  props.currentCredit
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </dl>
          )}
        </div>
      </div>

      {/* <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Total Potential Available Credit Remaining
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {"$" +
                props.totalAvailableCredit
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </dd>
          </dl>
        </div>
      </div> */}

      <div className="bg-white h-20 overflow-hidden elevation-2 rounded-lg">
        <div className="px-4 py-5 sm:p-4">
          <dl>
            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
              Progress 481a Adjustment
            </dt>
            <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
              {"$" +
                props.potentialCredit
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default CostSegReviewTiles;
