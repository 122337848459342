import React from "react";
import classNames from "classnames";

const Button = (props) => {
  return (
    <button
      className={classNames(
        "text-white font-bold py-2 px-4 min-w-80 elevation-2 text-center focus:outline-none rounded",
        props.primary && !props.disabled && "bg-sprxGrey hover:bg-gray-400",
        props.secondary && !props.disabled && "bg-sprxBlue hover:bg-blue-400",
        props.disabled && "bg-sprxGrey opacity-50 cursor-not-allowed",
        props.buttonClassName && props.buttonClassName
      )}
      onClick={!props.disabled ? props.onClick : undefined}
    >
      <h2
        className={classNames(
          "font-normal text-base text-center",
          props.textClassName && props.textClassName
        )}
      >
        {props.children}
      </h2>
    </button>
  );
};

export default Button;
