import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";

export const HomeConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/home",
      component: SprxLoadable({
        loader: () => import("./Home"),
      }),
    },
  ],
};
