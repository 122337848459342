import React from "react";
import classNames from "classnames";

const ToolCard = (props) => {
  return (
    <div className="bg-white overflow-hidden elevation-2 rounded h-full">
      <button
        className={classNames(
          "h-90/100 w-full focus:outline-none",
          !props.disabled && "hover:bg-gray-200",
          props.disabled && "opacity-50 cursor-not-allowed"
        )}
        onClick={!props.disabled ? props.onClick : undefined}
      >
        <div className="h-80/100">
          <img className="h-full w-full" src={props.image} alt="" />
        </div>
        <div className="flex h-1/5 font-medium items-center justify-center text-center">
          {props.toolName}
        </div>
      </button>
      <button
        className="h-1/10 w-full hover:bg-gray-200 focus:outline-none"
        onClick={props.onClickLearnMore}
      >
        <div className="flex h-full items-center justify-center text-center font-light">
          Learn More
        </div>
      </button>
    </div>
  );
};

export default ToolCard;
