import * as Actions from "../actions";

const initialState = {
  success: false,
  error: {},
  roles: {},
  searchText: "",
};

const admin = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ROLE_SUCCESS: {
      return {
        roles: action.payload,
        success: true,
      };
    }
    case Actions.ROLE_FAILURE: {
      return {
        success: false,
        error: action.payload,
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      };
    }
    default: {
      return state;
    }
  }
};

export default admin;
