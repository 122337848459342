import React from "react";
import ReactLoading from "react-loading";

const AppLoading = (props) => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <ReactLoading
        type={"spin"}
        color={"#039be5"}
        height={"20%"}
        width={"20%"}
      />
    </div>
  );
};

export default AppLoading;
