import React from "react";
import classNames from "classnames";

const DocumentTestCard = (props) => {
  return (
    <div className="bg-gray-200 p-4">
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        4-Part Test Sub-Scores
      </h2>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-shrink-0 flex items-center justify-center w-16 bg-sprxBlue text-white text-sm leading-5 font-medium rounded-l-md">
            PP
          </div>
          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
              <p className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                {props.pp}
              </p>
              <p className="text-gray-500 text-base sm:text-xs md:text-sm">
                Permitted Purpose
              </p>
            </div>
          </div>
        </li>

        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-shrink-0 flex items-center justify-center w-16 bg-sprxOrange text-white text-sm leading-5 font-medium rounded-l-md">
            TU
          </div>
          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
              <p className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                {props.tu}
              </p>
              <p className="text-gray-500">Technical Uncertainty</p>
            </div>
          </div>
        </li>

        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-shrink-0 flex items-center justify-center w-16 bg-sprxYellow text-white text-sm leading-5 font-medium rounded-l-md">
            TN
          </div>
          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
              <p className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                {props.tn}
              </p>
              <p className="text-gray-500">Technical in Nature</p>
            </div>
          </div>
        </li>

        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-shrink-0 flex items-center justify-center w-16 bg-sprxGreen text-white text-sm leading-5 font-medium rounded-l-md">
            POE
          </div>
          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
              <p className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                {props.poe}
              </p>
              <p className="text-gray-500">Process of Experimentation</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DocumentTestCard;
