import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";
import React from "react";
import { Redirect } from "react-router-dom";

export const DxrEngagementConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/dxr/engagement/:engagementId?/dashboard",
      component: SprxLoadable({
        loader: () => import("./DxrEngagementDashboard"),
      }),
    },
    {
      path: "/dxr/engagement/:engagementId?/documentation-upload",
      component: SprxLoadable({
        loader: () => import("./DxrDocumentUpload"),
      }),
    },
    {
      path: "/dxr/engagement/:engagementId?/documentation-review",
      component: SprxLoadable({
        loader: () => import("./DxrDocumentReview"),
      }),
    },
    {
      path: "/dxr/engagement/:engagementId?/finalize-dxr",
      component: SprxLoadable({
        loader: () => import("./FinalizeDXREngagement"),
      }),
    },
    {
      path: "/dxr/engagement/:engagementId?/document-detail-report",
      component: SprxLoadable({
        loader: () => import("./DxrDocumentDetailReport"),
      }),
    },
    {
      path: "/dxr/engagement/:engagementId?/notes",
      component: SprxLoadable({
        loader: () => import("./DxrNotes"),
      }),
    },
    {
      path: "/dxr/engagement/:engagementId?/engagement-settings",
      component: SprxLoadable({
        loader: () => import("./DxrEngagementSettings"),
      }),
    },
    {
      path: "/engagement",
      component: () => <Redirect to="/home" />,
    },
  ],
};
