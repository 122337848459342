import React from "react";
import classNames from "classnames";

const AppTabs = (props) => {
  const width = `w-1/${props.labels.length}`;
  const items = props.labels;

  return (
    <div className="w-full">
      {/* <div className="sm:hidden">
        <select
          id={props.id}
          value={props.value === "" ? `-- ${props.label} --` : props.value}
          onChange={props.onChange}
          aria-label="Selected tab"
          className="form-select block w-full"
        >
          <option value={"Upload a new document"}>Upload a new document</option>
          <option value={"A.I.X Library"}>A.I.X Library</option>
        </select>
      </div> */}

      <div className="sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            {items.map((item, index) => {
              return (
                <button
                  className={classNames(
                    width,
                    "py-4 px-1 text-center border-b-2 font-medium text-sm leading-5 text-gray-500 focus:outline-none",
                    props.tab === index
                      ? "border-sprxBlue"
                      : "border-transparent hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                  )}
                  key={Math.random()}
                  onClick={() => {
                    props.toggle(index);
                  }}
                >
                  {item}
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AppTabs;
