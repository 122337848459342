import React from "react";
import classNames from "classnames";

const SearchBar = (props) => {
  return (
    <div className={classNames("pt-2", props.className && props.className)}>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          id="search"
          className="block w-full h-12 elevation-1 pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 text-black placeholder-gray-400 focus:outline-none focus:bg-white focus:text-gray-900 sm:text-sm transition duration-150 ease-in-out"
          placeholder="Search"
          type="search"
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export default SearchBar;
