import React from "react";
import classNames from "classnames";

const BasicTextAreaInput = (props) => {
  return (
    <div className={classNames(props.parentClassName && props.parentClassName)}>
      <label htmlFor={props.id} className="sr-only">
        {props.label && props.label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <textarea
          rows={props.rows}
          id={props.id}
          className={classNames(
            "form-textarea block transition duration-150 ease-in-out sm:text-sm sm:leading-5",
            props.className && props.className,
            props.width ? `w-${props.width}` : "w-full",
            props.height ? `h-${props.height}` : ""
          )}
          type={props.type}
          placeholder={props.placeholder && props.placeholder}
          value={props.value}
          onChange={props.onChange}
        ></textarea>
      </div>
    </div>
  );
};

export default BasicTextAreaInput;
