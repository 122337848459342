import amplifyService from "../../../api/amplifyService";
import config from "../../../api/amplifyServiceConfig";
import * as UserActions from "./user.actions";
import * as Actions from "../../../store/actions";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_CODE_SUCCESS = "REGISTER_CODE_SUCCESS";
export const REGISTER_CODE_ERROR = "REGISTER_CODE_ERROR";

export function requestRegisterCode(model) {
  const { email, password } = model;
  return (dispatch) => {
    amplifyService
      .registerUserCodeRequest(email, password)
      .then((status) => {
        if (status.codeSent === true) {
          return dispatch({
            type: REGISTER_SUCCESS,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: REGISTER_ERROR,
          payload: error,
        });
      });
  };
}

export function registerWithAWS(model) {
  const { email, firstName, lastName, role, firmName, firmId, code } = model;
  return (dispatch) => {
    amplifyService
      .registerUserSubmit(email, code)
      .then((data) => {
        if (data.registerUser === true) {
          dispatch(
            UserActions.createUserSettingsAWS({
              ...data.user,
              firstName,
              email,
              role,
              firmName,
              firmId,
              fullName: `${firstName} ${lastName}`,
            })
          );

          return dispatch({
            type: REGISTER_SUCCESS,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: REGISTER_ERROR,
          payload: error,
        });
      });
  };
}

export function createNewUserAWS(model) {
  const { email, password, firstName, lastName, role, firmName, firmID, type } =
    model;
  return async function (dispatch, getState) {
    var currentUser = await amplifyService.getCurrentUser();

    const user = {
      createdBy: currentUser.username,
      displayName: firstName,
      email: email,
      ClientId: config.cognito.APP_CLIENT_ID,
      UserPoolId: config.cognito.USER_POOL_ID,
      Username: email,
      password: password,
      firmID: firmID,
      firmName: firmName,
      fullName: `${firstName} ${lastName}`,
      role: role,
      type: type,
      settings: {},
    };

    amplifyService
      .createUser(user)
      .then((user) => {
        console.log(user);
        dispatch(
          Actions.showMessage({
            message: `Created User with ID ${user.params.userID}`,
          })
        );

        return dispatch({
          type: REGISTER_SUCCESS,
        });
      })
      .catch(function (error) {
        dispatch(Actions.showMessage({ message: error }));

        return dispatch({
          type: REGISTER_ERROR,
          payload: error,
        });
      });
  };
}

export function createNewClientUserAWS(model) {
  const {
    email,
    password,
    firstName,
    lastName,
    role,
    firmName,
    firmID,
    engagementID,
    type,
  } = model;
  return async function (dispatch, getState) {
    var currentUser = await amplifyService.getCurrentUser();

    const user = {
      createdBy: currentUser.username,
      displayName: firstName,
      email: email,
      ClientId: config.cognito.APP_CLIENT_ID,
      UserPoolId: config.cognito.USER_POOL_ID,
      Username: email,
      password: password,
      firmID: firmID,
      firmName: firmName,
      fullName: `${firstName} ${lastName}`,
      role: role,
      type: type,
      engagementID: engagementID,
      settings: {},
    };

    amplifyService
      .createClientUser(user)
      .then((user) => {
        console.log(user);
        dispatch(
          Actions.showMessage({
            message: `Created User with ID ${user.params.userID}`,
          })
        );

        return dispatch({
          type: REGISTER_SUCCESS,
        });
      })
      .catch(function (error) {
        dispatch(Actions.showMessage({ message: error }));

        return dispatch({
          type: REGISTER_ERROR,
          payload: error,
        });
      });
  };
}

export function changeClientAccess(engagementID, action, userID, firmID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // var body = {
      //   action: action,
      //   engagementID: engagementID,
      //   userID: userID
      // };

      amplifyService
        .changeClientAccess({
          action: action,
          engagementID: engagementID,
          userID: userID,
          firmID: firmID,
        })
        .then((accesOjb) => {
          resolve(accesOjb);
          return dispatch({
            type: REGISTER_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch(Actions.showMessage({ message: error.message }));
          reject(error.message);
          return dispatch({
            type: REGISTER_ERROR,
            payload: error.message,
          });
        });
    });
  };
}
