import SprxLoadable from "../../base-components/SprxLoadable/SprxLoadable";
import React from "react";
import { Redirect } from "react-router-dom";

export const JobsEngagementConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: "/jobs/engagement/:engagementId?/dashboard",
      component: SprxLoadable({
        loader: () => import("./JobsEngagementDashboard"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/employee-information",
      component: SprxLoadable({
        loader: () => import("./JobsEmployeeInformationUpload"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/location-information",
      component: SprxLoadable({
        loader: () => import("./JobsLocationInformationUpload"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/state-credit-profile",
      component: SprxLoadable({
        loader: () => import("./JobsStateCreditProfile"),
      }),
    },
    // {
    //   path: "/dxr/engagement/:engagementId?/documentation-upload",
    //   component: SprxLoadable({
    //     loader: () => import("./DxrDocumentUpload"),
    //   }),
    // },
    {
      path: "/jobs/engagement/:engagementId?/review",
      component: SprxLoadable({
        loader: () => import("./JobsCreditReview"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/finalize-jobs",
      component: SprxLoadable({
        loader: () => import("./FinalizeJobsEngagement"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/reports",
      component: SprxLoadable({
        loader: () => import("./JobsEngagementReports"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/compute-jobs",
      component: SprxLoadable({
        loader: () => import("./ComputeJobs"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/notes",
      component: SprxLoadable({
        loader: () => import("./JobsNotes"),
      }),
    },
    {
      path: "/jobs/engagement/:engagementId?/engagement-settings",
      component: SprxLoadable({
        loader: () => import("./JobsEngagementSettings"),
      }),
    },
    {
      path: "/engagement",
      component: () => <Redirect to="/home" />,
    },
  ],
};
