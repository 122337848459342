import * as Actions from "../actions";

const initialState = {
  success: false,
  engagementCreateSuccess: false,
  newClientDialogOpen: false,
  popUpType: "select",
  error: {},
  engagements: {},
  clients: {},
  refresh: false
};

const clients = function(state = initialState, action) {
  switch (action.type) {
    case Actions.ENGAGEMENT_LIST_SUCCESS: {
      return {
        ...state,
        engagements: action.payload,
        success: true,
        error: {}
      };
    }
    case Actions.ENGAGEMENT_LIST_FAILURE: {
      return {
        ...state,
        success: false,
        error: action.payload
      };
    }
    case Actions.CLIENTS_LIST_SUCCESS: {
      return {
        ...state,
        clients: action.payload,
        success: true,
        error: {}
      };
    }
    case Actions.CLIENTS_LIST_FAILURE: {
      return {
        ...state,
        success: false,
        error: action.payload
      };
    }
    case Actions.OPEN_NEW_CLIENT_DIALOG: {
      return {
        ...state,
        newClientDialogOpen: action.payload
      };
    }
    case Actions.CLOSE_NEW_CLIENT_DIALOG: {
      return {
        ...state,
        newClientDialogOpen: action.payload
      };
    }
    case Actions.TOGGLE_POPUP_TYPE: {
      return {
        ...state,
        popUpType: action.payload
      };
    }
    case Actions.CLIENT_CREATE_SUCCESS: {
      return {
        ...state,
        engagementCreateSuccess: action.payload
      };
    }
    case Actions.CLIENT_CREATE_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }
    case Actions.ENGAGEMENT_CREATE_SUCCESS: {
      return {
        ...state,
        engagementCreateSuccess: action.payload
      };
    }
    case Actions.ENGAGEMENT_CREATE_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }
    case Actions.REFRESH_INDEX: {
      return {
        ...state,
        refresh: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default clients;
