import React from "react";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import classNames from "classnames";

const UserNavbarHeader = ({ classes, engagementData, user, sprx }) => {
  var start_pos = sprx.navigation[0].url.indexOf("/") + 1;
  var end_pos = sprx.navigation[0].url.indexOf("/", start_pos);
  var toolPath = sprx.navigation[0].url.substring(start_pos, end_pos);
  var toolName;

  switch (toolPath) {
    case "rd":
      toolName = "R&D Credit Tool";
      break;
    case "erc":
      toolName = "Employee Retention Credit Tool";
      break;
    case "dxr":
      toolName = "R&D Document Review Tool";
      break;
    case "wotc":
      toolName = "WOTC Credit Tool";
      break;
    case "itc":
      toolName = "Investment Credit Tool";
      break;
    case "jobs":
      toolName = "Jobs Credit Tool";
      break;
    case "cs":
      toolName = "Cost Segregation Tool";
      break;
    default:
      toolName = user.data.displayName;
      break;
  }

  return (
    <div className="relative flex flex-col items-center justify-center pt-6 mb-8 z-0 bg-sprxGrey">
      <p className="text-base whitespace-no-wrap text-white">
        {_.isEmpty(engagementData.engagementData)
          ? toolName
          : engagementData.engagementData.engagementName}
      </p>
      <p className="mt-2 mb-12 text-sm whitespace-no-wrap text-white text-opacity-50">
        {_.isEmpty(engagementData.engagementData)
          ? user.data.email
          : engagementData.engagementData.clientName}
      </p>
      {/* <div className="relative flex justify-center items-center mt-2 top-40 rounded-full bg-sprxDarkNavBar">
        <img
          className="inline-block h-20 w-20 p-2 rounded-full"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
        src={
           user.data.photoURL && user.data.photoURL !== ""
              ? user.data.photoURL
             : "assets/images/avatars/profile.jpg"
     }
      </div> */}
    </div>
  );
};

function mapStateToProps({ sprx, auth, engagement }) {
  return {
    sprx: sprx,
    user: auth.user,
    engagementData: engagement.engagement,
  };
}

export default connect(mapStateToProps)(UserNavbarHeader);
