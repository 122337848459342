import config from "./amplifyServiceConfig";
import Amplify, { Auth, API } from "aws-amplify";

class amplifyService {
  init() {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        authenticationFlowType: "CUSTOM_AUTH",
        oauth: {
          domain: config.cognito.DOMAIN_URL,
          scope: ["email"],
          redirectSignIn: config.cognito.REDIRECT_SIGNIN,
          redirectSignOut: config.cognito.REDIRECT_SIGNOUT,
          responseType: "token",
        },
      },
      API: {
        endpoints: [
          {
            name: "sprx-api",
            endpoint: config.apiGateway.URL,
            region: config.apiGateway.REGION,
          },
          {
            name: "sprx-api-state-rd",
            endpoint: config.apiGateway.STATERDURL,
            region: config.apiGateway.REGION,
          },
          {
            name: "sprx-api-erc",
            endpoint: config.apiGateway.ERCURL,
            region: config.apiGateway.REGION,
          },
          {
            name: "sprx-api-dxr",
            endpoint: config.apiGateway.DXRURL,
            region: config.apiGateway.REGION,
          },
          {
            name: "sprx-api-jobs",
            endpoint: config.apiGateway.JOBSURL,
            region: config.apiGateway.REGION,
          },
          {
            name: "sprx-api-cost-seg",
            endpoint: config.apiGateway.COSTSEGURL,
            region: config.apiGateway.REGION,
          },
          {
            name: "sprx-api-payroll-connect",
            endpoint: config.apiGateway.PAYROLLCONNECT,
            region: config.apiGateway.REGION,
          },
        ],
      },
    });
  }

  // Auth based functions.

  async signIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      return user;
    } catch (e) {
      throw e;
    }
  }

  async signInIDP() {
    try {
      return window.location.assign(
        `https://${config.cognito.DOMAIN_URL}/login?response_type=token&client_id=${config.cognito.APP_CLIENT_ID}&redirect_uri=${config.cognito.REDIRECT_SIGNIN}`
      );
    } catch (e) {
      throw e;
    }
  }

  async submitChallengeLogin(email, baseURL, name) {
    const body = {
      UserPoolId: config.cognito.USER_POOL_ID,
      email: email,
      baseURL: baseURL,
      name: name,
    };

    try {
      return await API.post("sprx-api", "/loginChallenge", {
        body: body,
      });
    } catch (e) {
      throw e.response.data.error;
    }
  }

  async signInChallenge(username) {
    console.log("🚀 ~ signInChallenge ~ username", username);
    try {
      const user = await Auth.signIn(username);
      return user;
    } catch (e) {
      throw e;
    }
  }

  async validateSignInChallenge(user, code) {
    console.log("🚀 ~ validateSignInChallenge ~ user", user);
    console.log("🚀 ~ validateSignInChallenge ~ code", code);
    try {
      const authedUser = Auth.sendCustomChallengeAnswer(user, code);
      return authedUser;
    } catch (e) {
      throw e;
    }
  }

  async signOut() {
    try {
      const signOut = await Auth.signOut();
      return signOut;
    } catch (e) {
      throw e;
    }
  }

  async getCurrentSession() {
    try {
      const session = await Auth.currentSession();

      return session;
    } catch (e) {
      throw e;
    }
  }

  async getCurrentUser() {
    try {
      const user = await Auth.currentUserInfo();
      return user;
    } catch (e) {
      throw e;
    }
  }

  async getUserData(data) {
    return API.get("sprx-api", `/users/${data.userID}`);
  }

  async registerUserCodeRequest(email, password) {
    try {
      await Auth.signUp({ username: email, password: password });
      return { codeSent: true };
    } catch (e) {
      return { codeSent: false, error: e };
    }
  }

  async registerUserSubmit(email, confirmationCode) {
    try {
      const user = await Auth.confirmSignUp(email, confirmationCode);
      return { registerUser: true, user: user };
    } catch (e) {
      return { registerUser: false, error: e };
    }
  }

  async forgotPasswordCodeRequest(email) {
    try {
      await Auth.forgotPassword(email);
      return { codeSent: true };
    } catch (e) {
      return { codeSent: false, error: e };
    }
  }

  async forgotPasswordSubmit(email, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      return { passwordReset: true };
    } catch (e) {
      return { passwordReset: false, error: e };
    }
  }

  // Create functions.

  async createUser(data) {
    const body = {
      firmID: data.firmID,
      firmName: data.firmName,
      fullName: data.fullName,
      role: data.role,
      UserPoolId: data.UserPoolId,
      ClientId: data.ClientId,
      password: data.password,
      email: data.email,
      createdBy: data.createdBy,
      displayName: data.displayName,
      settings: data.settings,
      type: data.type,
    };

    try {
      return API.post("sprx-api", "/users", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createClientUser(data) {
    const body = {
      firmID: data.firmID,
      firmName: data.firmName,
      fullName: data.fullName,
      role: data.role,
      UserPoolId: data.UserPoolId,
      ClientId: data.ClientId,
      password: data.password,
      email: data.email,
      createdBy: data.createdBy,
      displayName: data.displayName,
      settings: data.settings,
      engagementID: data.engagementID,
      type: data.type,
    };

    try {
      return API.post("sprx-api", "/createClientUser", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async changeClientAccess(data) {
    const body = {
      engagementID: data.engagementID,
      action: data.action,
      firmID: data.firmID,
    };

    try {
      return API.post("sprx-api", `/updateClientAccess/${data.userID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createFirm(data) {
    var body = {
      firmName: data.firmName,
      engagementIDs: "1",
      logoURL: data.logoURL,
    };

    try {
      return API.post("sprx-api", "/firms", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createClient(data) {
    var body = {
      clientName: data.clientName,
      firmID: data.firmID,
    };

    try {
      return API.post("sprx-api", "/clients", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createEngagement(data) {
    var body = {
      firmName: data.firmName,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      taxYearEnd: data.taxYearEnd,
      taxYearStart: data.taxYearStart,
      engagementLogo: data.engagementLogo,
    };

    try {
      return API.post("sprx-api", "/engagements", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createEngagementData(data) {
    var body = {
      engagementID: data.engagementID,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      taxYearEnd: data.taxYearEnd,
      taxYearStart: data.taxYearStart,
    };

    try {
      return API.post("sprx-api", "/engagementData", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createERCEngagement(data) {
    var body = {
      firmName: data.firmName,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      engagementLogo: data.engagementLogo,
    };

    try {
      return API.post("sprx-api-erc", "/engagements", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createERCEngagementData(data) {
    var body = {
      engagementID: data.engagementID,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
    };

    try {
      return API.post("sprx-api-erc", "/engagementData", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createCostSegEngagementData(data) {
    var body = {
      engagementID: data.engagementID,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      location: data.engagementLocationID,
      templateID: data.templateID,
      depreciationSystem: data.depreciationSystem,
    };

    try {
      return API.post("sprx-api-cost-seg", "/engagementData", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createCostSegEngagement(data) {
    var body = {
      firmName: data.firmName,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      engagementLogo: data.engagementLogo,
    };

    try {
      return API.post("sprx-api-cost-seg", "/engagements", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createDXREngagement(data) {
    var body = {
      firmName: data.firmName,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      engagementLogo: data.engagementLogo,
    };

    try {
      return API.post("sprx-api-dxr", "/engagements", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createDXREngagementData(data) {
    var body = {
      engagementID: data.engagementID,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
    };

    try {
      return API.post("sprx-api-dxr", "/engagementData", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createJobsEngagement(data) {
    var body = {
      firmName: data.firmName,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
      engagementLogo: data.engagementLogo,
    };

    try {
      return API.post("sprx-api-jobs", "/engagements", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createJobsEngagementData(data) {
    var body = {
      engagementID: data.engagementID,
      firmID: data.firmID,
      clientID: data.clientID,
      clientName: data.clientName,
      engagementFees: data.engagementFees,
      engagementName: data.engagementName,
      engagementType: data.engagementType,
    };

    try {
      return API.post("sprx-api-jobs", "/engagementData", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async createSupportTicket(data) {
    var body = {
      subject: data.subject,
      content: data.content,
      email: data.email,
      phoneNumber: data.phoneNumber,
      userName: data.userName,
      userID: data.userID,
      firmName: data.firmName,
      firmID: data.firmID,
    };

    try {
      return API.post("sprx-api", "/createSupportTicket", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  // List funcions.

  async listFirmUsers(data) {
    try {
      return API.get("sprx-api", `/listFirmUsers/${data.firmID}`);
    } catch (e) {
      throw e;
    }
  }

  async listEngagements(data) {
    try {
      return API.get("sprx-api", `/listEngagements/${data.firmID}`);
    } catch (e) {
      throw e;
    }
  }

  async listClients(data) {
    try {
      return API.get("sprx-api", `/listClients/${data.firmID}`);
    } catch (e) {
      throw e;
    }
  }

  async listFirms() {
    try {
      return API.get("sprx-api", `/firms`);
    } catch (e) {
      throw e;
    }
  }

  async listEngagementDocuments(data) {
    var body = {
      bucket: data.bucket,
      delimiter: data.delimiter,
      prefix: data.prefix,
    };

    try {
      return API.post("sprx-api", "/listEngagementDocuments", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  // Get funcions.

  async getEngagementData(data) {
    try {
      return API.get("sprx-api", `/engagementData/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getFirmData(data) {
    try {
      return API.get("sprx-api", `/firms/${data.firmID}`);
    } catch (e) {
      throw e;
    }
  }

  async getEngagement(data) {
    try {
      return API.get("sprx-api", `/engagements/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getERCEngagementData(data) {
    try {
      return API.get("sprx-api-erc", `/engagementData/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getERCEngagement(data) {
    try {
      return API.get("sprx-api-erc", `/engagements/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getCostSegEngagementData(data) {
    try {
      return API.get(
        "sprx-api-cost-seg",
        `/engagementData/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async getCostSegEngagement(data) {
    try {
      return API.get("sprx-api-cost-seg", `/engagements/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getDXREngagementData(data) {
    try {
      return API.get("sprx-api-dxr", `/engagementData/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getDXREngagement(data) {
    try {
      return API.get("sprx-api-dxr", `/engagements/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getJobsEngagementData(data) {
    try {
      return API.get("sprx-api-jobs", `/engagementData/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getJobsEngagement(data) {
    try {
      return API.get("sprx-api-jobs", `/engagements/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  // File Manipulation funcions.

  async requestSignedURL(data) {
    var body = {
      key: data.key,
      bucket: data.bucket,
      action: data.action,
      type: data.type,
    };

    try {
      return API.post("sprx-api", "/requestSignedURL", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async extractForm6765(data) {
    var body = {
      bucketName: data.bucketName,
      fileName: data.fileName,
      taxYear: data.taxYear,
      url: data.url,
      toEmail: data.toEmail,
      engagement: data.engagement,
      userName: data.userName,
    };

    console.log(body);

    try {
      return API.put("sprx-api", `/form6765Extract/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async pollForm6765(data) {
    try {
      return API.put("sprx-api", "/form6765Poll", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async extractOCRData(data) {
    var body = {
      bucketName: data.bucketName,
      fileName: data.fileName,
      featureType: data.featureType,
      url: data.url,
      firmID: data.firmID,
    };

    console.log(body);

    try {
      return API.put("sprx-api", `/ocrData/${data.firmID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async pollOCRData(data) {
    try {
      return API.put("sprx-api", "/ocrDataPoll", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async extractOCRSupportingDoc(data) {
    var body = {
      bucketName: data.bucketName,
      fileName: data.fileName,
      url: data.url,
    };

    try {
      return API.put(
        "sprx-api",
        `/ocrSupportingDocument/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async pollOCRSupportingDoc(data) {
    try {
      return API.put("sprx-api", "/ocrSupportingDocumentPoll", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async transcribeSupportingDoc(data) {
    var body = {
      bucketName: data.bucketName,
      fileName: data.fileName,
      url: data.url,
    };

    try {
      return API.put(
        "sprx-api",
        `/transcribeSupportingDocument/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async pollTranscribeSupportingDoc(data) {
    try {
      return API.put("sprx-api", "/transcribeSupportingDocumentPoll", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async ocrMSOSupportingDocument(data) {
    try {
      return API.put("sprx-api", "/ocrMSOSupportingDocument", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async dxrExtractSupportingDocuments(data) {
    var body = {
      engagementID: data.engagementID,
    };

    try {
      return API.post("sprx-api-dxr", `/start-document-extraction`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async extractDXROCRSupportingDoc(data) {
    var body = {
      bucketName: data.bucketName,
      fileName: data.fileName,
      url: data.url,
    };

    try {
      return API.put(
        "sprx-api-dxr",
        `/dxrOCRSupportingDocument/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async pollDXROCRSupportingDoc(data) {
    try {
      return API.put("sprx-api-dxr", "/dxrOCRSupportingDocumentPoll", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async transcribeDXRSupportingDoc(data) {
    var body = {
      bucketName: data.bucketName,
      fileName: data.fileName,
      url: data.url,
    };

    try {
      return API.put(
        "sprx-api-dxr",
        `/dxrTranscribeSupportingDocument/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async pollTranscribeDXRSupportingDoc(data) {
    try {
      return API.put("sprx-api-dxr", "/dxrTranscribeSupportingDocumentPoll", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async dxrOCRMSOSupportingDocument(data) {
    try {
      return API.put("sprx-api-dxr", "/dxrOCRMSOSupportingDocument", {
        body: data,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  // DB Manipulation funcions.

  async updateFirm(data) {
    console.log("🚀 ~ updateFirm ~ data", data);

    var body = data.body;

    console.log("🚀 ~ updateFirm ~ body", body);

    try {
      return API.put("sprx-api", `/updateFirm/${data.firmID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateEngagementData(data) {
    var body = data.body;

    try {
      return API.put("sprx-api", `/engagementData/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateEngagement(data) {
    var body = data.body;

    try {
      return API.put("sprx-api", `/engagements/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async connectRDCreditPayroll(data) {
    var body = data.body;

    try {
      return API.post(
        "sprx-api-payroll-connect",
        `/connectRDCreditPayroll/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async updateERCEngagementData(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-erc", `/engagementData/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateERCEngagement(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-erc", `/engagements/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateDXREngagementData(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-dxr", `/engagementData/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateDXREngagement(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-dxr", `/engagements/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateJobsEngagementData(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-jobs", `/engagementData/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateJobsEngagement(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-jobs", `/engagements/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async updateCostSegEngagementData(data) {
    var body = data.body;

    try {
      return API.put(
        "sprx-api-cost-seg",
        `/engagementData/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async updateCostSegEngagement(data) {
    var body = data.body;

    try {
      return API.put("sprx-api-cost-seg", `/engagements/${data.engagementID}`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  // ML funcions.

  async runMLModel(data) {
    var body = data.body;

    try {
      return API.put("sprx-api", `/runMLModel`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async runMLModelEstimate(data) {
    var body = { employees: data.body };

    try {
      return API.put("sprx-api", `/runMLModelEstimate`, {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  // credit funcions.

  async computeCredit(data) {
    try {
      return API.post("sprx-api", `/computeCredit/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async computeStateCredit(data) {
    try {
      return API.post(
        "sprx-api-state-rd",
        `/computeStateCredits/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async checkEmployeeLocation(data) {
    try {
      return API.post(
        "sprx-api",
        `/checkEmployeeLocation/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async allocateQRE(data) {
    try {
      return API.put("sprx-api", `/allocateQRE/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async calculateERCAvgEmployees(data) {
    var body = {
      average2019NumberEmployeesPerMonth:
        data.average2019NumberEmployeesPerMonth,
    };

    try {
      return API.post("sprx-api-erc", "/computeAverageEmployees", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async computeGRDecline(data) {
    var body = {
      pqGR: data.pqGR,
    };

    try {
      return API.post("sprx-api-erc", "/computeGRDecline2021", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async checkERCEmployeeLocation(engagementID) {
    try {
      return API.post("sprx-api-erc", `/checkEmployeeLocation/${engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async checkERCEmployeeHCP(engagementID) {
    try {
      return API.post("sprx-api-erc", `/checkEmployeeHCP/${engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async computeERCPppAllocation(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeERCPppAllocation/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computeERCReallocation(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeERCReallocation/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computeERCOptimal2020PppAllocation(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeERCOptimal2020PppAllocation/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computeERCOptimal2021PppAllocation(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeERCOptimal2021PppAllocation/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computeERCCredit(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeOptimalERCCredit2021/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computeCredit2020(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeCredit2020/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computeCredit2021(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computeCredit2021/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computePppAllocation2020(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computePppAllocation2020/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async computePppAllocation2021(data) {
    try {
      return API.post(
        "sprx-api-erc",
        `/computePppAllocation2021/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async checkEmployeeJobsLocation(engagementID) {
    try {
      return API.post(
        "sprx-api-jobs",
        `/checkEmployeeJobsLocation/${engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async generateParsedJobsUploadFiles(data) {
    var body = {
      templateName: data.templateName,
      type: data.type,
    };
    // console.log(data);
    try {
      return API.post(
        "sprx-api-jobs",
        `/generateParsedJobsUploadFiles/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async computeJobsCredit(engagementID) {
    try {
      return API.post("sprx-api-jobs", `/computeJobsCredit/${engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getRSMeansToken(engagementID) {
    try {
      return API.get("sprx-api-cost-seg", `/getRSMeansToken/${engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async getRSMeansAsset(data) {
    var body = {
      type: data.type,
      book: data.book,
      location: data.location,
      assetID: data.assetID,
      authToken: data.authToken,
    };

    try {
      return API.post("sprx-api-cost-seg", "/getRSMeansAsset", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async searchRSMeansAsset(data) {
    var body = {
      type: data.type,
      book: data.book,
      location: data.location,
      authToken: data.authToken,
      searchTerm: data.searchTerm,
    };

    try {
      return API.post("sprx-api-cost-seg", "/searchRSMeansAsset", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async compute481a(engagementID) {
    try {
      return API.post("sprx-api-cost-seg", `/compute481a/${engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  // deliverable funcions.

  async generateReviewReports(data) {
    var body = {
      templateName: data.templateName,
      type: data.type,
    };

    try {
      return API.put(
        "sprx-api",
        `/generateReviewReports/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateParsedUploadFile(data) {
    var body = {
      templateName: data.templateName,
      type: data.type,
    };
    // console.log(data);
    try {
      return API.put(
        "sprx-api",
        `/generateParsedUploadFiles/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateDeliverables(data) {
    try {
      return API.put("sprx-api", `/generateDeliverables/${data.engagementID}`);
    } catch (e) {
      throw e;
    }
  }

  async generateFederalForm(data) {
    var body = {
      engagementID: data.engagementID,
    };

    try {
      return (
        API.put("sprx-api", `/generateFederalForm/${data.engagementID}`),
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateReports(data) {
    var body = {
      engagementID: data.engagementID,
    };
    try {
      return (
        API.put("sprx-api", `/generateReports/${data.engagementID}`),
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateAnalysisStatements(data) {
    var body = {
      engagementID: data.engagementID,
    };
    try {
      return (
        API.put("sprx-api", `/generateAnalysisStatements/${data.engagementID}`),
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateERCReviewReports(data) {
    var body = {
      templateName: data.templateName,
    };

    try {
      return API.put(
        "sprx-api-erc",
        `/generateERCReviewReports/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateDXRReports(data) {
    try {
      return API.put(
        "sprx-api-dxr",
        `/generateDXRReports/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async generateDXRDemoSet(data) {
    var body = {
      targetID: data.targetID,
      sourceID: data.sourceID,
    };
    console.log(body);

    try {
      return API.put("sprx-api-dxr", "/generateDXRDemoSet", {
        body: body,
      });
    } catch (e) {
      throw e;
    }
  }

  async generateERCCreditReport(data) {
    try {
      return API.put(
        "sprx-api-erc",
        `/generateERCCreditReport/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async generateERCAnalysisStatement(data) {
    try {
      return API.put(
        "sprx-api-erc",
        `/generateERCAnalysisStatement/${data.engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  async generateParsedCostSegUploadFile(data) {
    var body = {
      templateName: data.templateName,
      type: data.type,
    };

    try {
      return API.post(
        "sprx-api-cost-seg",
        `/generateParsedCostSegUploadFile/${data.engagementID}`,
        {
          body: body,
        }
      );
    } catch (e) {
      throw e;
    }
  }

  async generateCostSegCreditReport(engagementID) {
    try {
      return API.post(
        "sprx-api-cost-seg",
        `/generateCostSegCreditReport/${engagementID}`
      );
    } catch (e) {
      throw e;
    }
  }

  // getCurrentSession() {
  //   return new Promise((resolve, reject) => {
  //     Auth.currentSession()
  //       .then(data => {
  //         resolve(data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  // signOut() {
  //   return new Promise((resolve, reject) => {
  //     Auth.signOut()
  //       .then(data => {
  //         console.log(data);
  //         resolve(data);
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }
}

const instance = new amplifyService();

export default instance;
