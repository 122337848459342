import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const ScrollBars = (props) => {
  return (
    <PerfectScrollbar className={props.className}>
      {props.children}
    </PerfectScrollbar>
  );
};

export default ScrollBars;
