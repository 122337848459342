import React from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import classNames from "classnames";
import NavigationBar from "./NavigationBar";
import Transition from "./Transition";
import * as Actions from "../../../store/actions";

const NavigationBarWrapper = (props) => {
  const folded = props.navbar.folded;
  // const foldedAndClosed = folded && !props.navbar.foldedOpen;
  // const foldedAndOpened = folded && props.navbar.foldedOpen;

  return (
    <div className={classNames("flex flex-col z-10")}>
      <div className={classNames("")}>
        {/* <div className="md:hidden">
          <Transition
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={!folded}
            onExited={props.hide}
          >
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={props.hide}
              ></div>
            </div>
          </Transition>

          <Transition
            enter="ease-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            show={!folded}
            onExited={props.hide}
          >
            <div
              className={classNames(
                "flex flex-col flex-auto overflow-hidden w-64 minWidth-64 h-screen z-50 shadow left-0 bg-blue-300",
                folded && "hidden"
              )}
            >
              <NavigationBar />
            </div>
          </Transition>
        </div> */}
        <div
          className={classNames(
            "flex flex-col flex-auto overflow-hidden w-64 minWidth-64 h-screen z-40 shadow left-0 bg-blue-300",
            folded && "hidden"
          )}
        >
          <NavigationBar />
        </div>

        {/* <div className="hidden md:flex md:flex-shrink-0">
          <div
            className={classNames(
              "flex flex-col flex-auto overflow-hidden w-64 minWidth-64 h-screen z-40 shadow left-0 bg-blue-300",
              folded && "hidden"
            )}
          >
            <NavigationBar />
          </div>
        </div> */}
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navbarOpenFolded: Actions.navbarOpenFolded,
      navbarCloseFolded: Actions.navbarCloseFolded,
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  );
}

function mapStateToProps({ sprx }) {
  return {
    navbar: sprx.navbar,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBarWrapper);
