import React, { useState } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { Button } from "./";
import { Transition } from "@headlessui/react";

const StateCreditModal = (props) => {
  var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
  defaultThemeColors["$main-color"] = "#454553";
  defaultThemeColors["$main-hover-color"] = "#4AA0D5";
  defaultThemeColors["$text-color"] = "#000000";
  defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

  Survey.StylesManager.applyTheme();

  var surveyJSON = props.stateData.stateInfo.analysis;

  var model = new Survey.Model(surveyJSON);

  if (props.stateData.completed) {
    model.data = props.stateData.stateInfo.completedAnalysis;
  }

  function onComplete(survey, options) {
    props.submit(survey);
  }

  // function onCurrentPageChanged(survey, options) {
  //   console.log(survey);
  //   console.log(options);

  //   console.log(model);
  // }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={props.show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={props.hide}
            ></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition
          show={props.show}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="">
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {`Additional Information for ${props.stateData.state}`}
                </h3>
                <div className="mt-2">
                  <Survey.Survey
                    model={model}
                    showNavigationButtons={false}
                    showTitle={false}
                    onComplete={onComplete}
                    // onCurrentPageChanged={onCurrentPageChanged}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  secondary
                  onClick={() => {
                    model.isFirstPage ? props.hide() : model.prevPage();
                  }}
                  buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                >
                  Back
                </Button>
              </span>
              <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  primary
                  buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                  onClick={() => {
                    model.isLastPage
                      ? model.completeLastPage()
                      : model.nextPage();
                  }}
                >
                  Next
                </Button>
              </span>
            </div>
          </div>
        </Transition>
      </div>
    </div>

    //OLD MODAL CODE KEEOING FOR NOW INCASE ISSUE COMES UP

    // <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
    //   <Transition
    //     show={props.show}
    //     enter="ease-out duration-300"
    //     enterFrom="opacity-0"
    //     enterTo="opacity-100"
    //     leave="ease-in duration-200"
    //     leaveFrom="opacity-100"
    //     leaveTo="opacity-0"
    //   >
    //     <div className="fixed inset-0 transition-opacity">
    //       <div
    //         className="absolute inset-0 bg-gray-500 opacity-75 z-50"
    //         onClick={props.hide}
    //       ></div>
    //     </div>
    //   </Transition>

    //   <Transition
    //     show={props.show}
    //     enter="ease-out duration-300"
    //     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //     enterTo="opacity-100 translate-y-0 sm:scale-100"
    //     leave="ease-in duration-200"
    //     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    //     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //   >
    //     <div
    //       className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
    //       role="dialog"
    //       aria-modal="true"
    //       aria-labelledby="modal-headline"
    //     >
    //       <div className="">
    //         <div className="mt-3 text-center sm:mt-5">
    //           <h3
    //             className="text-lg leading-6 font-medium text-gray-900"
    //             id="modal-headline"
    //           >
    //             {`Additional Information for ${props.stateData.state}`}
    //           </h3>
    //           <div className="mt-2">
    //             <Survey.Survey
    //               model={model}
    //               showNavigationButtons={false}
    //               showTitle={false}
    //               onComplete={onComplete}
    //               // onCurrentPageChanged={onCurrentPageChanged}
    //             />
    //           </div>
    //         </div>
    //       </div>
    // <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
    //   <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
    //     <Button
    //       primary
    //       buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
    //       onClick={() => {
    //         model.isLastPage
    //           ? model.completeLastPage()
    //           : model.nextPage();
    //       }}
    //     >
    //       Next
    //     </Button>
    //   </span>
    //   <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
    //     <Button
    //       secondary
    //       onClick={() => {
    //         model.isFirstPage ? props.hide() : model.prevPage();
    //       }}
    //       buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
    //     >
    //       Back
    //     </Button>
    //   </span>
    // </div>
    //     </div>
    //   </Transition>
    // </div>
  );
};

export default StateCreditModal;
