import React from "react";
import classNames from "classnames";

const BasicTextInput = (props) => {
  return (
    <div className={classNames(props.parentClassName && props.parentClassName)}>
      <label
        htmlFor={props.id}
        className={classNames(
          props.showLabel
            ? "block text-sm font-medium leading-5 text-gray-700"
            : "hidden"
        )}
      >
        {props.label && props.label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <input
          id={props.id}
          className={classNames(
            "form-input block w-full sm:text-sm sm:leading-5",
            props.className && props.className
          )}
          type={props.type}
          placeholder={props.placeholder && props.placeholder}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
};

export default BasicTextInput;
